import { combineReducers } from "redux";
import appbarReducer from "./appbarReducer";
import authReducer from "./authReducer";
import generalGraphReducer from "./generalGraphReducer";
import modalReducer from "./modalReducer";
import navReducer from "./navReducer";
import notesReducer from "./notesReducer";
import organizationReducer from "./organizationReducer";
import patientReducer from "./patientReducer";
import permissionsReducer from "./permissionsReducer";
import scheduledSessionsReducer from "./ScheduledSessionsReducer";
import userRadarPlotReducer from "./userRadarPlotReducer";
import userReducer from "./userReducer";
import userSessionReducer from "./userSessionReducer";
import userSkillLevelsReducer from "./userSkillLevelsReducer";
import gameLevelReducer from "./gameLevelReducer";

import { SIGN_OUT } from "../actions/types";
import PermissionsService from "../lib/PermissionsSystem";
import assignmentReducer from "./assignmentReducer";

const appReducer = combineReducers({
  appbarReducer,
  assignmentReducer,
  authReducer,
  generalGraphReducer,
  modalReducer,
  navReducer,
  notesReducer,
  organizationReducer,
  patientReducer,
  permissionsReducer,
  session: scheduledSessionsReducer,
  userRadarPlotReducer,
  userReducer,
  userSessionReducer,
  userSkillLevelsReducer,
  gameLevelReducer,
});

export default (state, action) => {
  if (state === undefined) {
    PermissionsService._Hydrate();
  }

  if (action.type === SIGN_OUT) {
    state = undefined;
    PermissionsService.Reset();
  }

  return appReducer(state, action);
};
