import React from "react";
import {
  AppBar,
  Container,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import TabPanel from "../../sharedComponents/TabPanel";
import { getUserSessionData } from "../../../actions/index";
import { Debug, capitalize } from "../../../lib/index";
import ChartBase from "./ChartBase";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// Gets the properties for the tab panels
const tabPanelProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

// for material table
const options = {
  search: false,
  sortable: false,
  paging: true,
  pageSize: 5,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [5, 10, 25, 50, 100],
  exportButton: true,
};

/* ----------------------------- Main component ----------------------------- */
class UserSession extends ChartBase {
  constructor(props) {
    super(props, getUserSessionData);
    this.parseData = this.parseData.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.dispatchAPI = this.dispatchAPI.bind(this);
    this.getFileName = this.getFileName.bind(this);

    this.state = {
      ...this.state,
      tab_index: 0,
    };
  }

  dispatchAPI(user_id, start_date, end_date) {
    return getUserSessionData(user_id, start_date, end_date, true);
  }

  parseData(payload) {
    let categories = {};

    // Group all related level categories into arrays
    for (let key in payload) {
      let category = payload[key].categories;
      if (category !== null && category != "null") {
        categories[category] = categories[category] || [];
        categories[category].push(payload[key]);
      }
    }

    let tableData = [];

    // Iterate over all level categories
    for (let category in categories) {
      const levels = categories[category];

      // Will store all data for this line
      let categoryData = {
        name: capitalize(category),
        levels: [],
      };

      // Iterate over all levels within the category
      for (const level of levels) {
        let levelData = {
          name: level.name,
          sessions: [],
          columns: [
            {
              title: "Date",
              field: "created_at",
            },
          ],
        };
        // Add all the tracked fields from aggregates to the table
        for (let key in level.avg) {
          if (key != "count")
            levelData.columns.push({ title: capitalize(key), field: key });
        }
        levelData.sessions = level.sessions;

        categoryData.levels.push(levelData);
      }
      tableData.push(categoryData);
    }
    this.table_data = tableData;

    return { chartData: {}, chartOptions: {} };
  }

  // Handles when a new tab is selected
  handleTabChange(event, newValue) {
    event.preventDefault();
    this.setState({
      ...this.state,
      tab_index: newValue,
    });
  }

  getFileName(levelData) {
    let user_name = "";
    if (this.props.user != undefined) {
      user_name = this.props.user.first_name || "";
      user_name += "_" + (this.props.user.last_name || "");
    }
    return `${user_name}_${levelData.name}_${moment(
      this.props.start_date
    ).format("YYYY_MM_DD")}-${moment(this.props.end_date).format(
      "YYYY_MM_DD"
    )}.csv`;
  }

  renderChart() {
    const tabBar = (
      <AppBar position="static" color="default">
        <Tabs
          value={this.state.tab_index}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {this.table_data.map((category, ix) => (
            <Tab
              label={category.name}
              key={category.name}
              {...tabPanelProps(ix)}
            />
          ))}
        </Tabs>
      </AppBar>
    );

    return (
      <Container>
        {tabBar}
        {this.table_data.map((category, ix) => (
          <TabPanel
            key={"pnl_" + category.name}
            value={this.state.tab_index}
            index={ix}
          >
            {category.levels.map((level, ix) => (
              <Accordion key={level.name}>
                <AccordionSummary>
                  <Typography>{level.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <MaterialTable
                        title={level.name}
                        columns={level.columns}
                        data={level.sessions}
                        options={{
                          ...options,
                          exportFileName: this.getFileName(level),
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        ))}
      </Container>
    );
  }
}

export default UserSession;
