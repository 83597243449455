import React, { useState, useEffect } from "react";
import { Radar } from "react-chartjs-2";

const UserMood = (props) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    parseData(props.userRadarPlotData);
  }, [props.userRadarPlotData]);

  const parseData = (payload) => {
    let datasets = [];

    if (
      payload !== null &&
      payload !== undefined &&
      payload.average !== undefined && payload.entries.length !== 0
    ) {
      // do not delete this line

      let labels = Object.keys(payload.average);

      let averages_oldValue = payload.entries[0];
      let averages_newValue = payload.entries[payload.entries.length - 1];

      let startPeriod= []
      let endPeriod = []
      var array_keys = ["perceived_difficulty" , "level_name" , "level_id","date"]

      array_keys.map(item => {
        delete averages_oldValue[item]
        delete averages_newValue[item]
      })

      Object.keys(averages_oldValue).map((key) => {
        if (averages_oldValue[key] !== null) {
          startPeriod.push(Number(averages_oldValue[key]).toFixed(2));
        } else {
          startPeriod.push(0);
        }
        return key;
      });

      Object.keys(averages_newValue).map((key) => {
        if (averages_newValue[key] !== null) {
          endPeriod.push(Number(averages_newValue[key]).toFixed(2));
        } else {
          endPeriod.push(0);
        }
        return key;
      });

      // Create the ChartJS dataset for the radar plot
      let chartData = {
        labels: labels,
          datasets: [
            {
              label: "Start of Period",
              data: [...startPeriod],
              backgroundColor: "rgba(157, 137, 246, 0.5)",
              borderColor: "rgba(157, 137, 246, 0.5)",
              borderWidth: 1,
            },
            {
              label: "End of Period",
              data: [...endPeriod],
              backgroundColor: "rgba(125, 192, 230, 0.5)",
              borderColor: "rgba(125, 192, 230, 0.5)",
              borderWidth: 1,
            }
          ],
      };

      // ChartJS options
      const options = {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          labels : {
              borderRadius : "5px"
          }
        },
        scale: {
          pointLabels: {
            fontSize: 15,
          },
        },
        plugins : {
            customCanvasBackgroundColor: {
                backgroundColor: 'transparent',
            },
        },
      };

      setChartData({ chartData: chartData, chartOptions: options });
    }
  };

  return <Radar type="Radar" data={chartData.chartData} options={chartData.chartOptions} />;
};

export default UserMood;
