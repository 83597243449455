import { select, scaleLinear, line, selectAll } from "d3";

const MARGIN = { TOP: 75, BOTTOM: 50, LEFT: 100, RIGHT: 25 };
const WIDTH = 750 - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 750 - MARGIN.TOP - MARGIN.BOTTOM;

class RadarPlot {
  constructor(element, data) {
    let vis = this;

    // the svg element
    vis.g = select(element)
      .append("svg")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

    // range of the svg
    let radialScale = scaleLinear().domain([0, 5]).range([0, 150]);

    this.radialScale = radialScale;

    // number of circle that surrounds the radar
    let ticks = [1, 2, 3, 4, 5];

    ticks.forEach((t) => {
      vis.g
        .append("circle")
        .attr("cx", WIDTH / 2)
        .attr("cy", HEIGHT / 2)
        .attr("fill", "none")
        .attr("stroke", "#555") //colour of the ticks
        .attr("r", radialScale(t));
    });

    ticks.forEach((t) =>
      vis.g
        .append("text")
        .attr("x", WIDTH / 2 + 5.5)
        .attr("y", HEIGHT / 2 - 5.5 - radialScale(t))
        .text(t.toString())
    );

    function angleToCoordinate(angle, value) {
      let x = Math.cos(angle) * radialScale(value);
      let y = Math.sin(angle) * radialScale(value);
      return { x: WIDTH / 2 + x, y: HEIGHT / 2 - y };
    }

    function getPathCoordinates(data_point) {
      let coordinates = [];

      for (let i = 0; i < data[1].length; i++) {
        let ft_name = data[1][i];
        // let ft_name = (data[1][i] !== null) ? data[1][i] : `0.0`;
        let angle = Math.PI / 2 + (2 * Math.PI * i) / data[1].length;

        coordinates.push(angleToCoordinate(angle, data_point[ft_name]));
      }
      return coordinates;
    }

    this.getPathCoordinates = getPathCoordinates;

    for (let i = 0; i < data[1].length; i++) {
      let ft_name = data[1][i]; //data title: (difficulty, effort ...etc.)
      let angle = Math.PI / 2 + (2 * Math.PI * i) / data[1].length;
      let line_coordinate = angleToCoordinate(angle, 5);
      let label_coordinate = angleToCoordinate(angle, 5.25);

      //Ajust label corrdinates based on angle.
      if (angle < 2) {
        label_coordinate.y = label_coordinate.y - 15;
        label_coordinate.x = label_coordinate.x - 25;
      } else if (angle > 2 && angle < 4) {
        label_coordinate.x = label_coordinate.x - 40;
      } else if (angle > 4 && angle < 5) {
        label_coordinate.x = label_coordinate.x - 50;
        label_coordinate.y = label_coordinate.y + 10;
      }

      //draw axis line
      vis.g
        .append("line")
        .attr("x1", WIDTH / 2)
        .attr("y1", HEIGHT / 2)
        .attr("x2", line_coordinate.x)
        .attr("y2", line_coordinate.y)
        .attr("stroke", "black");

      //draw axis label
      vis.g
        .append("text")
        .attr("x", label_coordinate.x)
        .attr("y", label_coordinate.y)
        .text(ft_name);
    }

    vis.update(data, getPathCoordinates);
  }

  update(data) {
    let vis = this;
    vis.data = data;

    const colour = "#64b5f6";
    const coordinates = vis.getPathCoordinates(data[0]);
    const makeLine = line()
      .x((d) => d.x)
      .y((d) => d.y);

    const circleEntries = Object.entries(data[0]);

    //JOIN DATA and assoicated it our line selection
    const radarLine = vis.g
      .selectAll("data-line-group")
      .data([data[0]], (d) => d);

    //EXIT DATA
    radarLine.exit();
    selectAll(".data-line-group").remove();

    radarLine
      .enter()
      .append("g")
      .attr("class", "data-line-group")
      .append("path")
      .datum(coordinates)
      .attr("d", makeLine)
      .attr("stroke-width", 3)
      .attr("stroke", colour)
      .attr("fill", colour)
      .attr("stroke-opacity", 1)
      .attr("opacity", 0.5);
  }
}

export default RadarPlot;
