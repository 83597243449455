import {
  GET_RADAR_PLOT_DATA,
  UPDATE_WITHIN_DATE_SELECT,
} from "../actions/types";

import { createDateString } from "../lib";

const INITIAL_STATE = {
  userChartData: [],
  selectedDate: createDateString(30),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RADAR_PLOT_DATA:
      return { ...state, userChartData: action.payload };
    case UPDATE_WITHIN_DATE_SELECT:
      return { ...state, selectedDate: action.date };
    default:
      return state;
  }
};
