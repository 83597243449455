import {
  NAV_COLLAPSE, NAV_EXPAND
} from "./types";

import Store from "../store.js";

export const toggleNav =
  () => {
    const dispatch = Store.dispatch;
    const isExpanded = Store.getState().navReducer.isExpanded;
    const action = isExpanded ? NAV_COLLAPSE : NAV_EXPAND
    dispatch({type: action});
  };
