import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PractitionersList from "./PractitionersList";
import { connect } from "react-redux";
import {
  updateUser,
  createUser,
  showModal,
  hideModal,
  archiveUser,
  getUsersByOrg,
} from "../../actions";
import { ARCHIVE_MODAL } from "../sharedComponents/Modals/constants";
import CustomDialog from "../sharedComponents/CustomDialog";
import AddIcon from "../sharedComponents/AddIcon";
import withModals from "../sharedComponents/Modals/withModals";
import AddUsersStepper from "../practitionersManagement/addUsersStepper/AddUsersStepper";

const styles = (theme) => ({
  select: {
    width: "50%",
    height: "40px",
  },
  title: {
    fontSize: "30px",
    lineHeight: "37px",
    fontFamily: "MontserratBold",
    color: "#333437",
    fontWeight: "700",
    padding: "6px 0.8em",
  },
  selectedItem: {
    backgroundColor: theme.palette.info.light,
  },
  selectContainer: {
    padding: "6px 1.5em",
    width: "100%",
  },
  gridContainer: {
    marginTop: "20px",
    width: "70%",
    marginLeft: "-18px",
    "& .MuiFab-label": {
      fontFamily: "MontserratBold",
      color: "#333437",
      fontWeight: "700",
      lineHeight: "37px",
    },
  },
  userTypesLabel: {
    display: "inline-block !important",
    marginRight: "10px",
  },
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_types: props.user_types || ["PATIENT"],
      checkedStates: {
        PATIENT: true,
      },
      menuAnchorTarget: null,
      hasFetched: false,
      users: undefined,
    };
  }

  toggleUploadModal(_, reason) {
    if (reason !== "backdropClick") {
      this.props.toggleArchiveModal();
    }
  }
  render() {
    const { classes, toggleArchiveModal, modals } = this.props;
    return (
      <>
        <Grid container className={classes.gridContainer} direction={"column"}>
          <h2 className={classes.title}>Manage User Accounts</h2>
          <Grid item style={{ width: "165px" }}>
            <AddIcon
              clsassName={"title"}
              onClick={this.toggleUploadModal.bind(this)}
              iconText="+ Add User"
            />
          </Grid>
        </Grid>
        <PractitionersList
          user_types={null}
          updateUser={this.props.updateUser}
          archiveUser={this.props.archiveUser}
        />
        <CustomDialog
          className="upload-modal"
          open={modals[ARCHIVE_MODAL]}
          toggle={this.toggleUploadModal}
          hideCloseButton
          maxWidth="lg"
        >
          <div className="text-center stepper">
            <AddUsersStepper
              toggle={toggleArchiveModal.bind(this)}
              user_types={this.state.user_types}
              users= {this.props.users}
            />
          </div>
        </CustomDialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users,
    hasFetched: state.userReducer.hasFetched,
    modal: state.modalReducer,
    toggleArchiveModal: ARCHIVE_MODAL,
  };
};

const modalsProps = {
  toggleArchiveModal: ARCHIVE_MODAL,
};

export default connect(mapStateToProps, {
  getUsersByOrg,
  updateUser,
  createUser,
  showModal,
  hideModal,
  archiveUser,
})(withStyles(styles)(withModals(Users, modalsProps)));
