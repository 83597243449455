import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

import AppVersionDispatcher from "./components/AppVersionDispatcher.js"
import "./assets/App.css"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"

import store from "./store"

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <AppVersionDispatcher />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
)
