import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    height: 300,
  },
}));

function renderTableRows(props) {
  return props.sessions.map((session) => {
    return (
      <TableRow key={session.id}>
        <TableCell>{new Date(session.created_at).toLocaleString()}</TableCell>
        <TableCell align="right">{`${session.time.toFixed(2)}s`}</TableCell>
      </TableRow>
    );
  });
}

export default function BillingSessions(props) {
  const classes = useStyles();
  const tableRows = renderTableRows(props);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Date of Session</TableCell>
            <TableCell align="right">Duration of Session</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
