/**
 * This is the file for the login form only
 */

import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// these are the material-ui components
import { Button, TextField, Grid, Typography, Hidden } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import { signIn } from "../../actions";

// Neurofit logo
import logo from "../../assets/images/neurofit-logo.svg";
import { Debug } from "../../lib";
import util from "util";

const useStyles = makeStyles((theme) => ({
  alertBox: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  forgotPasswordLink: {
    textDecoration: "none",
    fontSize: "0.75em",
    textAlign: "right",
    paddingTop: theme.spacing(1),
  },
}));

export default function LoginForm(props) {
  const classes = useStyles();
  const { register, handleSubmit, setValue, errors, reset } = useForm();
  const dispatch = useDispatch();
  const [message, onMessageChanged] = React.useState("");

  // a function used for logging in
  const onSubmit = async (data) => {
    let response = await signIn(data)(dispatch);
    Debug.log("response: " + util.inspect(response));
    if (!response.is_error) {
      reset();
    } else {
      onMessageChanged(response.message);
    }
  };

  // renders the error message when login credential is invalid
  const renderError = () => {
    if (message) {
      return (
        <div className={classes.alertBox}>
          <Alert severity="error">{message}</Alert>
        </div>
      );
    }
  };

  return (
    // <Container component="main">

    <Grid container className="nf-login-form-grid-container">
      {/* login form */}
      <Grid item sm={12} md={6} lg={6}>
        <Typography component="h2" variant="h2">
          Log In
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={register({
              required: "Required",
              pattern: {
                value:
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.email && <span> {errors.email.message} </span>}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            inputRef={register}
          />
          {errors.password && <span>Invalid Password</span>}
          <Button type="submit" variant="contained" className={classes.submit}>
            Login
          </Button>
          <a href="/forgot_password" className={classes.forgotPasswordLink}>
            Forgot Password?
          </a>
          {renderError()}
          {/*<a href="/register" className={classes.forgotPasswordLink}>
            Register
          </a>*/}
        </form>
      </Grid>

      {/* neurofit logo and title */}
      <Grid item sm={12} md={6} lg={6}>
        <img src={logo} alt="Neurofit logo" />
        <Typography component="h1" variant="h1">
          Neurofit VR
        </Typography>
      </Grid>
    </Grid>
    // </Container>
  );
}
