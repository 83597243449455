import React, { useState, setState } from "react";
import {
  Grid,
  makeStyles,
  CssBaseline,
  Typography,
  Card,
} from "@material-ui/core";
import Weeks from "./MonthlyView/Week";
import Header from "./MonthlyView/MonthlySessionHeading";
import WeekHeader from "./otherComponents/WeekHeader";

const useStyles = makeStyles((theme) => ({
  monthView: {
    paddingTop: "10px",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "1800px",
  },
}));

function renderWeek(date, day, month, year) {
  return (
    <Weeks
      firstDay={date}
      dayOfWeek={day}
      currentMonth={month}
      currentYear={year}
    />
  );
}

/* Monthly view manages the state for the weeks and days */
export default function MonthlyView(props) {
  const classes = useStyles();
  const weekIncrement = 7;

  //Retrieves new date object
  const [date, setDate] = useState(props.date);
  date.setMonth(date.getMonth(), 1); //Sets date as first day of the month

  //Represent the date of the first day of the week
  let firstWeek = 1;
  let secondWeek = firstWeek,
    thirdWeek = 0,
    fourthWeek = 0,
    fifthWeek = 0,
    sixthWeek = 0;

  //Regulates the starting day of week 2, so if first day of month is Sunday, the following week is firstday plus seven
  switch (date.getDay()) {
    case 0: //Sunday is first day
      secondWeek += 7;
      break;
    case 1: //Monday is first day
      secondWeek += 6;
      break;
    case 2: //Tuesday is first day
      secondWeek += 5;
      break;
    case 3: //Wednesday is first day
      secondWeek += 4;
      break;
    case 4: //Thursday is first day
      secondWeek += 3;
      break;
    case 5: //Friday is first day
      secondWeek += 2;
      break;
    case 6: //Saturday is first day
      secondWeek += 1;
      break;
  }

  //Increment the third, fourth, and fifth week based on the second week
  thirdWeek = secondWeek + weekIncrement;
  fourthWeek = thirdWeek + weekIncrement;
  fifthWeek = fourthWeek + weekIncrement;
  sixthWeek = fifthWeek + weekIncrement;

  //Functions to set the date to the previous month or the next month
  const goBackDate = () =>
    setDate(new Date(date.getUTCFullYear(), date.getMonth() - 1));
  const goForwardDate = () =>
    setDate(new Date(date.getUTCFullYear(), date.getMonth() + 1));

  //To be used in the session header for searching implicit dates
  const setFromDatePicker = (value) => setDate(new Date(value));

  return (
    <div>
      <CssBaseline />

      {/*Shows the top view for the current Month and search options*/}
      <Header
        month={date.getMonth()}
        year={date.getUTCFullYear()}
        goBackDate={goBackDate}
        goForwardDate={goForwardDate}
        handleDateChange={setFromDatePicker}
      />

      {/*Shows the weekly heading from Sunday to Saturday*/}
      <WeekHeader />

      {/*Container holding the 5 weeks*/}
      <Grid
        container
        className={classes.monthView}
        alignItems="center"
        justify="center"
      >
        <Grid container spacing={1}>
          {/* Renders the week using the first day of the week, the position in the week (0 - Sunday), and the current month*/}
          {renderWeek(
            firstWeek,
            date.getDay(),
            date.getMonth(),
            date.getUTCFullYear()
          )}
          {renderWeek(secondWeek, 0, date.getMonth(), date.getUTCFullYear())}
          {renderWeek(thirdWeek, 0, date.getMonth(), date.getUTCFullYear())}
          {renderWeek(fourthWeek, 0, date.getMonth(), date.getUTCFullYear())}
          {renderWeek(fifthWeek, 0, date.getMonth(), date.getUTCFullYear())}
          {renderWeek(sixthWeek, 0, date.getMonth(), date.getUTCFullYear())}
        </Grid>
      </Grid>
    </div>
  );
}
