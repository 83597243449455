import moment from "moment"
import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import {
  Grid,
  Paper,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"

import {
  getLevels,
  getUserThunk,
  createAssignment,
  createAssignmentReset,
  getAssignments,
} from "../../../actions/index"
import ActivityForm from "./ActivityForm"
import LevelForm from "./LevelForm"
import Divider from "../sharedComponents/Divider"
import NFButton from "../sharedComponents/NFButton"
import TitleRow from "../sharedComponents/TitleRow"

const ACTIVITY = {
  days: [],
  levels: [],
  time_start: "09:00",
  time_end: "12:00",
  date_start: moment().format("YYYY-MM-DD"),
  date_end: moment().add(7, "days").format("YYYY-MM-DD"),
}

const useStyles = makeStyles((theme) => ({
  topRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    width: "75%",
    display: "block",
    margin: `${theme.spacing(1)} 0`,
    border: 0,
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.gray[400],
  },
  paper: {
    borderRadius: "0.75rem",
    boxShadow: "none",
    padding: "2rem",
  },
  formGroup: {
    marginTop: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
    width: 160,
  },
  buttonPrimary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.orange[300],
    },
    backgroundColor: theme.palette.orange[200],
    color: theme.palette.white,
    maxWidth: "16rem",
    marginTop: theme.spacing(1),
  },
  buttonSecondary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.gray[100],
    },
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[600],
    maxWidth: "16rem",
    marginTop: theme.spacing(1),
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
}))

const AssignmentCreate = () => {
  let { id } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const levels = useSelector((state) => state.gameLevelReducer.levels)
  const creating = useSelector((state) => state.assignmentReducer.creating)
  const createSuccess = useSelector(
    (state) => state.assignmentReducer.createSuccess
  )
  const createError = useSelector(
    (state) => state.assignmentReducer.createError
  )
  const user = useSelector((state) => state.userReducer.viewed_user)

  const [activities, setActivities] = useState([{ ...ACTIVITY }])
  const [duration, setDuration] = useState(null)
  const [message, setMessage] = useState("")
  let userId = id

  useEffect(() => {
    if (createSuccess) {
      setMessage("Assignments created successfully!")
      dispatch(createAssignmentReset())
      enqueueSnackbar(`Assignment created successfully!`, {
        variant: "success",
        persist: false,
      })
      setActivities([]) // Clear the form
      history.push(`/user-profile/${id}`)
    } else if (createError) {
      setMessage(`Error creating assignments: ${createError}`)
    }
  }, [createSuccess, createError, dispatch, history, id])

  useEffect(() => {
    dispatch(getLevels())
  }, [dispatch])

  useEffect(() => {
    if (userId) {
      dispatch(getUserThunk(userId))
    }
  }, [dispatch, userId])

  const addActivity = () => {
    setActivities([...activities, { ...ACTIVITY }])
    console.log(activities)
  }

  const handleActivityChange = (index, updatedActivity) => {
    const newActivities = [...activities]
    newActivities[index] = updatedActivity
    setActivities(newActivities)
  }

  const setLevels = (idx, value) => {
    const newActivities = [...activities]
    newActivities[idx].levels = value
    setActivities(newActivities)
  }

  const handleDurationChange = (event) => {
    setDuration(parseInt(event.target.value))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (activities.length === 0) {
      setMessage("Please add at least one assignment.")
      return
    }
    dispatch(createAssignment(id, activities, duration))
  }

  return (
    <>
      <TitleRow
        title={`New Assignment: ${user.first_name} ${user.last_name}`}
      />
      <Grid container item xs={12} direction='column'>
        <Paper className={classes.paper}>
          <form noValidate>
            <Grid container item xs={12}>
              <Typography variant='body1'>
                {message && <p>{message}</p>}
              </Typography>
              {activities.map((activity, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  className={classes.activity}>
                  <Grid item xs={12} lg={6} direction='column'>
                    <ActivityForm
                      key={index}
                      activity={activity}
                      index={index}
                      onActivityChange={handleActivityChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} direction='column'>
                    <LevelForm
                      levels={levels}
                      selectedLevels={activity.levels}
                      setLevels={setLevels}
                      index={index}
                    />
                  </Grid>
                  {/* {index !== activities.length - 1 && <Divider />} */}
                  <Divider />
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={12} direction='column'>
              <FormControl component='fieldset' className={classes.formGroup}>
                <FormLabel component='legend'>
                  (Optional) How much time should be spent on this assignment?
                </FormLabel>
                <TextField
                  id='duration'
                  value={duration || ""}
                  onChange={handleDurationChange}
                  label='Minutes'
                  variant='outlined'
                  type='number'
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <Button
                className={classes.buttonSecondary}
                onClick={() => addActivity()}>
                <AddIcon />
                Add Another Activity
              </Button>
              <Button className={classes.buttonPrimary} onClick={onSubmit}>
                {creating ? "Creating..." : "Create Assignments"}
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  )
}

export default AssignmentCreate
