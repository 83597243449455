import {
  GET_ORGANIZATIONS,
  REGISTER_ORGANIZATION,
  UPDATE_ACCOUNT_PRIVILEGE,
  GET_SESSIONS_BY_ORGANIZATION,
  UPDATE_SELECTED_ORGANIZATION,
} from "../actions/types"
import { Debug } from "../lib"

const INITIAL_STATE = {
  organizations: [],
  selectedOrganization: "",
  hasFetched: false,
  response: [],
  sessions: [],
}

const organizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS:
      return {
        ...state,
        hasFetched: true,
        organizations: action.payload || [],
      }
    case REGISTER_ORGANIZATION:
      return { ...state, response: action.response }
    case GET_SESSIONS_BY_ORGANIZATION:
      return { ...state, sessions: action.payload }
    case UPDATE_SELECTED_ORGANIZATION:
      Debug.log("ORG_ID: " + action.id)
      return { ...state, selectedOrganization: action.id }
    case UPDATE_ACCOUNT_PRIVILEGE:
      return { ...state }
    default:
      return state
  }
}

export default organizationReducer
