import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

export const NavbarMobile = styled.div`

position:fixed;
z-index: 999;
width: 100%;
height 100%;
display: flex;
align-items: center;
justify-content: center;
top: 0;
left:0;
transition: 0.3s ease-in-out;
background-color: #c4dfef;
top:${(props) => (props.isNavbarMobileOpen ? "0%" : "100%")};
opacity: ${(props) => (props.isNavbarMobileOpen ? "100%" : "0%")};


    @media screen and (min-width: 768px) {
        display: none;
    }

`;

export const OpenIcon = styled(GiHamburgerMenu)`
  color: black;
  font-size: 2em;
`;

export const OpenIconContainer = styled.div`
  position: fixed;
  top: 1.2rem;
  right: 1.5rem;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  z-index: 998;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: black;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
