import {
  FormControl,
  FormLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginTop: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(1),
    width: 160,
  },
  fullTextField: {
    padding: theme.spacing(1),
    width: 320,
  },
  paper: {
    borderRadius: "0.75rem",
    boxShadow: "none",
    padding: "2rem",
  },
  divider: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  buttonPrimary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.orange[300],
    },
    backgroundColor: theme.palette.orange[200],
    color: theme.palette.white,
    maxWidth: "8rem",
  },
  buttonSecondary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.gray[100],
    },
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[600],
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
}))

const LevelForm = ({ index, levels, selectedLevels, setLevels }) => {
  const classes = useStyles()

  const handleLevelChange = (event) => {
    const { id } = event.target
    const levelId = parseInt(id.split("-")[4])
    // levels are sequential 1-n, but option IDs overwritten by MUI
    setLevels(index, levelId + 1)
  }

  const handleChange = (event, newValue) => {
    const newSelectedLevels = newValue.map(level => level.id);
    setLevels(index, newSelectedLevels);
  };

  const levelOptions = levels.map((level) => ({
    ...level,
    levelId: level.id,
  }))

  return (
    <>
      <FormControl component='fieldset' className={classes.formGroup}>
        <FormLabel component='legend'>Activity</FormLabel>
        <Autocomplete
          multiple
          options={levelOptions}
          getOptionLabel={(option) => option.name}
          value={levels.filter(level => selectedLevels.includes(level.id))}
          onChange={handleChange}
          className={classes.fullTextField}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Select Levels'
            />
          )}
        />
      </FormControl>
    </>
  )
}

export default LevelForm
