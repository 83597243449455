import React, { useState } from "react";
import UserColumn from "./helpers/UserColumn";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import "./ConfirmInputFields.scss";
import {
  MAIN_COLUMN_PATIENT,
  MAIN_COLUMN_PRACTITONER,
} from "../../../lib/Constants";
import { getColumn } from "./Assets/HelperFunctions";

function ConfirmInputFields(props) {
  const userType = props.user_type;
  const [rows, setRows] = useState(props.users);
  const numberOfColumns = rows[0].length;
  const steps = userType === "PATIENT" ? 8 : 7;
  const [activeStep, setActiveStep] = useState(0);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const currentUserColumn =
    userType === "PATIENT" ? MAIN_COLUMN_PATIENT : MAIN_COLUMN_PRACTITONER;
  const handleNext = async () => {
    if (activeStep === steps - 1) {
      let copy = [];
      let criteria =
        userType === "PATIENT"
          ? [...MAIN_COLUMN_PATIENT]
          : [...MAIN_COLUMN_PRACTITONER];
      for (let i = 0; i < numberOfColumns; i++) {
        if (criteria.includes(rows[0][i])) {
          copy.push(getColumn(rows, i));
        }
      }
      props.setUsers(copy);
    } else {
      rows[0][currentColumn] =
        userType === "PATIENT"
          ? MAIN_COLUMN_PATIENT[activeStep - 1]
          : MAIN_COLUMN_PRACTITONER[activeStep - 1];
      setSelectedColumns((preVal) => {
        return [...preVal, currentColumn];
      });
      setCurrentColumn(null);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleCustomize = () => {
    if (activeStep === 0) {
      const copy = [...rows];
      for (let i = 0; i < numberOfColumns; i++) {
        copy[0][i] = "";
      }
      setRows(copy);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleImport = () => {
    let copy = [];
    let criteria =
      userType === "PATIENT"
        ? [...MAIN_COLUMN_PATIENT]
        : [...MAIN_COLUMN_PRACTITONER];
    for (let i = 0; i < numberOfColumns; i++) {
      if (criteria.includes(rows[0][i])) {
        copy.push(getColumn(rows, i));
      }
    }
    props.setUsers(copy);
  };
  const handleBack = () => {
    rows[0][selectedColumns[selectedColumns.length - 1]] = ``;
    const copy = [...selectedColumns];
    copy.pop();
    setSelectedColumns(copy);
    setCurrentColumn(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepTitle = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return "Preview Import";
      case 1:
        return "Select “First Name” Column";
      case 2:
        return "Select “Last Name” Column";
      case 3:
        return userType === "PATIENT"
          ? "Select “Date of Birth” Column"
          : "Select “Occupation” Column";
      case 4:
        return userType === "PATIENT"
          ? "Select “Sex” Column"
          : "Select “Email” Column";
      case 5:
        return userType === "PATIENT"
          ? "Select “Email” Column"
          : "Select “Sex” Column";
      case 6:
        return userType === "PATIENT"
          ? "Select “Diagnosis” Column"
          : "Preview Columns";
      case 7:
        return "Preview Columns";
      default:
        return "Unknown stepIndex";
    }
  };

  const columnClickedHandler = (index) => {
    if (activeStep !== 0) {
      setCurrentColumn(index);
    }
  };

  return (
    <div className="ConfirmInput-container">
      <div className="allTitle">
        <div className="title">{getStepTitle(activeStep)}</div>
        {activeStep === 0 ? (
          <div className="sub-title">
            Please confirm columns are labelled as you expect. You can customize
            columns if needed
          </div>
        ) : (
          (activeStep === 6 && userType !== "PATIENT") ||
          (activeStep !== 7 && (
            <div className="sub-title">
              Please select the column which contains the “
              {currentUserColumn[activeStep - 1]}” data.
            </div>
          ))
        )}
      </div>
      <div className="total-user">Total Users: {rows.length - 1}</div>
      <Stepper
        style={{ padding: "0", overflow: "auto" }}
        activeStep={activeStep}
      >
        <Step
          sx={{ overflow: "auto" }}
          style={{ padding: "0", overflow: "auto" }}
          key={activeStep}
        >
          <div className="columns">
            {Array.from({ length: numberOfColumns }, (_, index) => (
              <UserColumn
                key={index}
                column={getColumn(rows, index)}
                onClick={() => {
                  return columnClickedHandler(index);
                }}
                selectedColumn={currentColumn}
                index={index}
                selectedColumns={selectedColumns}
              />
            ))}
          </div>
        </Step>
      </Stepper>
      <div className="buttons">
        <button onClick={props.toggle} className="cancel">
          Cancel
        </button>
        <div>
          {activeStep !== 0 ? (
            <button
              disabled={activeStep === 1}
              onClick={handleBack}
              className="back"
            >
              Back
            </button>
          ) : (
            <button onClick={handleCustomize} className="back">
              Customize
            </button>
          )}
          {activeStep !== 0 ? (
            <button
              onClick={handleNext}
              disabled={
                activeStep === 6 && userType !== "PATIENT"
                  ? false
                  : activeStep === 7
                  ? false
                  : currentColumn !== 0 && currentColumn === null
                  ? true
                  : false
              }
              className="next"
            >
              Next
            </button>
          ) : (
            <button onClick={handleImport} className="next">
              Import
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmInputFields;
