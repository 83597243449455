import {
  PERMADMIN_GET_PERMISSIONS_LISTING,
  PERMADMIN_CREATE_PERMISSION,
  PERMADMIN_DELETE_PERMISSION,
  PERMADMIN_GET_ROLES_LISTING,
  PERMADMIN_CREATE_ROLE,
  PERMADMIN_UPDATE_ROLE,
  PERMADMIN_UPDATE_PERMISSION,
} from "../actions/types";

const INITIAL_STATE = {
  permissions: [],
  permission_count: 0,
  hasFetchedPerms: false,
  roles: [],
  hasFetchedRoles: false,
  response: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERMADMIN_GET_PERMISSIONS_LISTING: {
      console.log(action);
      let result = {
        ...state,
        response: action.response,
        hasFetchedPerms: true,
        permissions:
          action.response.status == 200 ? action.payload.permissions : [],
      };
      result.permissions.sort((x, y) => {
        if (x.name < y.name) return -1;
        if (x.name > y.name) return 1;
        else return 0;
      });
      result.permission_count = result.permissions.length;
      console.log("result state:");
      console.log(result);
      return result;
    }
    case PERMADMIN_CREATE_PERMISSION: {
      let result = { ...state, response: action.response };
      result.permissions.push(action.payload.permission);
      result.permission_count = result.permissions.length;
      return result;
    }
    case PERMADMIN_UPDATE_PERMISSION: {
      let result = { ...state, response: action.response };
      result.permissions = result.permissions.map((x) => {
        if (x.id === action.payload.permission.id) {
          Object.assign(x, action.payload.permission);
        }
        return x;
      });
      return result;
    }
    case PERMADMIN_DELETE_PERMISSION: {
      let result = { ...state, response: action.response };
      result.permissions = result.permissions.filter(
        (x) => x !== action.payload.permission_id
      );
      return result;
    }
    case PERMADMIN_GET_ROLES_LISTING:
      return {
        ...state,
        response: action.response,
        hasFetchedRoles: true,
        roles: action.payload || [],
      };
    case PERMADMIN_CREATE_ROLE: {
      let result = { ...state, response: action.response };
      result.roles.push(action.payload);
      return result;
    }
    case PERMADMIN_UPDATE_ROLE: {
      let result = { ...state, response: action.response };
      const added = action.payload.added_perms;
      const removed = action.payload.removed_perm;
      result.roles = result.roles.map((x) => {
        if (x.id === action.payload.role_id) {
          if (Array.isArray(removed)) {
            x.permissions = x.permissions.filter(
              (x) => !action.payload.removed_perms.includes(x.id)
            );
          }
          if (Array.isArray(added)) {
            for (let perm_id of added) {
              x.permissions.push(
                state.permissions.find((x) => x.id == perm_id)
              );
            }
          }
        }
      });
      return result;
    }
    default:
      return state;
  }
};
