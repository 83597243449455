import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "0.875rem",
    minWidth: "80px",
  },
  primary: {
    backgroundColor: theme.palette.orange[200],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.orange[300],
    },
  },
  secondary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.orange[200],
    border: `1px solid ${theme.palette.orange[200]}`,
    "&:hover": {
      color: theme.palette.orange[200],
      backgroundColor: theme.palette.orange[100],
    },
  },
}))

const NFButton = ({
  type = "button",
  variant = "primary",
  destination,
  onClick,
  children,
  className,
  ...props
}) => {
  const classes = useStyles()

  const buttonProps = {
    className: `${classes.button} ${classes[variant]} ${className || ""}`,
    variant: "contained",
    disableElevation: true,
    ...props,
  }

  if (type === "link") {
    return (
      <Button {...buttonProps} component={Link} to={destination}>
        {children}
      </Button>
    )
  }

  return (
    <Button {...buttonProps} type={type} onClick={onClick}>
      {children}
    </Button>
  )
}

export default NFButton
