class Utility {
  static isFloat(n) {
    return (n === +n && n !== (n | 0)) | Utility.isInteger(n);
  }

  static isInteger(n) {
    return n === +n && n === (n | 0);
  }

  /**
   * Uses a regular expression to escape characters within a regex string
   * @see https://stackoverflow.com/a/26156888/1725883
   * @param {String} strToEscape
   */
  static escapeRegExp(strToEscape) {
    // Escape special characters for use in a regular expression
    return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  /**
   * Trims a character from a string, returning a new string
   * @see https://stackoverflow.com/a/26156888/1725883
   * @param {String} origString The string to trim
   * @param {String} charsToTrim The characters to trim from the string, will be escaped before passing to regex
   */
  static trimChar(origString, charsToTrim) {
    charsToTrim = Utility.escapeRegExp(charsToTrim);
    var regEx = new RegExp(
      "^[" + charsToTrim + "]+|[" + charsToTrim + "]+$",
      "g"
    );
    return origString.replace(regEx, "");
  }

  /**
   * Trims characters from the right of the string to the first character that does not match the list, returns a new string
   * @see https://stackoverflow.com/a/26156888/1725883
   * @param {String} origString The string to trim
   * @param {String} charsToTrim The characters to trim from the string, will be escaped before passing to regex
   */
  static rTrimChar(origString, charsToTrim) {
    charsToTrim = Utility.escapeRegExp(charsToTrim);
    var regEx = new RegExp("[" + charsToTrim + "]+$", "g");
    return origString.replace(regEx, "");
  }

  /**
   * Trims characters from the left of the string to the first character that does not match the list, returns a new string
   * @see https://stackoverflow.com/a/26156888/1725883
   * @param {String} origString The string to trim
   * @param {String} charsToTrim The characters to trim from the string, will be escaped before passing to regex
   */
  static lTrimChar(origString, charsToTrim) {
    charsToTrim = Utility.escapeRegExp(charsToTrim);
    var regEx = new RegExp("^[" + charsToTrim + "]+", "g");
    return origString.replace(regEx, "");
  }
}

export { Utility };

export const updateObject = (oldObject, updateProp) => {
  return {
    ...oldObject,
    ...updateProp,
  };
};

export const  _calculateAge = (birthday)  => { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
