import {
  TOGGLE_LOGOUT_MODAL,
  TOGGLE_DRAWER,
  ACTIVE_PATH_CHANGED,
} from "../actions/types.js";

const INITIAL_STATE = {
  logoutModalOpen: false,
  drawerModalOpen: false,
  activePath: "/",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOGOUT_MODAL:
      return { ...state, logoutModalOpen: action.value };
    case TOGGLE_DRAWER:
      return { ...state, drawerModalOpen: action.value };
    case ACTIVE_PATH_CHANGED:
      return { ...state, activePath: action.value };
    default:
      return state;
  }
};
