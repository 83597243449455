import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 250,
  },
  icon: {
    color: theme.palette.primary.main,
    height: 100,
    width: 100,
  },
}));

export default function BillingTotalTime(props) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        Total Time
      </Typography>
      <AccessTimeIcon className={classes.icon} />
      <Typography variant="body1">
        {props.totalTime ? props.totalTime.toFixed(2) : 0}s
      </Typography>
    </Paper>
  );
}
