import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import Button from "@material-ui/core/Button";
import {
  toggleLogin,
  archiveUser,
  updateUser,
  getUsersByOrg,
} from "../../actions/index";
import { ReactComponent as AccountImage } from "../../assets/images/account.svg";
import { IconButton } from "@material-ui/core";
import PermissionsService from "../../lib/PermissionsSystem";
import { Debug } from "../../lib";
import EditUser from "./EditUsers";

import Store from "../../store";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";
import CustomMaterialTable from "../sharedComponents/CustomMaterialTable";
import CustomDialog from "../sharedComponents/CustomDialog";
import {
  ARCHIVE_MODAL,
  EDIT_USERS_MODAL,
  NOT_ENOUGH_USERS_MODAL
} from "../sharedComponents/Modals/constants";
import withModals from "../sharedComponents/Modals/withModals";
import { UserTypes, UserStatus, USER_TYPES } from "../../lib/Constants";
import "./PatientsList.scss";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { withSnackbar } from "notistack";
import NotEnoughNF from "../practitionersManagement/addUsersStepper/NotEnoughNF";

const styles = (theme) => ({
  table: {
    // minWidth: 650,
    maxWidth: "100%",
    margin: "auto",
    padding: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  // tableHead: {
  // backgroundColor: theme.palette.primary.main
  //     backgroundColor: 'red'
  // },
  // tableHeadCell: {
  // color: theme.palette.common.white
  //     color: 'red !important'
  // },
  archive: {
    color: "#191919",
    fontSize: "22pt",
    "&:hover": {
      color: "#149FD8",
    },
  },
  unarchive: {
    color: "#149FD8",
    fontSize: "22pt",
  },
  iconStyle: {
    color: "#555555",
    textAlign: "center",
  },
  select: {
    background: "#FFFFFF",
    border: "2px solid #8FCAED",
    borderRaduis: "5px",
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  MenuItem: {
    "&:hover": {
      borderColor: "#FFFFFF",
    },
  },
  titleNF : {
    fontSize: "30px",
    width: "auto",
    lineHeight: "37px",
    margin: "20px auto",
    fontFamily: "MontserratBold",
    color: "#333437",
  }
});

class PractitionersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      is_archive_shown: false,
      selectedRowData: null,
      is_loaded: Array.isArray(props.users),
      is_error: false,
      selectOptions: [],
      roles : [],
      practitioners : [] ,
      NFErrorMsg : null
    };

    this.getTableSettings = this.getTableSettings.bind(this);
    this.handleDataLoad = this.handleDataLoad.bind(this);
  }

  async componentDidMount() {
    if (!this.state.is_loaded) {
      this.handleDataLoad();
    }
  }

  async componentDidUpdate (prevProps , prevState) {
    if(this.props.users && this.state.practitioners.length === 0){
      const prac = this.props.users.filter((x) => { 
        var  roles = x.roles.map(function(item) {
          return item['role_name'];
        });
        return roles.includes(UserTypes[1].value2)
      });
      if(prac && prac.length !== 0) { 
        this.setState({
          ...this.state ,
          practitioners : prac
        })
      }
  } 
  }

  handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    this.setState({
      ...this.state,
      selectOptions: typeof value === "string" ? value.split(",") : value,
    });
  };

  async handleDataLoad() {
    try {
      this.setState({
        ...this.state,
        is_loaded: false,
        is_error: false,
      });
      await this.props.dispatch(getUsersByOrg());
      this.setState({
        ...this.state,
        is_loaded: true,
        is_error: false,
      });
    } catch (e) {
      Debug.log(e);
      this.setState({
        ...this.state,
        is_loaded: true,
        is_error: true,
      });
    }
  }

  getTableSettings() {
    const dispatch = Store.dispatch;

    let tableColumns = [
      {
        title: "Report",
        editable: "never",
        render: (row) => {
          return (
            <Link to={ row.user_type === USER_TYPES.Patient ?  `/user-profile/${row.id}` : `/practitioner-profile/${row.id}`} type="button">
              <IconButton
                className="p-0"
                aria-label="practitioner profile"
                value={row.id}
              >
                <AccountImage />
              </IconButton>
            </Link>
          );
        },
      },
      {
        title: "Type",
        field: "roles",
        editable: "never",
        render: (row) => {
          if(row.roles.length === 0) {
            return (
              <span>{`${
                row.user_type === "PATIENT"&&"Patient"
              }`}</span>
            );
          }else {
            const roles = row?.roles?.map(i=>{

                let role = ""
                if(i.role_name === UserTypes[1].value2) {
                  role += UserTypes[1].label
                }else if(i.role_name === UserTypes[2].value) {
                  role += UserTypes[2].label
                }else if(i.role_name === UserTypes[3].value) {
                  role += UserTypes[3].label
                }
                return (<><span>{`${
                    role
                 }`}</span><br/></>  )
            })
            return roles
          }
         
        },
      },
      {
        title: "Name",
        field: "first_name",
        editable: "never",
        render: (row) => {
          return (
            <span>{`${row.first_name.trim()} ${row.last_name.trim()}`}</span>
          );
        },
      },
      {
        title: "Birth Date",
        field: "dob",
        editable: "never",
        render: (row) => {
          return (
            <span>{`${
              row.user_type === "PATIENT"
                ? moment(row.dob.split("T")[0]).format("YYYY-MM-DD") || ""
                : "-"
            }`}</span>
          );
        },
      },
      {
        title: "Gender",
        field: "gender",
        editable: "never",
        render: (row) => {
          return <span>{`${row.gender || ""}`}</span>;
        },
      },
      {
        title: "Email",
        field: "email",
        editable: "never",
      },
      {
        title: "Login Status",
        field: "status",
        editable: "always",
        render: (row) => {
          return row.user_type === "PATIENT" ? (
            <FormControlLabel
              control={
                <Switch
                  key={row.id + "_" + row.status}
                  checked={row.status === "ENABLED"}
                  onChange={async (e) => {
                    //row.status = row.status !== "ENABLED" ? <p className= 'loginStatusCanLogin'>Can login</p> : <p  className= 'loginStatusCantLogin'>Can’t login</p>;
                    row.status =
                    row.status !== "ENABLED" ? "ENABLED" : "DISABLED";
                    try {
                    const response =  await  dispatch(toggleLogin(row.id, row.status === "ENABLED")); 
                    if(response) { 
                      row.status =
                      row.status !== "ENABLED" ? "ENABLED" : "DISABLED";
                    }
                    } catch (e) {
                        row.status = "DISABLED";
                        this.props.enqueueSnackbar(e.response?.data?.message || "You are not allow to modify patient login status", {variant : 'error'})
                    }
                  }}
                  disabled={
                    row.id === PermissionsService.GetUserID() ||
                    row.status === "ARCHIVED"
                      ? true
                      : row.user_type === "PATIENT" ||
                        row.user_type === "ORG_USER"
                      ? !PermissionsService.HasPermission(
                          "org.admin.patients.manage"
                        )
                      : !PermissionsService.HasPermission(
                          "org.admin.users.update"
                        )
                  }
                  name="status"
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#8FCAED",
                    },
                  }}
                />
              }
              labelPlacement={"start"}
              label={
                row.status === "ENABLED" ? (
                  <p className="loginStatusCanLogin">Can login</p>
                ) : (
                  <p className="loginStatusCantLogin">Can’t login</p>
                )
              }
            />
          ) : (
            <p className="d-flex loginStatusNotApplicable">
              Not applicable{" "}
              <span className="loginStatusNotApplicable__icon">?</span>
            </p>
          );
        },
      },
    ];

    const canArchive =
      (this.props.user_types == undefined &&
        (PermissionsService.HasPermission("org.admin.patients.archive") ||
          PermissionsService.HasPermission("org.admin.users.archive"))) ||
      (this.props.user_types !== undefined &&
        ((this.props.user_types.includes("PATIENT") &&
          PermissionsService.HasPermission("org.admin.patients.archive")) ||
          (this.props.user_types.includes("USER") &&
            PermissionsService.HasPermission("org.admin.users.archive")) ||
          (this.props.user_types.includes("ORG_USER") &&
            PermissionsService.HasPermission("org.admin.users.archive"))));
    const row = this.state.selectedRowData;
    if (canArchive) {
      tableColumns.push(
        {
          title: "Actions",
          field: "is_archive",
          editable: "never",
          render: (row) => {
            var  roles = row.roles.map(function(item) {
              return item['role_name'];
            });
            if (
              (row.id != PermissionsService.GetUserID()) &
                (row.user_type === "PATIENT" &&
                  PermissionsService.HasPermission(
                    "org.admin.patients.archive"
                  )) ||
              (row.user_type === "USER" &&
                PermissionsService.HasPermission("org.admin.users.archive")) ||
              (row.user_type === "ORG_USER" &&
                PermissionsService.HasPermission("org.admin.users.archive")) ||
                (row.user_type === "ORG_ADMIN" &&
                PermissionsService.HasPermission("org.admin.users.archive"))
            ) {
              // If the user not archived, show the archive button
              if (row.status != "ARCHIVED") {
                return (
                  <div className="d-flex w-auto align-items-center justify-content-start">
                    <Edit
                      style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.5)" }}
                      onClick={async (e) => {
                        this.setState({
                          selectedRowData: row,
                          roles : roles
                        });
                        this.props.toggleEditUserModal();
                      }}
                    />
                    <p
                      className="action-text"
                      style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.5)" }}
                      onClick={async (e) => {
                        this.setState({
                          selectedRowData: row,
                          roles : roles
                        });
                        this.props.toggleEditUserModal();
                      }}
                    >
                      {"Edit"}
                    </p>
                    <ArchiveIcon
                      style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.5)" }}
                      onClick={async (e) => {
                        this.setState({
                          selectedRowData: row,
                        });
                        this.props.toggleArchiveModal();
                      }}
                    />
                    <p
                      className="action-text"
                      style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.5)" }}
                      onClick={async (e) => {
                        this.setState({
                          selectedRowData: row,
                        });
                        this.props.toggleArchiveModal();
                      }}
                    >
                      {"Archive"}
                    </p>
                  </div>
                );
              }
              // If the user is archived, show the unarchive button
              else {
                return (
                  <div className="d-flex w-auto align-items-center justify-content-start">
                    <Edit
                      style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.5)" }}
                    />
                    <p className="action-text">{"Edit"}</p>
                    <UnarchiveIcon
                      style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.5)" }}
                      onClick={async (e) => {
                        this.setState({
                          selectedRowData: row,
                        });
                        this.props.toggleArchiveModal();
                      }}
                    />
                    <p className="action-text">{"Unarchive"}</p>
                  </div>
                );
              }
            }
          },
        },
      );
    } else {
      return <div></div>;
    }

    return tableColumns;
  }

  onRowClick = (e, props) => {
    console.log(`Setting selected row to ${props.data.id}`);
  };

  onRowDoubleClick = (e, props) => {
    const row = props.data;
    this.props.history.push(`/user-profile/${row.id}`);
  };

  onRemoveSelectedClick = (e, data) => {
    this.setState({
      selectedRowData: data[0],
    });
    this.props.toggleArchiveModal();
  };

  onChangeSelectedRowData = (rows) => {

    this.setState({
      selectedRowData: rows[0],
    });
  };
   upgradePlanes = () => {
    this.props.history.push("/billing")
    this.setState({
      NFErrorMsg : null
    })
    this.props.toggleErrorModalNF()
  }

  handleReset = () => {
    this.setState({
      NFErrorMsg : null
    })
    this.props.toggleErrorModalNF()
  }

  onArchiveUser = async () => {
    const canArchive =
      (this.props.user_types == undefined &&
        (PermissionsService.HasPermission("org.admin.patients.archive") ||
          PermissionsService.HasPermission("org.admin.users.archive"))) ||
      (this.props.user_types !== undefined &&
        ((this.props.user_types.includes("PATIENT") &&
          PermissionsService.HasPermission("org.admin.patients.archive")) ||
          (this.props.user_types.includes("USER") &&
            PermissionsService.HasPermission("org.admin.users.archive")) ||
          (this.props.user_types.includes("ORG_USER") &&
            PermissionsService.HasPermission("org.admin.users.archive"))) ||
            (this.props.user_types.includes("ORG_ADMIN") &&
            PermissionsService.HasPermission("org.admin.users.archive")));
    const row = this.state.selectedRowData;
    if (canArchive) {
      if (
        (row.id != PermissionsService.GetUserID()) &
          (row.user_type === "PATIENT" &&
            PermissionsService.HasPermission("org.admin.patients.archive")) ||
        (row.user_type === "USER" &&
          PermissionsService.HasPermission("org.admin.users.archive")) ||
        (row.user_type === "ORG_USER" &&
          PermissionsService.HasPermission("org.admin.users.archive")) || 
          (row.user_type === "ORG_ADMIN" &&
                PermissionsService.HasPermission("org.admin.users.archive"))
      ) {
        this.props.toggleArchiveModal();
          if (row.status != "ARCHIVED") {
            try {
              await this.props.dispatch(archiveUser(row.id, true));
            }catch (err) {
              this.props.toggleErrorModalNF()
              this.setState({
                NFErrorMsg : err.response?.data?.message || "You are not allowed to modify patient login status"
              })            }
          } else {
            try {
              await this.props.dispatch(archiveUser(row.id, false));
            }catch (err) {
              this.props.toggleErrorModalNF()
              this.setState({
                NFErrorMsg : err.response?.data?.message || "You are not allowed to modify patient login status"
              })
            }
          }
          await this.props.dispatch(getUsersByOrg(this.props.user_types))
      } else {
        return <div></div>;
      }
    }
  };
  render() {
    const tableColumns = this.getTableSettings();
    const { classes, toggleArchiveModal, toggleEditUserModal, modals  , toggleErrorModalNF} =
      this.props;
    const  {practitioners} = this.state
    const tableActions = [];
    if (this.state.is_loaded) {
      if (!this.state.is_error) {
        let users = Array.isArray(this.props.users) ? this.props.users : [];
        if (
          this.state.selectOptions.includes(UserStatus[0].value) &&
          this.state.selectOptions.includes(UserStatus[1].value)
        ) {
          users = this.props.users;
        } else {
          if (
            this.state.selectOptions.includes(UserStatus[0].value) &&
            !this.state.selectOptions.includes(UserStatus[1].value)
          ) {
            users = users.filter((x) => x.status !== "ARCHIVED");
          }
          if (
            this.state.selectOptions.includes(UserStatus[1].value) &&
            !this.state.selectOptions.includes(UserStatus[0].value)
          ) {
            users = users.filter((x) => x.status === "ARCHIVED");
          }
        }
        if (Array.isArray(this.props.user_types)) {
          users = users.filter((x) =>
            this.props.user_types.includes(x.user_type)
          );
        }
          let userPat = [];
          let userPrac = [] 
          let userOrg = [];
          let usersDeveloper = [] ;
          let usersAdmin = [];
          let isContainUserTypes = false
          if (this.state.selectOptions.includes(UserTypes[0].value)) {
            isContainUserTypes = true
            userPat =  users.filter((x) => x.user_type === UserTypes[0].value);
          }
          if (this.state.selectOptions.includes(UserTypes[1].value)) {
            isContainUserTypes = true
            userPrac = users.filter((x) => { 
              var  roles = x.roles.map(function(item) {
                return item['role_name'];
              });
              return roles.includes(UserTypes[1].value2)
              });
          }
          if (this.state.selectOptions.includes(UserTypes[2].value)) {
            isContainUserTypes = true
            userOrg = users.filter((x) => { 
              var  roles = x.roles.map(function(item) {
                return item['role_name'];
              });
              return roles.includes(UserTypes[2].value)
              });
          }
          if (this.state.selectOptions.includes(UserTypes[3].value)) {
            isContainUserTypes = true
            usersAdmin = users.filter((x) => { 
              var  roles = x.roles.map(function(item) {
                return item['role_name'];
              });
              return roles.includes(UserTypes[3].value)
              });
          }
          const  setUsers = [...new Set([...userPat,...userOrg,...userPrac,...usersDeveloper,...usersAdmin])]
          var filtered = [];
          for(var arr in users){
            for(var filter in setUsers){
                if(users[arr].id === setUsers[filter].id){
                    filtered.push(users[arr]);
                }
            }
          }
          if(this.state.selectOptions.length !== 0 && isContainUserTypes  ) {
            users = filtered
          }
        return (
          <div className="user-list-table" position="fixed">
            <div data-update-time={this.state.update_time}></div>
            <CustomMaterialTable
              title=""
              columns={tableColumns}
              customClassNameSelect={classes.select}
              className={classes.paper}
              customMenuItem={classes.MenuItem}
              viewOptionClass={"viewOption"}
              data={users}
              UserTypes={UserTypes}
              UserStatus={UserStatus}
              actions={tableActions}
              selectOptions={this.state.selectOptions}
              handleSelectChange={this.handleSelectChange}
              onRowClick={this.onRowClick}
              onRowDoubleClick={this.onRowDoubleClick}
              onChangeSelectedRowData={this.onChangeSelectedRowData}
            ></CustomMaterialTable>

            <CustomDialog
              className="archived-modal"
              open={modals[ARCHIVE_MODAL]}
              toggle={toggleArchiveModal}
              hideCloseButton
            >
              <div className="text-center">
                <h4>
                  Are you sure you want to{" "}
                  {this.state.selectedRowData?.status !== "ARCHIVED"
                    ? "archive"
                    : "unarchive"}{" "}
                  {this.state.selectedRowData &&
                    this.state.selectedRowData?.first_name +
                      " " +
                      this.state.selectedRowData?.last_name}
                  ?
                </h4>
                <p>
                  {this.state.selectedRowData?.status !== "ARCHIVED" &&
                    "Archiving will remove their access to Neurofit VR and their patients will need to be reactivated"}
                </p>
                <Button onClick={toggleArchiveModal} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={() => this.onArchiveUser()}
                  variant="contained"
                  color="primary"
                >
                  {this.state.selectedRowData?.status !== "ARCHIVED"
                    ? "Archive"
                    : "Unarchive"}
                </Button>
              </div>
            </CustomDialog>
            <CustomDialog
              className="edit-user-modal"
              open={modals[EDIT_USERS_MODAL]}
              toggle={toggleEditUserModal}
              hideCloseButton
              maxWidth="md"
            >
              <div className="text-center">
                <h4>
                  Edit{" "}
                  {(this.state.selectedRowData?.user_type === "PATIENT" || this.state.roles.length === 0)
                    ? "Patient"
                    :(this.state.roles.includes(UserTypes[1].value2)) ?  "Practitioner" : "User"}{" "}
                  Profile
                </h4>
                <EditUser
                  infoUser={this.state.selectedRowData}
                  toggleEditUserModal={toggleEditUserModal}
                  practitioners= {practitioners}
                />
              </div>
            </CustomDialog>
            <CustomDialog 
              className="not-enough-user-modal"
              open={modals[NOT_ENOUGH_USERS_MODAL]}
              toggle={toggleErrorModalNF}
              hideCloseButton
              maxWidth="sm"
            > 
               <div className="text-center">
                <h2 className={classes.titleNF}>
                  Not enough Neurofit?
                </h2>
                <NotEnoughNF 
                    notEnoughNF = {this.state.NFErrorMsg}
                    handleReset = {this.handleReset}
                    MoveToBilling = {this.upgradePlanes}
                />
               </div>
            </CustomDialog>
          </div>
        );
      } else {
        return (
          <div>
            <p>Failed to load users</p>
          </div>
        );
      }
    } else {
      return <LoadingSpinner />;
    }
  }
}

PractitionersList.propTypes = {
  classes: PropTypes.object.isRequired,
};

PractitionersList.MapStateToProps = (state) => {
  let result = {};
  result.users = state.userReducer.user_list;
  Debug.log(state);
  return result;
};

const modalsProps = {
  toggleArchiveModal: ARCHIVE_MODAL,
  toggleEditUserModal: EDIT_USERS_MODAL,
  toggleErrorModalNF : NOT_ENOUGH_USERS_MODAL
};

export default connect(PractitionersList.MapStateToProps)(
  withStyles(styles)(withRouter(withModals(withSnackbar(PractitionersList), modalsProps)))
);
