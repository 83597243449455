import moment from "moment"
import http from "../http-common"
import util from "util"
import { Debug } from "../lib"
import {
  ACTIVE_PATH_CHANGED,
  ARCHIVE_USER,
  CREATE_USER,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_FAILURE,
  CREATE_ASSIGNMENT_RESET,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_RESET,
  DISPLAY_USERNAME,
  GET_ASSIGNED_PATIENTS,
  GET_ASSIGNMENTS,
  GET_DETAILS_CURRENT_AUTH_ORG,
  GET_GRAPH_DATA,
  GET_LONGEST_STREAK,
  GET_ORGANIZATIONS,
  GET_OVER_PROGRESS_OVER_TIME,
  GET_PATIENTS_SESSIONS_ORG,
  GET_RADAR_PLOT_DATA,
  GET_RATINGS_PAITENTS_ORG,
  GET_SELECTED_USER,
  GET_SESSIONS_BY_ORGANIZATION,
  GET_STACKED_BAR_CHART,
  GET_USER_SESSIONS,
  GET_USER_SKILL_LEVELS,
  GET_USER,
  GET_USERS_BY_ORG_ID,
  GET_USERS_BY_ORG,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_ACTIVITY_STATS_REQUEST,
  GET_USER_ACTIVITY_STATS_SUCCESS,
  GET_USER_ACTIVITY_STATS_FAILURE,
  GET_PATIENT_REPORT_CONFIG_REQUEST,
  GET_PATIENT_REPORT_CONFIG_SUCCESS,
  GET_PATIENT_REPORT_CONFIG_FAILURE,
  CREATE_PATIENT_REPORT_CONFIG_REQUEST,
  CREATE_PATIENT_REPORT_CONFIG_SUCCESS,
  CREATE_PATIENT_REPORT_CONFIG_FAILURE,
  CREATE_PATIENT_REPORT_CONFIG_CLEAR,
  HIDE_MODAL,
  NAV_COLLAPSE,
  NAV_EXPAND,
  REGISTER_ORGANIZATION,
  SHOW_MODAL,
  SIGN_IN,
  SIGN_OUT,
  SUBMIT_USER_DATA,
  TOGGLE_ATTENTION_LINE,
  TOGGLE_DRAWER,
  TOGGLE_LOGIN_FOR_USERS,
  TOGGLE_LOGOUT_MODAL,
  TOGGLE_MEMORY_LINE,
  TOGGLE_PROCESSING_LINE,
  UPDATE_ACCOUNT_PRIVILEGE,
  UPDATE_END_DATE_SELECT,
  UPDATE_SELECTED_ORGANIZATION,
  UPDATE_SELECTED_USER,
  UPDATE_START_DATE_SELECT,
  UPDATE_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_LANGUAGE,
  UPDATE_WITHIN_DATE_SELECT,
  GET_LEVELS_REQUEST,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAILURE,
  GET_LEVEL_REQUEST,
  GET_LEVEL_SUCCESS,
  GET_LEVEL_FAILURE,
  GET_LEVEL_CLEAR,
} from "./types"
import PermissionsService from "../lib/PermissionsSystem"

function tryDispatch(dispatch, args) {
  if (dispatch) {
    dispatch(args)
  }
}

//----------------------------- AUTH ACTIONS -----------------------------
export const signIn =
  ({ email, password }) =>
  async (dispatch) => {
    const response = await http
      .put("organization/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        Debug.log("Auth: " + response.headers.authorization)
        Debug.log("Refresh: " + response.headers.refresh_token)
        Debug.log("Permissions: " + JSON.stringify(response.data.permissions))
        localStorage.setItem("auth_token", response.headers.authorization)
        localStorage.setItem("refresh_token", response.headers.refresh_token)
        localStorage.setItem(
          "permissions_list",
          JSON.stringify(response.data.permissions)
        )
        localStorage.setItem("privilege", response.data.organization.privilege)
        localStorage.setItem("user_data", JSON.stringify(response.data.user))
        localStorage.setItem(
          "org_data",
          JSON.stringify(response.data.organization)
        )
        localStorage.setItem("org_name", response.data.organization.name)
        localStorage.setItem("org_status", response.data.organization.status)
        localStorage.setItem("features", JSON.stringify(response.data.features))
        PermissionsService.SetPermissions(response.data.permissions)
        PermissionsService.SetUserBlob(response.data.user)

        tryDispatch(dispatch, {
          type: SIGN_IN,
          payload: response,
        })
        return response.data
      })
      .catch((error) => {
        Debug.log(util.inspect(error))
        localStorage.clear()
        PermissionsService.Reset()
        if (error.response)
          return {
            status: error.response.status,
            is_error: true,
            message: error.response.data.message,
          }
        else
          return {
            status: 500,
            is_error: true,
            message: error.message || "An unknown error has occurred",
          }
      })

    return response
  }

export const signOut = () => {
  Debug.log("Logging Out!")
  localStorage.clear()
  localStorage.removeItem("auth_token")
  localStorage.removeItem("refresh_token")
  localStorage.removeItem("permissions_list")
  localStorage.removeItem("privilege")
  localStorage.removeItem("org_name")
  localStorage.removeItem("org_status")
  localStorage.removeItem("features")
  PermissionsService.Reset()

  return {
    type: SIGN_OUT,
  }
}

export const reqPasswordReset =
  ({ email }) =>
  async (dispatch) => {
    const response = await http
      .post("user/request_reset_password", {
        email,
      })
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })

    return response
  }

export const resetPassword =
  ({ new_password, token }) =>
  async (dispatch) => {
    const response = await http
      .post("user/reset_password", {
        token,
        new_password,
      })
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })

    return response
  }

export const validateResetToken =
  ({ token }) =>
  async (dispatch) => {
    const response = await http
      .get("user/validate_reset_token", {
        params: {
          token,
        },
      })
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })

    return response
  }

//----------------------------- USER ACTIONS -----------------------------

export const getUsersByOrg = () => async (dispatch) => {
  const response = await http
    .post("/organization/users", {
      include_archived: true,
    })
    .then((response) => {
      let data = response.data
      data.sort((a, b) => {
        if (a.last_name > b.last_name) return 1
        if (b.last_name > a.last_name) return -1
        return 0
      })
      return data
    })
    .catch((err) => {
      throw err
    })

  tryDispatch(dispatch, {
    type: GET_USERS_BY_ORG,
    payload: response,
  })
  return response
}

export const getDetailsForCurrOrg = () => async (dispatch) => {
  const response = await http
    .get("/organization/details")
    .then((response) => {
      let data = response.data
      return data
    })
    .catch((err) => {
      throw err
    })
  tryDispatch(dispatch, {
    type: GET_DETAILS_CURRENT_AUTH_ORG,
    payload: response,
  })
  return response
}

export const getUsersByOrgId = (org_id) => async (dispatch) => {
  const response = await http
    .get(`/organization/${org_id}/practitioners`)
    .then((response) => {
      let data = response.data.selectPrac
      data.sort((a, b) => {
        if (a.last_name > b.last_name) return 1
        if (b.last_name > a.last_name) return -1
        return 0
      })
      return data
    })
    .catch((err) => {
      throw err
    })

  tryDispatch(dispatch, {
    type: GET_USERS_BY_ORG_ID,
    payload: response,
  })
  return response
}
export const getUser = (user_id) => async (dispatch) => {
  const response = await http
    .get(`/organization/users/${user_id}`)
    .then((response) => {
      response.data.id = user_id
      return response
    })
    .catch((error) => {
      return error.response
    })

  tryDispatch(dispatch, {
    type: GET_USER,
    payload: response.data,
    response: response,
  })

  return response
}

// Action Creators
const getUserRequest = () => ({
  type: GET_USER_REQUEST,
})

const getUserSuccess = (userData) => ({
  type: GET_USER_SUCCESS,
  payload: userData,
})

const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  payload: error,
})

export const getUserThunk = (userId) => {
  return async (dispatch) => {
    dispatch(getUserRequest())

    try {
      const results = []
      const response = await http.get(`/organization/users/${userId}`)

      dispatch(getUserSuccess(response.data))
      return results
    } catch (error) {
      dispatch(getUserFailure(error.message))
      throw error
    }
  }
}

export const updateSelectedUser = (userEmail) => (dispatch) => {
  tryDispatch(dispatch, {
    type: UPDATE_SELECTED_USER,
    userEmail: userEmail,
  })
}

export const createUser = (formData) => async (dispatch) => {
  const response = await http
    .request({
      url: "/user",
      method: "post",
      data: {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
        dob: formData.dob,
        gender: formData.gender,
        education: formData.education,
        diagnosis: formData.diagnosis,
        is_patient: formData.is_patient,
        password: formData.password,
        role: formData.role,
        occupation: formData?.occupation,
        practitioner: formData?.practitioner,
      },
    })
    .then((response) => {
      return {
        status: response.status,
        message: response.data.message,
      }
    })
    .catch((error) => {
      return {
        status: 400,
        message: error.response.data.message,
        errorType: error.response.data.error_type,
      }
    })

  /*tryDispatch(dispatch, {
        type: CREATE_USER,
        payload: response
    });*/

  // dispatch(getUsersByOrg());

  return response
}

export const updateUser = (id, formData) => async (dispatch) => {
  const response = await http
    .request({
      url: `/organization/users/${id}`,
      method: "put",
      data: formData,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
  //Dispatch get users action to update user and fetch new list of users
  tryDispatch(dispatch, {
    type: UPDATE_USER,
    payload: response,
  })
  tryDispatch(dispatch, getUsersByOrg())
  return response
}

export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
})
export const updateUserSuccess = (responseData) => ({
  type: UPDATE_USER_SUCCESS,
  payload: responseData,
})
export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
})

export const updateUserThunk = (user) => {
  return async (dispatch) => {
    dispatch(updateUserRequest())

    try {
      const response = await http.request({
        url: `/organization/users/${user.id}`,
        method: "put",
        data: user,
      })

      dispatch(updateUserSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(updateUserFailure(error.message))
    }
  }
}

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
})

export const archiveUser = (user, is_archived) => async (dispatch) => {
  const response = await http
    .request({
      url: `/organization/users/${user}/archive`,
      method: "put",
      params: {
        is_archived,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })

  console.log(response)
  tryDispatch(dispatch, {
    type: ARCHIVE_USER,
    payload: response,
  })
}

export const toggleLogin = (userId, can_login) => async (dispatch) => {
  const response = await http
    .request({
      url: `/organization/users/${userId}/can_login`,
      method: "put",
      data: {
        can_login,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })

  tryDispatch(dispatch, getUsersByOrg())
  return response
}

export const toggleAllUsers = (can_login) => async (dispatch) => {
  const response = await http
    .request({
      url: `/organization/users/can_login`,
      method: "put",
      data: {
        can_login,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })

  tryDispatch(dispatch, {
    type: TOGGLE_LOGIN_FOR_USERS,
    payload: response,
  })

  tryDispatch(dispatch, getUsersByOrg())
}

export const getSelectedUser = (user_id) => async (dispatch) => {
  const response = await http
    .request({
      url: `/organization/users/${user_id}/`,
      method: "put",
      params: {
        user_id: user_id,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })

  tryDispatch(dispatch, {
    type: GET_SELECTED_USER,
    payload: response,
  })
  tryDispatch(dispatch, getUsersByOrg())
}

export const submitUserData = () => async (dispatch) => {
  tryDispatch(dispatch, {
    type: SUBMIT_USER_DATA,
  })
}

//----------------------------- MODAL ACTIONS -----------------------------

export const showModal =
  ({ modalProps, modalType }) =>
  (dispatch) => {
    modalProps.open = true

    tryDispatch(dispatch, {
      type: SHOW_MODAL,
      modalProps,
      modalType,
    })
  }

export const hideModal = () => (dispatch) => {
  tryDispatch(dispatch, {
    type: HIDE_MODAL,
  })
}

//----------------------------- GENERAL GRAPH ACTIONS (COGNITIVE PROFILE) -----------------------------

export const toggleMemoryLine = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_MEMORY_LINE,
    value,
  })
}

export const toggleAttentionLine = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_ATTENTION_LINE,
    value,
  })
}

export const toggleProcessingLine = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_PROCESSING_LINE,
    value,
  })
}

export const updateStartDateSelect = (date) => (dispatch) => {
  try {
    // date = date.toISOString()
    date = moment(date).utc()
  } catch {
    date = ""
  }

  dispatch({
    type: UPDATE_START_DATE_SELECT,
    date: date,
  })
}

export const updateEndDateSelect = (date) => (dispatch) => {
  try {
    date = moment(date).utc()
  } catch {
    date = ""
  }
  dispatch({
    type: UPDATE_END_DATE_SELECT,
    date: date,
  })
}

export const getGraphData = (user, startDate, endDate) => async (dispatch) => {
  const response = await http
    .request({
      url: decodeURIComponent(
        `/organization/users/${user}/sessions/aggregated`
      ),
      params: {
        fromDate: startDate,
        toDate: endDate,
        include_records: true,
      },
      method: "get",
    })
    .then((response) => {
      if (response.data.trail_making.session_count <= 0) {
        return undefined
      }
      return response.data
    })
    .catch((error) => {
      return error
    })

  dispatch({
    type: GET_GRAPH_DATA,
    payload: response,
  })
  return response
}

// Action Creators
const getUserActivityStatsRequest = (userId) => ({
  type: GET_USER_ACTIVITY_STATS_REQUEST,
  payload: {userId: userId},
})

const getUserActivityStatsSuccess = (userId, data) => ({
  type: GET_USER_ACTIVITY_STATS_SUCCESS,
  payload: {data: data, userId: userId},
})

const getUserActivityStatsFailure = (userId, error) => ({
  type: GET_USER_ACTIVITY_STATS_FAILURE,
  payload: {error: error, userId: userId},
})

// Thunk Action
export const getUserActivityStats = (userId) => {
  return async (dispatch) => {
    dispatch(getUserActivityStatsRequest(userId))

    try {
      const response = await http.get(`/user/${userId}/activity_stats`)
      dispatch(getUserActivityStatsSuccess(userId, response.data))
      return response.data
    } catch (error) {
      dispatch(getUserActivityStatsFailure(userId, error.message))
      throw error
    }
  }
}

// Action Creators
const getPatientReportConfigRequest = () => ({
  type: GET_PATIENT_REPORT_CONFIG_REQUEST,
})

const getPatientReportConfigSuccess = (data) => ({
  type: GET_PATIENT_REPORT_CONFIG_SUCCESS,
  payload: data,
})

const getPatientReportConfigFailure = (error) => ({
  type: GET_PATIENT_REPORT_CONFIG_FAILURE,
  payload: error,
})

// Thunk Action
export const getPatientReportConfig = (patientId) => {
  return async (dispatch) => {
    dispatch(getPatientReportConfigRequest())

    try {
      const response = await http.request({
        url: `/user/${patientId}/report_config`,
        method: "get",
      })
      dispatch(getPatientReportConfigSuccess(response.data.configuration))
      return response.data.configuration
    } catch (error) {
      dispatch(getPatientReportConfigFailure(error.message))
      throw error
    }
  }
}

// Action Creators
const createPatientReportConfigRequest = () => ({
  type: CREATE_PATIENT_REPORT_CONFIG_REQUEST,
})

const createPatientReportConfigSuccess = (data) => ({
  type: CREATE_PATIENT_REPORT_CONFIG_SUCCESS,
  payload: data,
})

const createPatientReportConfigFailure = (error) => ({
  type: CREATE_PATIENT_REPORT_CONFIG_FAILURE,
  payload: error,
})

// Thunk Action
export const createPatientReportConfig = (patientId, reportConfig) => {
  return async (dispatch) => {
    dispatch(createPatientReportConfigRequest())

    try {
      const response = await http.request({
        url: `/user/${patientId}/report_config`,
        method: "post",
        data: reportConfig,
      })
      dispatch(createPatientReportConfigSuccess(response.data.configuration))
      return response.data.configuration
    } catch (error) {
      dispatch(createPatientReportConfigFailure(error.message))
      throw error
    }
  }
}

export const createPatientReportConfigClear = () => (dispatch) => {
  dispatch({
    type: CREATE_PATIENT_REPORT_CONFIG_CLEAR,
  })
}



//----------------------------- USER RADAR PLOT ACTIONS (FEEDBACK / RATINGS) -----------------------------

export const getRadarPlotData =
  (user, start_date, end_date = new Date()) =>
  async (dispatch) => {
    const response = await http
      .request({
        method: "get",
        url: `/organization/users/${user}/ratings/averages?from=${start_date}&to=${end_date}`,
      })
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        return err.response
      })

    Debug.log("Radar data: " + util.inspect(response))
    dispatch({
      type: GET_RADAR_PLOT_DATA,
      payload: response,
    })
    return response
  }

export const updateWithinDateRange = (date) => (dispatch) => {
  dispatch({
    type: UPDATE_WITHIN_DATE_SELECT,
    date: date,
  })
}

//----------------------------- APPBAR ACTIONS -----------------------------

export const toggleLogoutModal = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_LOGOUT_MODAL,
    value,
  })
}

export const toggleDrawer = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_DRAWER,
    value,
  })
}

export const activeLinkChanged = (value) => (dispatch) => {
  dispatch({
    type: ACTIVE_PATH_CHANGED,
    value,
  })
}

//----------------------------- ORGANIZATIONS ACTIONS -----------------------------
export const getOrganizations = () => async (dispatch) => {
  const response = await http
    .request({
      url: "/organizations",
      method: "get",
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response
    })

  dispatch({
    type: GET_ORGANIZATIONS,
    payload: response,
  })
}

export const setPrivilege = (orgId, newPrivilege) => async (dispatch) => {
  const response = await http
    .request({
      url: `/organizations/${orgId}/privilege/${newPrivilege}`,
      method: "put",
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response
    })

  dispatch({
    type: UPDATE_ACCOUNT_PRIVILEGE,
    payload: response,
  })

  dispatch(getOrganizations())
}

export const registerOrganization = (formData) => async (dispatch) => {
  // For now we'll hard code the roles :(
  formData.role = "ORG_ADMIN"

  const response = await http
    .post("/organizations/create", formData)
    .then((res) => {
      return {
        status: res.status,
        message: res.data.message,
      }
    })
    .catch((err) => {
      return {
        status: 400,
        message: err.response.data.message || "An error has occurred",
      }
    })

  dispatch({
    type: REGISTER_ORGANIZATION,
    response: response,
  })

  dispatch(getOrganizations())
  return response
}

export const getSessionsByOrganization =
  (orgId, startDate, endDate) => async (dispatch) => {
    const response = await http
      .request({
        url: `/organizations/${orgId}/time_tracking/total`,
        method: "get",
        params: {
          from: startDate,
          to: endDate,
          include_records: true,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_SESSIONS_BY_ORGANIZATION,
          payload: response.data,
        })

        return response.data
      })
      .then((error) => {
        return error.response
      })
  }

export const ratingValueForOrganization =
  (org_id, start_date, end_date = new Date()) =>
  async (dispatch) => {
    const response = await http
      .request({
        method: "get",
        url: `/organization/${org_id}/ratings/averages?from=${start_date}&to=${end_date}`,
      })
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        return err.response
      })
    dispatch({
      type: GET_RATINGS_PAITENTS_ORG,
      payload: response,
    })
    return response
  }

export const progressOverTimeDataBasedOnFilter =
  (values) => async (dispatch) => {
    let filter = {
      exercise: null,
    }
    const {
      org_id,
      start_date,
      end_date,
      exercise,
      practitioners,
      sex,
      age,
      attendance,
    } = values

    if (exercise && Array.isArray(exercise) && exercise.length > 0) {
      filter.exercise = exercise.map((item) => {
        return "&exercise=" + item
      })
      filter.exercise = filter.exercise.join("")
    }

    if (
      practitioners &&
      Array.isArray(practitioners) &&
      practitioners.length > 0
    ) {
      filter.practitioners = practitioners
    }
    if (sex && Array.isArray(sex) && sex.length > 0) {
      filter.sex = sex
    }
    if (age || (Array.isArray(age) && age.length > 0)) {
      filter.age = age
    }
    if (attendance || (Array.isArray(attendance) && attendance.length > 0)) {
      filter.attendance = attendance
    }
    const response = await http
      .request({
        method: "get",
        url: `/graphing/progress_over_time?org_id=${org_id}&start_date=${start_date}&end_date=${end_date}${
          filter?.exercise ? filter?.exercise : ""
        }`,
        params: {
          practitioners: filter.practitioners,
          sex: filter.sex,
          age: filter.age,
          attendance: filter.attendance,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        return err.response
      })
    dispatch({
      type: GET_OVER_PROGRESS_OVER_TIME,
      payload: response,
    })
    return response
  }
export const StackedBarChart = (values) => async (dispatch) => {
  const { org_id, start_date, end_date } = values
  const response = await http
    .request({
      method: "get",
      url: `/graphing/stacked_chart?org_id=${org_id}&start_date=${start_date}&end_date=${end_date}`,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err.response
    })
  dispatch({
    type: GET_STACKED_BAR_CHART,
    payload: response,
  })
  return response
}
export const getSessionsForOrg =
  (org_id, startDate, endDate) => async (dispatch) => {
    const response = await http
      .request({
        url: decodeURIComponent(`/organization/${org_id}/sessions`),
        params: {
          form: startDate,
          to: endDate,
        },
        method: "get",
      })
      .then((response) => {
        if (!response.data) {
          return undefined
        }
        return response.data
      })
      .catch((error) => {
        return error
      })

    dispatch({
      type: GET_PATIENTS_SESSIONS_ORG,
      payload: response,
    })
    return response
  }

export const updateSelectedOrganization = (id) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_ORGANIZATION,
    id,
  })
}

/* ------------------- User Session: Correct vs Incorrect ------------------- */

export const getUserSession = (user_id) => async (dispatch) => {
  const response = await http
    .request({
      url: decodeURIComponent(`/organization/users/${user_id}/sessions`),
      method: "get",
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })

  dispatch({
    type: GET_USER_SESSIONS,
    payload: response,
  })

  return response
}

/* --------------------------- Data for line graph -------------------------- */

export const getUserSessionData =
  (user_id, startDate, endDate, include_records = false) =>
  async (dispatch) => {
    const response = await http
      .request({
        url: `/organization/users/${user_id}/sessions/aggregated`,
        method: "get",
        params: {
          fromDate: startDate,
          toDate: endDate,
          include_records,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        return err.response
      })

    dispatch({
      type: GET_USER_SKILL_LEVELS,
      payload: response,
    })

    return response
  }

export const getLongestStreak =
  (user, start_date, end_date = new Date()) =>
  async (dispatch) => {
    const response = await http
      .request({
        method: "get",
        url: `/user/${user}/longest_streak?from=${start_date}&to=${end_date}`,
      })
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        return err.response
      })
    dispatch({
      type: GET_LONGEST_STREAK,
      payload: response,
    })
    return response
  }

/* --------------------------- practitioner -------------------------- */
export const getAssignedPatients = (user_id) => async (dispatch) => {
  const response = await http
    .request({
      method: "get",
      url: `/user/${user_id}/assign_patients`,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err.response
    })
  dispatch({
    type: GET_ASSIGNED_PATIENTS,
    payload: response,
  })
  return response
}

//----------------------------- GAME LEVEL ACTIONS ----------------------------
const getLevelRequest = () => ({
  type: GET_LEVEL_REQUEST,
})

const getLevelSuccess = (levelData) => ({
  type: GET_LEVEL_SUCCESS,
  payload: levelData,
})

const getLevelFailure = (error) => ({
  type: GET_LEVEL_FAILURE,
  payload: error,
})

export const getLevelClear = () => ({
  type: GET_LEVEL_CLEAR,
})

export const getLevel = (id) => {
  return async (dispatch) => {
    dispatch(getLevelRequest())

    try {
      const response = await http.request({
        url: `/levels/${id}`,
        method: "get",
      })
      dispatch(getLevelSuccess(response.data))

      return response.data
    } catch (error) {
      dispatch(getLevelFailure(error.message))
      throw error
    }
  }
}

const getLevelsRequest = () => ({
  type: GET_LEVELS_REQUEST,
})

const getLevelsSuccess = (levelsData) => ({
  type: GET_LEVELS_SUCCESS,
  payload: levelsData,
})

const getLevelsFailure = (error) => ({
  type: GET_LEVELS_FAILURE,
  payload: error,
})

export const getLevels = () => {
  return async (dispatch) => {
    dispatch(getLevelsRequest())

    try {
      const response = await http.request({
        url: "/levels/",
        method: "get",
      })
      dispatch(getLevelsSuccess(response.data))

      return response.data
    } catch (error) {
      dispatch(getLevelsFailure(error.message))
      throw error
    }
  }
}

//---------------------------- ASSIGNMENTS ACTIONS ----------------------------
export const getAssignments = (userId) => async (dispatch) => {
  const result = await http
    .request({
      url: `assignments/${userId}?all_time=true`,
      method: "get",
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response
    })

  dispatch({
    type: GET_ASSIGNMENTS,
    payload: result,
  })
}

// Action Creators
const createAssignmentRequest = () => ({
  type: CREATE_ASSIGNMENT_REQUEST,
})

const createAssignmentSuccess = (assignmentData) => ({
  type: CREATE_ASSIGNMENT_SUCCESS,
  payload: assignmentData,
})

const createAssignmentFailure = (error) => ({
  type: CREATE_ASSIGNMENT_FAILURE,
  payload: error,
})

export const createAssignmentReset = () => ({
  type: CREATE_ASSIGNMENT_RESET,
})

export const createAssignment = (userId, activities, duration = null) => {
  return async (dispatch) => {
    dispatch(createAssignmentRequest())

    try {
      const response = await http.request({
        url: `/assignments/${userId}`,
        method: "post",
        data: { activities, duration },
      })

      dispatch(
        createAssignmentSuccess({ data: response.data, assignment: activities })
      )
      dispatch(getAssignments(userId))
      return response.data
    } catch (error) {
      dispatch(createAssignmentFailure(error.message))
      throw error
    }
  }
}

export const updateActivityRequest = () => ({
  type: UPDATE_ACTIVITY_REQUEST,
})

export const updateActivitySuccess = (updatedActivity) => ({
  type: UPDATE_ACTIVITY_SUCCESS,
  payload: updatedActivity,
})

export const updateActivityFailure = (error) => ({
  type: UPDATE_ACTIVITY_FAILURE,
  payload: error,
})

export const updateActivityReset = () => ({
  type: UPDATE_ACTIVITY_RESET,
})

export const updateActivity = (activityId, updatedData) => {
  return async (dispatch) => {
    dispatch(updateActivityRequest())

    try {
      const response = await http.request({
        url: `/assignments/${activityId}`,
        method: "put",
        data: updatedData,
      })

      dispatch(updateActivitySuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(updateActivityFailure(error.message))
    }
  }
}
