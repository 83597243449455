import React from "react";
import "./UserColumn.scss";
function UserColumn(props) {
  const column = props.column;
  const containerContnt = (
    <React.Fragment>
      {column.map((element, index) => {
        return index === 0 ? (
          <div key={index} className="header">
            {element}
          </div>
        ) : (
          <div key={index} className="body">
            {element}
          </div>
        );
      })}
    </React.Fragment>
  );
  return (
    <div
      className={`UserColumn-container ${
        props.selectedColumn === props.index ? "selected" : ""
      }`}
      onClick={props.onClick}
    >
      {containerContnt}
    </div>
  );
}

export default UserColumn;
