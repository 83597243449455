import React from "react";
import { Bar } from "react-chartjs-2";
import ChartBase from "./ChartBase";
import { getUserSessionData } from "../../../actions/index";

class UserPerformanceRatio extends ChartBase {
  constructor(props) {
    super(props, getUserSessionData);
    this.renderChart = this.renderChart.bind(this);
    this.parseData = this.parseData.bind(this);
    this.dispatchAPI = this.dispatchAPI.bind(this);
  }

  dispatchAPI(user_id, start_date, end_date) {
    return getUserSessionData(user_id, start_date, end_date);
  }

  parseData(payload) {
    const {
      mail_room,
      nature_path,
      restaurant,
      spot_the_difference,
      trail_making,
      western,
    } = payload;

    // --------------- this gets the skill's average correct value --------------
    const getCorrect = (value) => {
      return value.session_count !== 0 ? value.avg.correct : 0;
    };

    //-------------- this gets the skill's average incorrect value -------------
    const getIncorrect = (value) => {
      return value.session_count !== 0
        ? value.avg.missed || value.avg.incorrect
        : 0;
    };

    //------ put correct values in to an array and gets the sum afterwards -----
    const attentionCorr = [
      getCorrect(mail_room),
      getCorrect(spot_the_difference),
    ].reduce((attentionCorr, x) => Math.floor(attentionCorr + x));

    const memoryCorr = [
      getCorrect(trail_making),
      getCorrect(nature_path),
    ].reduce((memoryCorr, x) => Math.floor(memoryCorr + x));

    const processingCorr = [getCorrect(restaurant), getCorrect(western)].reduce(
      (processingCorr, x) => Math.floor(processingCorr + x)
    );

    //----- put incorrect values in to an array and gets the sum afterwards ----
    const attentionInCorr = [
      getIncorrect(mail_room),
      getIncorrect(spot_the_difference),
    ].reduce((attentionInCorr, x) => Math.floor(attentionInCorr + x));

    const memoryInCorr = [
      getIncorrect(trail_making),
      getIncorrect(nature_path),
    ].reduce((memoryInCorr, x) => Math.floor(memoryInCorr + x));

    const processingInCorr = [
      getIncorrect(restaurant),
      getIncorrect(western),
    ].reduce((processingInCorr, x) => Math.floor(processingInCorr + x));

    let chartData = {
      labels: ["Attention", "Memory", "Processing"],
      datasets: [
        {
          data: [attentionCorr, memoryCorr, processingCorr],
          label: "Correct",
          fillColor: "rgb(21.2%,76.9%,98%)",
          backgroundColor: "rgb(21.2%,76.9%,98%)",
        },
        {
          data: [attentionInCorr, memoryInCorr, processingInCorr],
          label: "Incorrect",
          fillColor: "rgb(98.4%,31%,45.9%)",
          backgroundColor: "rgb(98.4%,31%,45.9%)",
        },
      ],
    };
    const chartOptions = {
      legend: { display: false },
      scales: {
        xAxes: [
          {
            ticks: { fontSize: 15 },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontSize: 15,
            },
            scaleLabel: {
              display: true,
              labelString: "Cumulative Incorrect vs Correct",
              fontSize: 15,
            },
          },
        ],
      },
      responsive: true,
    };

    return { chartData, chartOptions };
  }

  renderChart() {
    return (
      <Bar data={this.state.chartData} options={this.state.chartOptions} />
    );
  }
}

export default UserPerformanceRatio;

/*
const UserPerformanceRatio = (props) => {
    if(payload !== null && payload !== undefined){

        const {mail_room, nature_path, restaurant, spot_the_difference, trail_making, western} = payload;
    // --------------- this gets the skill's average correct value --------------

        const getCorrect = value => {
            return value.session_count !== 0 ? value.avg.correct : 0
        }

    // -------------- this gets the skill's average incorrect value -------------

        const getIncorrect = value => {
            return value.session_count !== 0 ? value.avg.incorrect : 0
        }

    // ------ put correct values in to an array and gets the sum afterwards -----

        const attentionCorr = [getCorrect(mail_room), getCorrect(spot_the_difference)]
                        .reduce((attentionCorr, x) => Math.floor(attentionCorr + x));

        const memoryCorr = [getCorrect(trail_making), getCorrect(nature_path)]
                    .reduce((memoryCorr, x) => Math.floor(memoryCorr + x));

        const processingCorr = [getCorrect(restaurant), getCorrect(western)]
                        .reduce((processingCorr, x) => Math.floor(processingCorr + x));

    // ----- put incorrect values in to an array and gets the sum afterwards ----

        const attentionInCorr = [getIncorrect(mail_room), getIncorrect(spot_the_difference)]
                            .reduce((attentionInCorr, x) => Math.floor(attentionInCorr + x));

        const memoryInCorr = [getIncorrect(trail_making), getIncorrect(nature_path)]
                    .reduce((memoryInCorr, x) => Math.floor(memoryInCorr + x));

        const processingInCorr = [getIncorrect(restaurant), getIncorrect(western)]
                        .reduce((processingInCorr, x) => Math.floor(processingInCorr + x));

        return (
            <div>
                <Bar
                    data={{
                        labels: ['Attention', 'Memory', 'Processing'],
                        datasets: [
                            {
                                data: [attentionCorr, memoryCorr, processingCorr],
                                label: "Correct",
                                fillColor: "rgb(21.2%,76.9%,98%)",
                                backgroundColor: 'rgb(21.2%,76.9%,98%)',
                            },
                            {
                                data: [attentionInCorr, memoryInCorr, processingInCorr],
                                label: "Incorrect",
                                fillColor: "rgb(98.4%,31%,45.9%)",
                                backgroundColor: 'rgb(98.4%,31%,45.9%)',
                            },
                        ]
                    }}

                    options={
                        {   legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                ticks:{
                                    fontSize: 15
                                }
                                }],
                                yAxes: [
                                {
                                    ticks: {
                                    beginAtZero: true,
                                    fontSize: 15
                                    },
                                    scaleLabel: {
                                    display: true,
                                    labelString: 'Cumulative Incorrect vs Correct',
                                    fontSize: 15
                                    }
                                },
                                ]
                            },
                            responsive: true,
                        }
                    }
                />
            </div>
        )
    }else{
        return (<h2>No Session Data</h2>)
    }


}
*/
