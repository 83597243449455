import { NAV_EXPAND, NAV_COLLAPSE } from "../actions/types"

const INITIAL_STATE = {
  isExpanded: true,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NAV_EXPAND:
      return {
        isExpanded: true,
      }
    case NAV_COLLAPSE:
      return {
        isExpanded: false,
      }
    default:
      return state
  }
}

export default reducer
