import React, { useState, useEffect } from "react";
import Register from "./Register";
import { loadStripe } from "@stripe/stripe-js";
import stripe from "@stripe/react-stripe-js";

//const stripePromise = require('stripe')('pk_live_51JRiXRBWsdetEdR0RNskAvglxtlJRDrmQbQ2Rop1X3BaWxFUl5YPbUGs9KB4bW7qdwc6QowV00U2dw4mFJO6N48K00FIUERxqB');

const RegisterContainer = () => {
  // logic should go here

  const [checkoutData, setCheckoutData] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      gender: "",
      pronouns: "",
    },
    {
      pricing_id: null,
      title: "",
      desc: "",
      price_monthly_usd: 0,
      price_anually_usd: 0,
      starting: 0,
      is_yearly: false,
    },
  ]);

  // changes in any array
  useEffect(() => {
    console.log(checkoutData);
  }, [checkoutData]);

  // only trigger when card form data changes
  useEffect(() => {
    console.log(checkoutData);
  }, [checkoutData[2]]);

  /*stripePromise.customers.create({ checkoutData }, (error, customer) => {
        if (error) {
          console.error(error);
        } else {
          console.log(customer);
        }
    }); */

  return (
    <Register
      checkoutData={checkoutData}
      callbackSetCheckoutData={setCheckoutData}
    />
  );
};

export default RegisterContainer;
