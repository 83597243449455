import React from "react"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
// Absolute import paths are not working with this configuration & CRA version
import EditPadIcon from "../../../assets/images/EditPad.svg"

const useStyles = makeStyles((theme) => ({
  tableRow: {
    // coupled with _variables.scss gray-200
    borderBottom: theme.palette.gray[200],
  },
  wideCell: {
    minWidth: "150px",
  },
  styledCell: {
    borderBottom: 0,
  },
  editLink: {
    width: "1rem",
    height: "1rem",
    // border: "1px solid",
    // borderColor: theme.palette.orange[200],
    borderRadius: "0.25rem",
    "&:hover": {
      // borderColor: theme.palette.orange[300],
      "& > svg": {
        color: theme.palette.orange[300],
      },
    },
  },
  editIcon: {
    color: theme.palette.orange[200],
    // position: "relative",
    // top: "-0.5rem",
    // right: "-0.5rem",
  },
}))

export default function OrganizationsListRow(props) {
  function privilegeText(privilege) {
    switch (privilege) {
      case -1:
        return "Inactive"
      case 0:
        return "Active"

      case 1:
        return "Admin"

      default:
        return "Error"
    }
  }

  const classes = useStyles()

  const owner = props.Owner
  const first_name = owner ? owner.first_name : "<missing>"
  const last_name = owner ? owner.last_name : "<missing>"
  const email = owner ? owner.email : "<missing>"
  return (
    <TableRow
      component='tr'
      key={props.id}
      hover={true}
      className={classes.tableRow}>
      <TableCell align='left'>
        <Link to={`/org-profile/${props.id}`}>{props.name}</Link>
      </TableCell>
      <TableCell scope='row'>Default</TableCell>
      <TableCell scope='row'>
        {first_name} {last_name}
      </TableCell>
      <TableCell align='left'>{email}</TableCell>
      <TableCell align='left'>{props.updated_at.split(" ")[0]}</TableCell>
      <TableCell align='left'>{props.created_at.split(" ")[0]}</TableCell>

      <TableCell align='left'>{privilegeText(props.privilege)}</TableCell>
      <TableCell align='left'>Billing Status</TableCell>
      <TableCell align='left'>
        <Link
          style={{ textDecoration: "none" }}
          to={`/org-profile/${props.id}`}
          className='navbar-link'>
          <img src={EditPadIcon} alt='Users' className={classes.editIcon} />
        </Link>
      </TableCell>
    </TableRow>
  )
}
