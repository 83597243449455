import { assignmentsToActivities } from "components/v2/sharedComponents/assignmentUtils"
import {
  GET_ASSIGNMENTS,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_FAILURE,
  CREATE_ASSIGNMENT_RESET,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_RESET,
} from "../actions/types"

const INITIAL_STATE = {
  assignments: [],
  activities: [],
  hasFetched: false,
  creating: false,
  createSuccess: null,
  createError: null,
  updating: false,
  updateSuccess: null,
  updateError: null,
}

const assignmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGNMENTS:
      return {
        ...state,
        hasFetched: true,
        assignments: action.payload || [],
        activities: assignmentsToActivities(action.payload) || [],
      }
    case CREATE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        creating: true,
        createSuccess: false,
        createError: null,
      }
    case CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        creating: false,
        createSuccess: true,
        createError: null,
        assignments: state.assignments,
      }
    case CREATE_ASSIGNMENT_FAILURE:
      return {
        ...state,
        creating: false,
        createSuccess: false,
        createError: action.payload,
      }
    case CREATE_ASSIGNMENT_RESET:
      return {
        ...state,
        creating: false,
        createSuccess: null,
        createError: null,
      }
    case UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        updating: true,
        updateSuccess: false,
        updateError: null,
      }
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        updateError: null,
        activities: state.activities.map((activity) =>
          activity.id === action.payload.id
            ? { ...activity, ...action.payload }
            : activity
        ),
      }
    case UPDATE_ACTIVITY_FAILURE:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        updateError: action.payload,
      }
    case UPDATE_ACTIVITY_RESET:
      return {
        ...state,
        updating: false,
        updateSuccess: null,
        updateError: null,
      }

    default:
      return state
  }
}

export default assignmentReducer
