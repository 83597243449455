import React, { useState, useEffect } from "react";
import StepThree from "./StepThree";

const StepThreeCont = ({ checkoutData, callbackSetCheckoutData }) => {
  const [cardInfo, setCardInfo] = useState({
    cardName: "",
    cardNumber: "",
    cardType: "",
    cardExpiration: "",
    cardSecurityCode: "",
    cardPostalCode: "",
    focus: "",
  });

  const handleToken = (token, address) => {
    console.log({ token, address });
  };

  return (
    <StepThree callbackHandleToken={handleToken} checkoutData={checkoutData} />
  );
};

export default StepThreeCont;
