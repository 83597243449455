import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
} from "@material-ui/core";

// Component Imports
import StepOne from "./StepOne";
import StepTwoCont from "./StepTwo/StepTwoCont";
import StepThreeCont from "./StepThree/StepThreeCont";

const useStyles = makeStyles({
  root: {
    width: "75%",
    margin: "0em auto",
    border: "1px solid #999",
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "red",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "red",
    },
  },
});

const Register = ({ checkoutData, callbackSetCheckoutData }) => {
  // REACT HOOKS
  const [activeStep, setActiveStep] = useState(0);
  // steps
  const steps = ["Sign up", "Choose a plan", "Checkout"];
  // material ui style
  const classes = useStyles();

  const handleNext = () => {
    console.log("next");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(steps.length);
  };

  useEffect(() => {
    console.log(checkoutData);
  }, []);

  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <StepOne
            activeSteps={activeStep}
            setActiveStep={setActiveStep}
            callbackHandleNext={handleNext}
            steps={steps}
            checkoutData={checkoutData}
            callbackSetCheckoutData={callbackSetCheckoutData}
          />
        );
      case 1:
        return (
          <StepTwoCont
            checkoutData={checkoutData}
            callbackSetCheckoutData={callbackSetCheckoutData}
            callbackHandleNext={handleNext}
          />
        );
      case 2:
        return (
          <StepThreeCont
            checkoutData={checkoutData}
            callbackSetCheckoutData={callbackSetCheckoutData}
          />
        );
      default:
        return "Unknown Step";
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.root}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <>
        {activeStep === steps.length ? (
          "The Steps completed"
        ) : (
          <>
            {getStepsContent(activeStep)}
            <Button onClick={handleNext}>
              {activeStep === steps.length ? "Finish" : "Next"}
            </Button>
          </>
        )}
      </>
    </div>
  );
};

export default Register;
