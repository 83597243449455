import React, { Component } from "react";
import Dropzone from "react-dropzone";
import readXlsxFile from "read-excel-file";
import "./UploadFile.scss";
import { allowedExtensions, schemaPrac } from "../../lib/Constants";

export default class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      fileInfos: [],
    };
  }

  componentDidMount() {}

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({
      progress: 0,
      currentFile: currentFile,
    });
    readXlsxFile(currentFile, { schemaPrac, ignoreEmptyRows: true })
      .then(async (rows) => {
        if (!rows.length || rows.length === 0 || rows.length === 1) {
          alert(
            "Something went wrong with your uploaded file, Please download the sample template to see the shape of accepted files"
          );
          return;
        }
        this.props.setUsers(rows);
        this.setState({
          progress: 100,
          fileInfos: currentFile,
        });
      })
      .catch((e) => {
        alert(
          "Something went wrong with your uploaded file, Please download the sample template to see the shape of accepted files"
        );
        return;
      });
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  }

  render() {
    const { selectedFiles, currentFile, progress, message, fileInfos } =
      this.state;
    const { isPatient } = this.props;
    return (
      <>
        <div className="body-modal">
          <div className="uploadFileModel">
            <div style={{ maxWidth: "500px" }}>
              <h4>
                Download the CSV template below to ensure you’ve got all the
                correct columns!
              </h4>
              {isPatient ? (
                <ul>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Date of Birth</li>
                  <li>Sex</li>
                  <li>Email</li>
                  <li>Diagnosis</li>
                </ul>
              ) : (
                <ul>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Occupation</li>
                  <li>Email</li>
                  <li>Sex</li>
                </ul>
              )}
              <h3>
                <a
                  href={`https://neurofitfrontendbucket31244-staging.s3.ca-central-1.amazonaws.com/${
                    isPatient
                      ? "sample-patients.xlsx"
                      : "sample-practitioners.xlsx"
                  }`}
                  download="Sample"
                >
                  Download Sample Template
                </a>
              </h3>
            </div>
            <div style={{ maxWidth: "500px" }}>
              {currentFile && (
                <div className="progress mb-3">
                  <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + "%" }}
                  >
                    {progress}%
                  </div>
                </div>
              )}

              <Dropzone
                onDrop={this.onDrop}
                multiple={false}
                accept={allowedExtensions}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles[0].name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles[0].name}
                        </div>
                      ) : (
                        <>
                          <p>
                            Drag and drop or{" "}
                            <a className="ancher-file">choose a file</a> to
                            upload your Users. All .csv, .xlsx, and .xls files
                            types are supported
                          </p>
                        </>
                      )}
                    </div>
                    <aside className="selected-file-wrapper">
                      <button
                        className="btn upload-button"
                        disabled={!selectedFiles}
                        onClick={this.upload}
                      >
                        <span class="material-icons">file_upload</span>
                      </button>
                    </aside>
                  </section>
                )}
              </Dropzone>

              {message && <div className="alert alert-light" role="alert">
                {message}
              </div> }

              {fileInfos.length > 0 && (
                <div className="card">
                  <div className="card-header">List of Files</div>
                  <ul className="list-group list-group-flush">
                    {fileInfos.map((file, index) => (
                      <li className="list-group-item" key={index}>
                        <a href={file.url}>{file.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
