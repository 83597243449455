/**
 * This is the main file for the login page
 */

import React, { Component } from "react";
import LoginForm from "./LoginForm.js";
import { Container } from "@material-ui/core";
import { signIn } from "../../actions";

class Login extends Component {
  render() {
    return (
      <Container maxWidth="xl" className="nf-login-page">
        <LoginForm loginUser={signIn} />
      </Container>
    );
  }
}

export default Login;
