import React, { Component } from "react";
import GeneralMetricsChart from "./GeneralMetricsChart";

export default class ChartWrapper extends Component {
  componentDidMount() {
    this.setState({
      chart: new GeneralMetricsChart(
        this.refs.chart,
        this.props.data,
        this.props.attentionLineToggle,
        this.props.processingLineToggle,
        this.props.memoryLineToggle
      ),
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillReceiveProps(nextProps) {
    this.state.chart.update(
      nextProps.data,
      nextProps.attentionLineToggle,
      nextProps.processingLineToggle,
      nextProps.memoryLineToggle
    );
  }

  render() {
    return (
      <div
        style={{
          marginLeft: "72px",
        }}
        className="chart-area"
        ref="chart"
      ></div>
    );
  }
}
