import React from "react";
import { Link } from "react-router-dom";

import './index.scss'
const successPayment = (props) => {
    return (
        <div className="success-container">
            <section className="sucess-msg patient-grid" >
                <p>
                    Congratulations!<br/>
                    <br/>
                    You’ve successfully upgraded to the {props.currInfo?.product_name} Plan<br/>
                    <br/>
                    <Link to={`/`} >Start adding users to grow your organization!</Link><br/>
                </p>
            </section>
        </div>
    )
}

export default successPayment