export const DEFAULT_PRONOUN_SETS = [
  { subject: "he", object: "him", possessive: "his" },
  { subject: "she", object: "her", possessive: "her" },
  { subject: "they", object: "them", possessive: "their" },
];

export const DEFAULT_GENDERS = ["pnts", "female", "male", "non-binary"];

export const USER_TYPES = {
  Patient: "PATIENT",
  Practitioner: "ORG_USER",
  Admin: "ORG_ADMIN",
};

export const MAPPING_OCCUPATION = [
  { label: "General Practitioner", id: "therapist" },
  { label: "Occupational Therapist", id: "ot" },
  { label: "Physical Therapist", id: "pt" },
  { label: "Speech Language Pathologist", id: "slp" },
];

export const _calendarOptions = {
  Day: "Day",
  Week: "Week",
  Month: "Month",
};

export const formatDate = {
  dateFormat: "DD MMMM,YYYY",
  defaultFormat: "YYYY/MM/DD",
  monthFormat: "YYYY/MM",
};

export const urlSession = {
  createSession: {
    url: "organization/scheduled_sessions",
  },
};

export const availableTime = (startTime, endTime) => {
  let times = []; // time array
  let time;
  let ap = [" AM", " PM"]; // AM-PM
  let quarterHours = ["00", "15", "30", "45"];

  for (let i = startTime; i < endTime; i++) {
    for (let j = 0; j < 4; j++) {
      if (i === 12) {
        time = i + ":" + quarterHours[j] + ap[Math.floor(i / 12)];
      } else {
        time =
          ("0" + (i % 12)).slice(-2) +
          ":" +
          ("0" + quarterHours[j]).slice(-2) +
          ap[Math.floor(i / 12)];
      }
      times.push(time);
    }
  }
  times.push(
    [
      ("0" + (endTime % 12)).slice(-2) +
        ":" +
        ("0" + "00").slice(-2) +
        ap[Math.floor(endTime / 12)],
    ][0]
  );
  return times;
};

//timezone
export const defaultTimezone = [
  {
    label: "UTC-8 Pacific Standard Time",
    value: "UTC-8",
  },
  {
    label: "UTC-7	Mountain Standard Time",
    value: "UTC-7",
  },
  {
    label: "UTC-6 Central Standard Time",
    value: "UTC-6",
  },
  {
    label: "UTC-5	Eastern Standard Time",
    value: "UTC-5",
  },
  {
    label: "UTC-4 Atlantic Standard Time",
    value: "UTC-4",
  },
];

export const duration = [
  {
    value: 15,
    label: "15 Minutes",
  },
  {
    value: 30,
    label: "30 Minutes",
  },
  {
    value: 45,
    label: "45 Minutes",
  },
  {
    value: 60,
    label: "1 Hours",
  },
  {
    value: 75,
    label: "1.25 Hours",
  },
  {
    value: 90,
    label: "1.5 Hours",
  },
];

// Allowed extensions for input file
export const allowedExtensions = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

export const schemaPrac = {
  "First Name": {
    prop: "firstname",
    type: String,
    required: true,
  },
  "Last Name": {
    prop: "lastname",
    type: String,
    required: true,
  },
  Email: {
    prop: "email",
    type: String,
  },
  "Occupation ": {
    prop: "contact",
    required: true,
    type: String,
    oneOf: ["therapist", "ot", "pt", "slp"],
  },
};

export const FeatureFlags = {
  recurringEvents: false,
};

export const UserTypes = [
  { label: "Patient", value: "PATIENT" },
  { label: "Practitioner", value: "ORG_USER", value2: "GENERAL_PRACTITIONER"},
  { label: "Org Administrator", value: "ORG_ADMIN" },
  { label: "Neurofit Admin", value: "NEUROFIT_ADMIN" },
];
export const filterArrayUserType = [ "PATIENT" , "GENERAL_PRACTITIONER" , "NEUROFIT_ADMIN"  , "ORG_ADMIN"];
export const UserStatus = [
  { label: "Active", value: "UNARCHIVED" },
  { label: "Archived", value: "ARCHIVED" },
];
export const MAIN_COLUMN_PATIENT = [
  "First Name",
  "Last Name",
  "Date of Birth",
  "Sex",
  "Email",
  "Diagnosis",
];
export const MAIN_COLUMN_PRACTITONER = [
  "First Name",
  "Last Name",
  "Occupation",
  "Email",
  "Sex",
];
export const SEX_TYPES = ["male", "female" , "other"];
export const DIAGNOSIS = ["Concussion", "Attention deficit hyperactivity disorder", "Attention deficit disorder", "Transient ischemic attack", "Cerebrovascular accident" ,"Autism spectrum disorder","Developmental learning disorder","Alzheimer's disease","Traumatic brain injury","Other"];
export const OCCUPATION = ["General Practitioner", "Occupational Therapist", "Physical Therapist", "Speech Language Pathologist"];

export const Education = [
  "No schooling",
  "Some elementary school (unfinished)",
  "Completed elementary school",
  "Some secondary/high school (unfinished)",
  "Completed secondary/high school",
  "Some technical, community college (unfinished)",
  "Completed technical, community college",
  "Some university (unfinished)",
  "Bachelor's degree",
  "Master's degree ",
  "Professional degree or doctorate",
];
export const TOOLTIP_AVG_PATIENT_MOOD = {
  achievement : "a measure of perceived accomplishment by user within a session",
  perceived_difficulty:"a measure of perceived difficulty by user within a session",
  difficulty :"a measure of perceived difficulty by user within a session",
  effort:"a measure of perceived effort by user within a session",
  enjoyment:"a measure of perceived satisfaction by user within a session",
  frustration:"a measure of perceived frustration by user within a session",
  impulsivity:"a measure of perceived task intention/attention by user within a session",
  motivation:"a measure of users interacting with Neurofit sessions",
  Accuracy:"a measure of task precision by user within a session",
}

