import React, { useState, useEffect } from "react";
import { Line, BoxPlot } from "react-chartjs-2";
import moment from "moment";
import { capitalize } from "../../../lib/index";
import { Chart } from "react-chartjs-2";
import PropTypes from "prop-types";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
import zoomPlugin from '../../../assets/js/chartjs-plugin-zoom';
Chart.plugins.register(zoomPlugin); 

const UserSkillLevels = (props) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    parseData(props.userSessionData);
  }, [props.userSessionData]);

  const parseData = (payload) => {
    let datasets = [];

    const is_single_day =
      moment(props.start_date).local().add(1, "second").format("YYYYMMDD") ==
      moment(props.end_date).local().format("YYYYMMDD");
    const unit = is_single_day ? "hour" : "day";
    const time_format = is_single_day ? "h:MMA" : "YYYY MMM DD" ;

    if (payload !== null && payload !== undefined) {
      // do not delete this line

      let colorSet = ["#79C5C8", "#FEC7C4", "#FFDBA6"];

      let categories = {};

      // Group all related level categories into arrays
      for (let key in payload) {
        let category = payload[key].categories;
        if (category !== null && category !== "null") {
          categories[category] = categories[category] || [];
          categories[category].push(payload[key]);
        }
      }

      let ix = 0;
      // Iterate over all level categories
      for (let category in categories) {
        const levels = categories[category];

        // Will store all data for this line
        let combinedData = {};

        // Iterate over all levels within the category
        for (const level of levels) {
          // If the level has recorded sessions
          if (level.session_count !== 0) {
            // Add all days to the graph
            Object.keys(level.daily_aggregates).map((date) => {
              let local = moment.utc(date).local(true);
              combinedData[local] = combinedData[local] || [];
              combinedData[local].push({
                x: local,
                y: level.daily_aggregates[date].avg.difficulty,
                level: level.name,
              });
              return date; // avoid warnings
            });
          }
        }

        // This will be the dataset we sent to ChartJS
        let aggregated = [];

        // Iterate over each day per category, to make a line item for that day
        Object.keys(combinedData).map((date) => {
          let set = combinedData[date];
          // If there's more then one level type in the day, iterate over them and aggregate
          if (set.length > 1) {
            let min = 5;
            let max = 0;
            let avg = 0;
            let tags = [];

            for (let ix = 0; ix < set.length; ix++) {
              let item = set[ix];
              min = Math.min(min, item.y);
              max = Math.max(max, item.y);
              avg += item.y;
              tags.push({ name: item.level, value: item.y });
            }

            avg /= set.length;

            aggregated.push({
              x: moment(date),
              y: avg,
              yMin: min,
              yMax: max,
              tags: tags,
            });
          }
          // There was only 1 level played on this day, we can directly insert it
          else {
            aggregated.push({
              x: set[0].x,
              y: set[0].y,
              yMin: set[0].y,
              yMax: set[0].y,
              tags: [{ name: set[0].level, value: set[0].y }],
            });
          }
          return date; // avoid warnings
        });

        // Sort the dataset to make sure that they are graphed left to right
        aggregated.sort((a, b) => {
          if (moment(a.x) > moment(b.x)) return 1;
          if (moment(a.x) < moment(b.x)) return -1;
          return 0;
        });

        // Create the ChartJS dataset for the category
        let dataset = {
          label: capitalize(category),
          data: aggregated,
          borderColor: colorSet[ix],
          fill: false,
          tension: 0,
        };
        ix++;

        datasets.push(dataset);
      }
    }

    /* ----------------- convert to a data for the scatter plot ----------------- */
    const data = {
      maintainAspectRatio: false,
      responsive: false,
      datasets: datasets,
    };

    /* ----------------------- options for the line graph ----------------------- */
    const options = {
      maintainAspectRatio: false,
      legend: props.legend,
      responsive: true,
      type: "line",
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: unit,
              minUnit: unit,
              round: unit,
              parser: time_format,
            },
            ticks: {
              fontSize: 14,
              min: moment(props.start_date).local(),
              max: moment(props.end_date).local(),
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              callback: function (value) {
                if (value % 1 === 0) {
                  return value;
                }
              },
              suggestedMin: 0,
              suggestedMax: 5,
            },
            scaleLabel: {
              display: true,
              labelString: "Performance Over Time",
              fontSize: 20,
            },
          },
        ],
      },

      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
            threshold: 10,
            speed: 20,
          },
          zoom: {
            enabled: true,
            pinch : true,
            drag: false,
            mode: "xy",
            speed: 0.1,
            mode: "xy",
          },
          limits: {
            y: { min: "original", max: "original" },
            x: { min: "original", max: "original" }
          }
        }
      },
    };

    setChartData({ chartData: data, chartOptions: options });
  };

  return props.isFetching ? (
    <LoadingSpinner />
  ) : chartData.chartData !== undefined ? (
    <Line
      type="line"
      data={chartData.chartData}
      options={chartData.chartOptions}
    />
  ) : (
    <div>Data not loaded</div>
  );
};

UserSkillLevels.propTypes = {
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  isFetching: PropTypes.string.isRequired,
};

export default UserSkillLevels;
