import React from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { capitalize, Debug } from '../../lib';
import PropTypes from 'prop-types'
import AdminActions from '../../actions/AdminActions'
import OrgChartBase from './OrgChartBase';
import Grid from '@material-ui/core/Grid'

import { GenerateColours } from '../../lib/Colors';
import { Checkbox, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateRangePickers from '../sharedComponents/DateRangePickers';

import { Chart } from 'react-chartjs-2'
import pluginTrendLineLinear from '../../assets/js/chartjs-plugin-trendline';
import zoomPlugin from '../../assets/js/chartjs-plugin-zoom';
import { CloudDownload } from '@material-ui/icons';

Chart.plugins.register(pluginTrendLineLinear);
Chart.plugins.register(zoomPlugin); 

class ActiveUsersChart extends OrgChartBase {
    constructor(props) {
        super(props);
        this.parseData = this.parseData.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.dispatchAPI = this.dispatchAPI.bind(this);
        this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
        this.handleDailyModeChanged = this.handleDailyModeChanged.bind(this);
        this.shouldLoad = this.shouldLoad.bind(this);

        this.state = {
            ...this.state,
            is_daily: false,
            start_date: props.start_date,
            end_date: props.end_date,
            available_levels : props.available_levels
        }
    }
    
    dispatchAPI() {
        return AdminActions.GetActiveUsers({ 
            org_id: this.props.org_id, 
            start_date: this.state.start_date, 
            end_date: this.state.end_date, 
            is_daily: this.state.is_daily });
    } 

    shouldLoad(prevProps, prevState) {
        return (
            this.state.is_daily != prevState.is_daily ||
            this.state.start_date != prevState.start_date ||
            this.state.end_date != prevState.end_date
        );
    }

    /**
     * Handles parsing the result of the API request and generating a ChartJS chart
     * @param {Object} payload 
     */
    parseData(payload) {
        let datasets = [];

        const timeFormat = this.state.is_daily ? 'YYYY-MM-DD' : 'YYYY-MMMM';

        if (payload !== null && payload !== undefined) {            
            let organizations = {};

            if (payload.length > 0) {
                // Iterate over all entries
                for(const entry of payload) {
                    // If the organization has not been added yet, create it in our temp dataset
                    if (organizations[entry.org_id] === undefined) {
                        organizations[entry.org_id] = {
                            name: entry.org_name,
                            id: entry.org_id,
                            data: []
                        };
                    }
                    // Add the entry to the organization's dataset
                    organizations[entry.org_id].data.push({
                        x: moment.utc(entry.date).format(timeFormat),
                        y: entry.active_users
                    });
                }

                Debug.log(organizations);
                const org_count = Object.keys(organizations).length;
                const colors = GenerateColours(org_count)

                let ix = 0;
                for (const org_id in organizations) {
                    const org = organizations[org_id];
                    let color = colors[ix++].toString();

                    // Create the ChartJS dataset for the category
                    let dataset = {
                        label: org.name,
                        data: org.data,
                        backgroundColor: color,
                        borderColor: color,
                        showLine: true,
                        fill: false,
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 5,
                        lineTension: 0,
                        trendLineLinear: {
                            style: color,
                            lineStyle: "dotted",
                            borderDash: [8, 8],
                            width: 2,
                            timeFormat: timeFormat
                        }
                    };

                    datasets.push(dataset);
                }
            }
        }

        /* ----------------- convert to a data for the scatter plot ----------------- */
        const data = {
            datasets
        }
        /* ----------------------- options for the line graph ----------------------- */
        const options = {
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: this.state.is_daily ? 'day' : 'month',
                        minUnit: this.state.is_daily ? 'day' : 'month',
                        round: this.state.is_daily ? 'day' : 'month',
                        parser: timeFormat,
                    },
                    ticks: {
                        min: moment(this.state.start_date).format(timeFormat),
                        max: moment(this.state.end_date).format(timeFormat),
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontSize: 15,
                        callback: function (value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                        suggestedMin: 0,
                        suggestedMax: 20,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Active Users',
                        fontSize: 20
                    }
                }, ],
            },
        }

        return { chartData: data, chartOptions: options };
    }

    async exportData (data ) {
        let rows_level = []
        for(let level of data.levels) {
          for( let template of level.templates) {
            // Fetch all sessions so we have the most up to date data
            let rows = await AdminActions.GetSessions({
              level_id: template.level_id,
              level_version: template.level_version,
              org_id: undefined,
              start_date : this.state.start_date , 
              end_date : this.state.end_date
            })(() => {}).then((result) => {
              return result.results;
            });
            if(rows || rows?.length !== 0 || rows !== undefined) {
              rows_level.push({level_name : level.name , rows : rows})
            }
        }
      }
      let filename ="organizations.csv"
  // Start compositing the CSV
  var csvFile = "";
  rows_level = await rows_level.filter(x => x.rows !== undefined)
  rows_level.map(rows => {
  // Handles processing a single record
    var processRow = function (row) {
          var finalVal = "";
          // Iterate over all fields of the blob and add them
          for (let key of Object.keys(row)) {
            // Use JSON stringify
            let innerValue = JSON.stringify(row[key] || "undefined");
            // Replace single " with ""
            var result = innerValue.replace(/"/g, '""');
            // Replace If there are special characters in the value, we need to escape the whole cell
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            // Append cell to results
            finalVal += result + ",";
          }
          // Newline to end row
          return finalVal + "\n";
    };
    // Next row
    csvFile +="level ," + rows.level_name;
    // Next row
    csvFile += "\n";
    // Add all headers for fields
    if(rows.rows.length !== 0) {
        for (let key of Object.keys(rows?.rows[0])) {
          csvFile += key + ",";
        }
      }else {
        csvFile += "" + ",";
      }
    // Next row
    csvFile += "\n";
    if(rows.rows.length !== 0) {
        for (var i = 0; i < rows.rows.length; i++) {
          csvFile += processRow(rows.rows[i]);
        }
      }else {
        csvFile += ""
      }
    // Next row
    csvFile += "\n";
  })
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  // Generate the blob to be downloaded
  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
      // If someones is using IE, god save their soul
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      }
      // Using a real browser
      else {
        // Create a temp element for downloading the file
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          // We add the hidden link, click it, then remove it, lol
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }

    handleDailyModeChanged(event) {
        this.setState({
            ...this.state,
            is_daily: event.target.checked
        })
    }
    
    handleDateRangeChanged(start_date, end_date) { 
        this.setState({
            ...this.state,
            start_date: start_date.toISOString(),
            end_date: end_date.toISOString()
        });
    }


    renderChart() {
        return (
            <Grid container item xs={12} spacing={1} justifyContent="flex-start" alignItems="center" style={{marginLeft : '20px'}} >
                <Grid item columns={{ xs: 4, sm: 8, md: 12 }} >
                    <DateRangePickers 
                        startDate={this.state.start_date}
                        endDate={this.state.end_date}
                        monthly={!this.state.is_daily} 
                        disableFuture
                        onValueChanged={this.handleDateRangeChanged} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={this.state.is_daily} 
                                onChange={this.handleDailyModeChanged}
                            />
                        }
                        label="Daily"
                    />
                    <Tooltip title="Export Data" style={{marginLeft : '20px'}} >
                            <CloudDownload
                              onClick={() => this.exportData(this.state.available_levels)}
                            />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Bar data={this.state.chartData} options={this.state.chartOptions} />
                </Grid>
            </Grid>
        )
    }
}

ActiveUsersChart.propTypes = {
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    org_id: PropTypes.string
}

export default ActiveUsersChart
