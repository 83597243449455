import React, { Component } from "react";

import Container from "@material-ui/core/Container";
import OrganizationsList from "./OrganizationsList";
import AddOrganizationForm from "./AddOrganizationForm";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import AdminActions from "../../actions/AdminActions";
import {
  Checkbox,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Box,
  Collapse,
  TablePagination,
  Tooltip,
} from "@material-ui/core";

import ActiveUsersChart from "./ActiveUsersChart";
import DateRangePickers from "../sharedComponents/DateRangePickers";
import moment from "moment";

import store from "../../store";
import AlignedLabel from "../sharedComponents/AlignedLabel";

import AddPermissionForm from "./permissions/AddPermForm";
import PermissionsDetail from "./permissions/PermissionsDetail";
import MaterialTable from "material-table";
import {
  CloudDownload,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MailTwoTone,
  Refresh,
} from "@material-ui/icons";
import { Utility } from "../../lib/Utility";

import * as queryString from "query-string";

class DeepDataDive extends Component {
  constructor(props) {
    super(props);

    let query = queryString.parse(props.location.search);
    this.state = {
      selected_level_id: null,
      selected_level_version: null,
      available_levels: [],
      template_data_tables: {},
      table_refs: {},
      filter_org_id: query.org_id,
    };

    this.composeDataTables = this.composeDataTables.bind(this);
    this.renderDataTable = this.renderDataTable.bind(this);
    this.renderMetaTemplateRow = this.renderMetaTemplateRow.bind(this);
    this.setTemplateOpen = this.setTemplateOpen.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  componentDidMount() {
    AdminActions.GetAvailableLevelVersions({
      org_id: this.state.filter_org_id,
    })(this.props.dispatch).then((res) => {
      console.log("metatemplates:");
      console.log(res);
      this.setState({
        ...this.state,
        available_levels: res.levels || [],
        is_template_expanded: {},
      });
      this.composeDataTables();
    });

    if (this.state.filter_org_id !== undefined) {
      AdminActions.GetOrganization({ org_id: this.state.filter_org_id })(
        this.props.dispatch
      ).then((res) => {
        this.setState({
          ...this.state,
          filter_org_name: res.name,
        });
      });
    }
  }

  async exportData(level, template) {
    let filename = level.name + ".csv";

    // Fetch all sessions so we have the most up to date data
    let rows = await AdminActions.GetSessions({
      level_id: template.level_id,
      level_version: template.level_version,
      org_id: this.state.filter_org_id,
    })(this.props.dispatch).then((result) => {
      return result.results;
    });

    // Handles processing a single record
    var processRow = function (row) {
      var finalVal = "";
      // Iterate over all fields of the blob and add them
      for (let key of Object.keys(row)) {
        // Use JSON stringify
        let innerValue = JSON.stringify(row[key] || "undefined");
        // Replace single " with ""
        var result = innerValue.replace(/"/g, '""');
        // Replace If there are special characters in the value, we need to escape the whole cell
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        // Append cell to results
        finalVal += result + ",";
      }
      // Newline to end row
      return finalVal + "\n";
    };

    // Start compositing the CSV
    var csvFile = "";

    // Add all headers for fields
    for (let key of Object.keys(rows[0])) {
      csvFile += key + ",";
    }
    // Next row
    csvFile += "\n";

    // Iterate over all data and add to CSV
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side

    // Generate the blob to be downloaded
    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    // If someones is using IE, god save their soul
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    // Using a real browser
    else {
      // Create a temp element for downloading the file
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        // We add the hidden link, click it, then remove it, lol
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  composeDataTables() {
    let tempState = this.state;
    for (let level of tempState.available_levels) {
      for (let template of level.templates) {
        let columns = [];
        for (let key of Object.keys(template.fields.RootFields)) {
          columns.push({
            field: key,
            title: key,
            render: (data) => {
              return <div>{JSON.stringify(data[key])}</div>;
            },
          });
        }
        tempState.table_refs[template.id] = React.createRef();
        console.log(columns);
        let tableRef = tempState.table_refs[template.id];
        const tableSettings = {
          columns,
          tableRef,
          options: {
            search: true,
            headerStyle: {
              textAlign: "left",
              minWidth: "100px",
              position: "sticky",
              top: 0,
              backgroundColor: "#D0EEFB",
              color: "#555",
            },
            paging: true,
            pageSize: 10,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 25, 50, 100],
            tableLayout: "auto",
          },
          data: async (query) => {
            return new Promise((resolve, reject) => {
              AdminActions.GetSessions({
                level_id: template.level_id,
                level_version: template.level_version,
                limit: query.pageSize,
                offset: query.page * query.pageSize,
                org_id: this.state.filter_org_id,
              })(this.props.dispatch)
                .then((result) => {
                  console.log(result);
                  let data = {
                    data: result.results,
                    page: query.page,
                    totalCount: result.total_count,
                  };
                  console.log(data);
                  resolve(data);
                })
                .catch((err) => reject(err));
            });
          },
          components: {
            Pagination: (props) => {
              return (
                <td>
                  <table
                    style={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      background: "white",
                      zIndex: 999,
                    }}
                  >
                    <tbody>
                      <tr>
                        <TablePagination {...props} />
                        <td>
                          <Tooltip title="Refresh Data">
                            <Refresh
                              onClick={() =>
                                tableRef.current &&
                                tableRef.current.onQueryChange()
                              }
                            />
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title="Export Data">
                            <CloudDownload
                              onClick={() => this.exportData(level, template)}
                            />
                          </Tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              );
            },
          },
          actions: [
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                tableRef.current && tableRef.current.onQueryChange(),
            },
          ],
        };
        tempState.template_data_tables[template.id] = tableSettings;
      }
    }
    this.setState(tempState);
  }

  renderDataTable(template, sessionData) {
    const template_chart_data = this.state.template_data_tables[template.id];
    return template_chart_data !== undefined ? (
      <MaterialTable {...template_chart_data} />
    ) : (
      <React.Fragment />
    );
  }

  setTemplateOpen(id, isOpen) {
    let state = this.state;
    let newState = Object.assign({}, state.is_template_expanded);
    for (let key in Object.keys(newState)) {
      newState[key] = false;
    }
    newState[id] = isOpen;
    state.is_template_expanded = newState;
    this.setState(state);
  }

  renderMetaTemplateRow(template) {
    let open = this.state.is_template_expanded[template.id] || false;
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.setTemplateOpen(template.id, !open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell align="center">{template.level_version}</TableCell>
          <TableCell align="right">{template.dependant_count}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>{this.renderDataTable(template)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  render() {
    const levels =
      this.state.available_levels.length > 0 ? (
        this.state.available_levels.map((level) => {
          return (
            <Accordion key={level.id} style={{ minWidth: "95%" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${level.name}-content`}
                id={`${level.name}-header`}
              >
                <Typography>{level.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {level.templates.map((template) => {
                  return (
                    <TableContainer key={template.id} component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align="center">Template ID</TableCell>
                            <TableCell align="right">Session Count</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.renderMetaTemplateRow(template)}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <LoadingSpinner />
      );

    return (
      //<Container maxWidth="lg" style={{marginTop: '24px'}}>
      <Grid
        container
        direction="column"
        style={{
          overflowX: "auto",
          flex: "none",
          width: "auto",
          minWidth: "95%",
        }}
      >
        <Typography>
          Found {this.state.available_levels.length} Levels
        </Typography>
        {this.state.filter_org_id !== undefined &&
          this.state.filter_org_name && (
            <Typography>
              Showing Data For: {this.state.filter_org_name}
            </Typography>
          )}
        {levels}
      </Grid>
      //</Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissionsReducer.permissions,
    hasFetchedPerms: state.permissionsReducer.hasFetchedPerms,
    roles: state.permissionsReducer.roles,
    hasFetchedRoles: state.permissionsReducer.hasFetchedRoles,
    permission_count: state.permissionsReducer.permission_count,
  };
};

export default connect(mapStateToProps, (dispatch) => {
  return {
    GetAvailableLevelVersions: AdminActions.GetAvailableLevelVersions,
    GetSessions: AdminActions.GetSessions,
    dispatch,
  };
})(DeepDataDive);
