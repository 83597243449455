import React from "react"
import Navbar from "./Navbar"
import { useHistory } from "react-router-dom"

const NavbarContainer = ({ isAuthenticated, logoutUser, privilege }) => {
  const history = useHistory()

  const onSignOutClick = (e) => {
    logoutUser(e)
    history.push("/")
  }
  if (isAuthenticated) {
    return <Navbar onSignOutClick={onSignOutClick} privilege={privilege} />
  }
  return null
}

export default NavbarContainer
