const defaultPatientState = {
  detailedView: false,
  basicInformation: {
    firstName: "",
    lastName: "",
    dob: "",
    diagnosis: "",
    fitpoints: 0,
  },
}
const patientReducer = (state = defaultPatientState, action) => {
  switch (action.type) {
    case "setBasicInformation":
      return {
        ...state,
        basicInformation: {
          id: action.payload.id,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          dob: action.payload.dob,
          org_id: action.payload.org_id,
          diagnosis: action.payload.patient_data?.diagnosis,
          fitpoints: action.payload.patient_data?.fitpoints,
        },
      }
    default:
      return state
  }
}

export default patientReducer
