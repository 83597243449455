import React from "react";
import { Grid, Typography, Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  daysDisplay: {
    paddingTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "1800px",
  },
  card: {
    width: "150px",
    textAlign: "center",
    backgroundColor: theme.palette.info.light,
    color: theme.palette.primary.contrastText,
  },
}));

const DaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function WeekHeader() {
  const classes = useStyles();

  return (
    <Grid className={classes.daysDisplay} container>
      {/* Temporary List providing the values for days 1 to 7*/}
      {[0, 1, 2, 3, 4, 5, 6].map((value) => (
        <Card className={classes.card} variant="outlined" key={value}>
          <Typography> {DaysOfWeek[value]} </Typography>
        </Card>
      ))}
    </Grid>
  );
}
