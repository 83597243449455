import React, { useEffect } from "react";

import LogoutButton from "./LogoutButton.js";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import logo from "../../assets/images/neurofit-logo.svg";

//components
import {
  Button,
  Typography,
  Toolbar,
  AppBar,
  InputBase,
  IconButton,
  Paper,
  InputAdornment,
  Grid,
  Hidden,
  Divider,
  Tooltip,
} from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EmailIcon from "@material-ui/icons/Email";
import MaterialLink from "@material-ui/core/Link";
import { spacing } from "@material-ui/system";

import MenuIcon from "@material-ui/icons/Menu";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TimelineIcon from "@material-ui/icons/Timeline";
import FeedbackIcon from "@material-ui/icons/Feedback";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SearchIcon from "@material-ui/icons/Search";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer, activeLinkChanged } from "../../actions";
import PermissionsService from "../../lib/PermissionsSystem.js";
import "./AppBar.scss";

//neurofit logo

// const drawerWidth = 240;
const drawerWidth = 288;

const useStyles = makeStyles((theme) => ({
  root: {
    //   flexGrow: 1,
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  navLink: {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: "18px",
  },
  navLinkIcon: {
    marginRight: 0,
    marginLeft: "auto",
    paddingLeft: "1em",
  },
  drawerNavLink: {
    color: "#222",
    textDecoration: "none",
    borderBottom: 1,
    fontSize: "24px",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  logoutButton: {
    margin: 0,
    width: "100%",
  },
  feedbackButton: {
    width: "100%",
    height: "48px",
    textDecoration: "none",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "17.5%",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& img": {
      width: "40px",
    },
  },
  active: {
    borderBottom: "2px solid #333437",
  },
}));

// MainLinks -- this renders the logout component
// checks if the user is authenticated

const renderMainLinks = (props, classes, anchorEl, setAnchorEl, history) => {
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // if the user is authenticated, logout link renders
  if (props.isAuthenticated) {
    let user = PermissionsService.GetUser();
    return (
      <div>
        <span>{user ? `Hello ${user.first_name}` : ""}</span>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={(e) => {
              props.logoutUser(e);
              history.push("/");
            }}
          >
            <span>Logout</span>
            <span className={`${classes.navLinkIcon}`}>
              <ExitToAppIcon />
            </span>
          </MenuItem>
          <MenuItem>
            <MaterialLink
              href={
                process.env.REACT_APP_CONTACT_EMAIL || "mailto:info@neurofit.ca"
              }
              color="inherit"
              underline="none"
            >
              <span>Contact Neurofit</span>
              <span className={`${classes.navLinkIcon}`}>
                <EmailIcon />
              </span>
            </MaterialLink>
          </MenuItem>
        </Menu>
      </div>
    );
  }
};

function renderDrawerLinks(props, classes, handleDrawerToggle) {
  return (
    <div>
      <List>
        <Link
          to={"/"}
          className={classes.drawerNavLink}
          onClick={handleDrawerToggle}
        >
          <ListItem sx={{ borderBottom: 1 }}>Manage Users</ListItem>
        </Link>
        <Link
          to={"/schedule"}
          className={classes.drawerNavLink}
          onClick={handleDrawerToggle}
        >
          <ListItem>Session Schedule</ListItem>
        </Link>
        <Link
          to={"/billing"}
          className={classes.drawerNavLink}
          onClick={handleDrawerToggle}
        >
          <ListItem>Billing Info</ListItem>
        </Link>
        {props.privilege === 1 && (
          <React.Fragment>
            <Link
              to={"/organizations"}
              className={classes.drawerNavLink}
              onClick={handleDrawerToggle}
            >
              <ListItem>Manage Organizations</ListItem>
            </Link>
            {/* <Link to={"/deep_data"} className={classes.drawerNavLink}>
              <Tooltip title="Deep Data Dive">
                <ListItem button>
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            </Link> */}
          </React.Fragment>
        )}
        {/* <LogoutButton logoutUser={props.logoutUser} className={classes.logoutButton} inDrawer={true} /> */}
      </List>
      <a
        href={"https://forms.gle/Xf88szo7w3MLeEj2A"}
        rel="noopener noreferrer"
        target="_blank"
        className={classes.feedbackButton}
      >
        <Button className={classes.feedbackButton} color="secondary">
          Give Feedback
        </Button>
      </a>
    </div>
  );
}

export default function AppBarr(props) {
  const activePath = useSelector((state) => state.appbarReducer.activePath);
  const classes = useStyles();
  let history = useHistory();
  const open = useSelector((state) => state.appbarReducer.drawerModalOpen);

  useEffect(() => {
    handleActiveLinkChanged(history.location.pathname);
    const listener = history.listen((location, action) => {
      handleActiveLinkChanged(location.pathname);
    });
    return () => {
      listener();
    };
  }, []);

  const { window } = props;

  const dispatch = useDispatch();
  const handleDrawerOpen = () => dispatch(toggleDrawer(true));
  const handleDrawerClose = () => dispatch(toggleDrawer(false));
  const handleActiveLinkChanged = (path) => dispatch(activeLinkChanged(path));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function forceCloseOnRouteChange() {
    history.listen((location, action) => {
      if (action === "PUSH") {
        handleDrawerClose();
      }
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const MainLinks = renderMainLinks(
    props,
    classes,
    anchorEl,
    setAnchorEl,
    history
  );
  const drawer = renderDrawerLinks(props, classes, handleDrawerToggle);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className="nf-navbar header">
        <Toolbar className="nf-header">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {(activePath === "/patients" || activePath === "/practitioners") &&
            {
              /*<div>
                            <Typography variant="h6" noWrap className={activePath === '/patients' || activePath === '/' ? `${classes.active}` : null}>
                            <Link to="/patients">
                                Patients & Clients
                            </Link>

                        </Typography>
                        <Typography variant="h6" noWrap className={activePath === '/practitioners' ? `${classes.active}` : null}>
                            <Link className="ms-3" to="/practitioners">
                                Practitioners
                            </Link>
                        </Typography>
                    </div>*/
            }}
          <div></div>
          {MainLinks}
        </Toolbar>
        <div className="mobileDropDown">{mobileOpen && drawer}</div>
      </AppBar>
    </div>
  );
}
