import React from "react"
import { connect } from "react-redux"

import App from "./App.js"
import AppV2 from "./v2/App.js"

const AppVersionDispatcher = ({ features }) => {
  if (features && features.find((f) => f.is_active && f.name === "dashboard_v2")) {
    return <AppV2 />
  }

  return <App />
}

const mapStateToProps = (state) => {
  return {
    features: state.authReducer.features,
  }
}

export default connect(mapStateToProps)(AppVersionDispatcher)
