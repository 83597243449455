import React, { useEffect } from "react"

import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"

//components
import { Button, Toolbar, IconButton } from "@material-ui/core"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import ExpandMore from "@material-ui/icons/ExpandMore"
import MaterialLink from "@material-ui/core/Link"

import MenuIcon from "@material-ui/icons/Menu"

import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { useTranslation } from "../../../i18n/translate"
import { activeLinkChanged, setLanguage } from "../../../actions"
import PermissionsService from "../../../lib/PermissionsSystem.js"
import "./AppBar.scss"

const drawerWidth = 288

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  navLink: {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: "18px",
  },
  navLinkIcon: {
    marginRight: 0,
    marginLeft: "auto",
    paddingLeft: "1em",
  },
  drawerNavLink: {
    color: "#222",
    textDecoration: "none",
    borderBottom: 1,
    fontSize: "24px",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  logoutButton: {
    margin: 0,
    width: "100%",
  },
  feedbackButton: {
    width: "100%",
    height: "48px",
    textDecoration: "none",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "17.5%",
    },
  },
  menuButton: {
    marginRight: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& img": {
      width: "40px",
    },
  },
  active: {
    borderBottom: "2px solid #333437",
  },
}))

// MainLinks -- this renders the logout component
// checks if the user is authenticated

const renderMainLinks = (
  props,
  classes,
  anchorEl,
  setAnchorEl,
  history,
  t,
  lng,
  setLng
) => {
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // if the user is authenticated, logout link renders
  if (props.isAuthenticated) {
    let user = PermissionsService.GetUser()
    const userInitial =
      (user && user.first_name && user.first_name.slice(0, 1).toUpperCase()) ||
      "U"
    return (
      <div>
        <span className='circle-icon bar-item'>{userInitial}</span>
        <span className='bar-item'>{user ? `${user.first_name}` : ""}</span>
        <IconButton
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
          className='bar-item'>
          <ExpandMore />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
          open={open}
          onClose={handleClose}>
          <MenuItem>
            <MaterialLink
              href={
                process.env.REACT_APP_CONTACT_EMAIL || "mailto:info@neurofit.ca"
              }
              color='inherit'
              underline='none'>
              <span>{t("CONTACT_NEUROFIT")}</span>
            </MaterialLink>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              props.logoutUser(e)
              history.push("/")
            }}>
            <span>{t("SIGN_OUT")}</span>
          </MenuItem>
          <MenuItem>
            <MaterialLink
              onClick={(_) => setLng(lng === "en-US" ? "fr-CA" : "en-US")}
              color='inherit'
              underline='none'>
              <span>{t("TOGGLE LANGUAGE")}</span>
            </MaterialLink>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

function renderDrawerLinks(props, classes, handleDrawerToggle) {
  return (
    <div>
      <List>
        <Link
          to={"/"}
          className={classes.drawerNavLink}
          onClick={handleDrawerToggle}>
          <ListItem sx={{ borderBottom: 1 }}>Manage Users</ListItem>
        </Link>
        <Link
          to={"/schedule"}
          className={classes.drawerNavLink}
          onClick={handleDrawerToggle}>
          <ListItem>Session Schedule</ListItem>
        </Link>
        <Link
          to={"/billing"}
          className={classes.drawerNavLink}
          onClick={handleDrawerToggle}>
          <ListItem>Billing Info</ListItem>
        </Link>
        {props.privilege === 1 && (
          <React.Fragment>
            <Link
              to={"/organizations"}
              className={classes.drawerNavLink}
              onClick={handleDrawerToggle}>
              <ListItem>Manage Organizations</ListItem>
            </Link>
          </React.Fragment>
        )}
        {/* <LogoutButton logoutUser={props.logoutUser} className={classes.logoutButton} inDrawer={true} /> */}
      </List>
      <a
        href={"https://forms.gle/Xf88szo7w3MLeEj2A"}
        rel='noopener noreferrer'
        target='_blank'
        className={classes.feedbackButton}>
        <Button className={classes.feedbackButton} color='secondary'>
          Give Feedback
        </Button>
      </a>
    </div>
  )
}

export default function AppBarr(props) {
  const classes = useStyles()

  const dispatch = useDispatch()
  let history = useHistory()

  const t = useTranslation()

  useEffect(() => {
    const handleActiveLinkChanged = (path) => dispatch(activeLinkChanged(path))
    handleActiveLinkChanged(history.location.pathname)
    const listener = history.listen((location, action) => {
      handleActiveLinkChanged(location.pathname)
    })
    return () => {
      listener()
    }
  }, [history, dispatch])
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const lng = useSelector((state) => state.userReducer.language)
  const setLng = (language) => dispatch(setLanguage(language))

  const [anchorEl, setAnchorEl] = React.useState(null)
  const MainLinks = renderMainLinks(
    props,
    classes,
    anchorEl,
    setAnchorEl,
    history,
    t,
    lng,
    setLng
  )
  const drawer = renderDrawerLinks(props, classes, handleDrawerToggle)

  return (
    <div className={classes.root}>
      {MainLinks}
      <Toolbar className='nf-header' variant='dense'>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <div className='mobileDropDown'>{mobileOpen && drawer}</div>
    </div>
  )
}
