import React from "react";
import "./ImportInProgress.scss";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";

function ImportInProgress(props) {
  return (
    <div className="importInProgress-container">
      <main>
        <div className="title">Import in Progress</div>
        <div className="loading">
          <LoadingSpinner />
        </div>
        <div className="footer">
          {props.numberOfUsers} users are being uploaded
        </div>
      </main>
    </div>
  );
}

export default ImportInProgress;
