import { GET_USER_SKILL_LEVELS } from "../actions/types";

const INITIAL_STATE = {
  hasFetched: false,
  skillLevels: [],
  startDateSelect: new Date().toISOString(),
  endDateSelect: new Date().toISOString(),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_SKILL_LEVELS:
      return {
        ...state,
        skillLevel: action.payload,
        startDateSelect: action.date,
        endDateSelect: action.date,
        hasFetched: true,
      };
    default:
      return state;
  }
};
