import { SIGN_IN, SIGN_OUT, GET_PRIVILEGE } from "../actions/types";
import { Debug, GetLocalStorageValueSafe } from "../lib";
import PermissionsService from "../lib/PermissionsSystem";

const INITIAL_STATE = {
  isAuthenticated: GetLocalStorageValueSafe("auth_token") !== null,
  privilege: parseInt(GetLocalStorageValueSafe("privilege") || "0", 10),
  permissions: JSON.parse(GetLocalStorageValueSafe("permissions") || "[]"),
  user: JSON.parse(GetLocalStorageValueSafe("user_data") || "{}"),
  org: JSON.parse(GetLocalStorageValueSafe("org_data") || "{}"),
  features: JSON.parse(GetLocalStorageValueSafe("features") || "[]"),
};
Debug.log("Initial Auth State: " + JSON.stringify(INITIAL_STATE));
Debug.log("Initial Auth Token: " + localStorage.getItem("auth_token"));
Debug.log("Initial Refresh Token: " + localStorage.getItem("refresh_token"));

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: GetLocalStorageValueSafe("auth_token") !== null,
        privilege: parseInt(GetLocalStorageValueSafe("privilege") || "0", 10),
        permissions: action.payload.permissions,
        response: action.payload,
        user: action.payload.user,
        org: action.payload.organization,
        features: action.payload.data.features,
      };
    case SIGN_OUT:
      Debug.log("Logging out");
      PermissionsService.Reset();
      return {
        ...state,
        isAuthenticated: false,
        privilege: 0,
        permissions: [],
        response: action.payload,
      };
    case GET_PRIVILEGE:
      return {
        ...state,
        privilege: parseInt(localStorage.getItem("privilege"), 10),
      };
    default:
      return state;
  }
};

export default reducer;
