import Paper from "@material-ui/core/Paper";
import {
  Checkbox,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  InputLabel,
  Input
} from "@mui/material";
import MaterialTable, { MTableBodyRow, MTableToolbar } from "material-table";
import React from "react";

const CustomMaterialTable = ({
  title,
  columns,
  customMenuItem,
  actions,
  className,
  customClassNameSelect,
  viewOptionClass,
  handleSelectChange,
  data,
  selectOptions,
  onRowClick,
  onRowDoubleClick,
  UserTypes,
  UserStatus,
  onChangeSelectedRowData,
}) => {
  return (
    <div className="custom-table">
      <MaterialTable
        title={title}
        columns={columns}
        actions={actions}
        className={className}
        component={Paper}
        data={data}
        style={{ marginBottom: "2rem" }}
        components={{
          Row: (props) => (
            <MTableBodyRow
              {...props}
              onRowClick={(e) => onRowClick(e, props)}
              onDoubleClick={(e) => onRowDoubleClick(e, props)}
            />
          ),
          Toolbar: (props) => (
            <div
              className="d-flex w-auto align-items-center justify-content-start filter-area"
              style={{ backgroundColor: "#E8F5FB" }}
            >
              <FormControl
                {...props}
                sx={{
                  margin: "0px 10px 0px 0px",
                  width: "151px",
                  height: "40px",
                }}
              >
                <InputLabel htmlFor="name-multiple">Filter</InputLabel>
                <Select
                  multiple={true}
                  id={"filter-table"}
                  value={selectOptions}
                  onChange={handleSelectChange}
                  placeholder={"View Option"}
                  renderValue={(selected) => {
                    return (
                      <p className={viewOptionClass}>
                        {"View Option " + selected.length}
                      </p>
                    );
                  }}
                  style={{ width: "151", height: "40px", marginTop: "10px" }}
                  className={customClassNameSelect}
                  inputProps={{ "aria-label": "Without label" }}
                  input={<Input id="name-multiple" />}
                  MenuProps={customMenuItem}
                >
                  <ListSubheader
                    style={{
                      color: "#8FCAED",
                      fontSize: "14px",
                      fontFamily: "Montserrat-SemiBold",
                    }}
                  >
                    Account Type
                  </ListSubheader>
                  {UserTypes &&
                    UserTypes.map((type) => (
                      <MenuItem
                        key={type.label}
                        value={type.value}
                        style={{
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                          color: "#333437",
                        }}
                      >
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#8FCAED",
                            },
                            color: "#8FCAED",
                            "&.MuiSvgIcon-root": {
                              borderRadius: "4px",
                            },
                          }}
                          checked={selectOptions.indexOf(type.value) > -1}
                        />
                        {type.label}
                      </MenuItem>
                    ))}
                  <ListSubheader
                    style={{
                      color: "#8FCAED",
                      fontSize: "14px",
                      fontFamily: "Montserrat-SemiBold",
                    }}
                  >
                    Account Status
                  </ListSubheader>
                  {UserStatus &&
                    UserStatus.map((status) => {
                      return (
                        <MenuItem
                          key={status.label}
                          value={status.value}
                          style={{
                            fontSize: "12px",
                            fontFamily: "Montserrat",
                            color: "#333437",
                          }}
                        >
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#8FCAED",
                              },
                              color: "#8FCAED",
                              "&.MuiSvgIcon-root": {
                                borderRadius: "4px",
                              },
                            }}
                            checked={selectOptions.indexOf(status.value) > -1}
                          />
                          {status.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "Showing {from}-{to} of {count} items",
          },
          toolbar: {
            searchTooltip: "Quick Filter",
            searchPlaceholder: "Search",
            nRowsSelected: "",
          },
        }}
        options={{
          search: true,
          selection: false,
          exportButton: false,
          headerStyle: {
            textAlign: "left",
            minWidth: "100px",
            position: "sticky",
            top: 0,
            backgroundColor: "#D0EEFB",
            color: "#555",
            fontFamily: "OpenSans-SemiBold",
            lineHeight: "16px",
          },
          rowStyle: (rowData) => ({
            transition: "ease all .5s",
            backgroundColor:
              // (selectedRow === rowData.id) ?
              // '#EEE' :
              // (rowData.status === "ARCHIVED") ?
              //   '#DDD':
              "#FFF",
            padding: "15px",
            fontFamily: "OpenSans-Regular",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
          }),
          paging: true,
          pageSize: 10,
          paginationType: "stepped",
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          pageSizeOptions: [], // [10, 25, 50, 100]
          searchFieldStyle: {
            borderRadius: "5px",
            width: "355px",
            height: "40px",
            backgroundColor: "#FFFFFF",
          },
          tableLayout: "auto"
        }}
        onSelectionChange={(rows) => onChangeSelectedRowData(rows)}
      />
    </div>
  );
};

export default CustomMaterialTable;
