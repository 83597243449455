import React from "react";
import { connect } from "react-redux";
import "./Navbar.scss";
import { Link } from "react-router-dom";

import Logo from "../../../assets/images/neurofit-logo.svg";
import PermissionsService from "../../../lib/PermissionsSystem";

const Navbar = ({ onSignOutClick , privilege }) => {
  // my parent container is a display flex
  const showSchedule = false;
  return (
    <div className="navbar-container">
      <div className="w-75 p-2 mt-2">
        <Link to={"/"}>
          <img src={Logo} />
        </Link>
      </div>

      <div className="navbar-links-container">
        <Link style={{ textDecoration: "none" }} to={"/"}>
          <h2>Manage Users</h2>
        </Link>
        { showSchedule && <Link style={{ textDecoration: "none" }} to={"/schedule"}>
          <h2>Session Schedule</h2>
        </Link>}
        {/* <Link style={{ textDecoration: "none" }} to={"/schedule"}>
          <h2>Session Schedule</h2>
        </Link> */}
        <Link style={{ textDecoration: "none" }} to={"/organizations"}>
          <h2>Manage Organizations</h2>
        </Link>
        { PermissionsService.HasPermission("admin.billing.read") && 
          <Link style={{ textDecoration: "none" }} to={"/billing"}>
              <h2>Billing Details</h2>
          </Link>
        }
        <h2 style={{ cursor: "pointer" }} onClick={onSignOutClick}>
          Sign Out
        </h2>
      </div>
    </div>
  );
};

export default Navbar;
