import React, { Component } from "react";

import OrganizationsList from "./OrganizationsList";
import AddOrganizationForm from "./AddOrganizationForm";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";


import Grid from "@material-ui/core/Grid";
import {
  getOrganizations,
  setPrivilege,
  registerOrganization,
  showModal,
  hideModal,
} from "../../actions";
import { FormControlLabel, FormGroup, Typography } from "@material-ui/core";

import ActiveUsersChart from './ActiveUsersChart';
import moment from 'moment'


const styles = (theme) => ({
  select: {
    width: "50%",
    height: "40px",
  },
  title: {
    fontSize: "30px",
    lineHeight: "37px",
    fontFamily: "MontserratBold",
    color: "#333437",
    fontWeight: "700",
    padding: "6px 0.8em",
  },
  selectedItem: {
    backgroundColor: theme.palette.info.light,
  },
  selectContainer: {
    padding: "6px 1.5em",
    width: "100%",
  },
  gridContainer: {
    marginTop: "20px",
    width: "70%",
    marginLeft: "-18px",
    "& .MuiFab-label": {
      fontFamily: "MontserratBold",
      color: "#333437",
      fontWeight: "700",
      lineHeight: "37px",
    },
  },
  userTypesLabel: {
    display: "inline-block !important",
    marginRight: "10px",
  },
  marginTop : {
    marginTop :'20px'
  },
  marginBottom : {
    marginBottom : '70px'
  }
});

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide_inactive: false,
      start_date: moment().subtract(1, "month").toISOString(),
      end_date: moment().toISOString(),
    };
    this.handleInactiveToggleChange =
      this.handleInactiveToggleChange.bind(this);
  }

  handleInactiveToggleChange(event) {
    this.setState({ ...this.state, hide_inactive: event.target.checked });
    this.forceUpdate();
  }

  loadingOrganizations = () => {
    if (!this.props.hasFetched) {
      this.props.getOrganizations();
      return <LoadingSpinner />;
    } else {
      return (
        <OrganizationsList
          hide_inactive={this.state.hide_inactive}
          setPrivilege={this.props.setPrivilege}
          organizations={this.props.organizations}
        ></OrganizationsList>
      );
    }
  };

  render() {
    const { classes , organizations  } = this.props;
    return (
      <>
        <Grid container className={classes.gridContainer} direction={"column"}>
          <h2 className={classes.title}>Manage Organizations</h2>
          {organizations?.privilege !== 0 &&  this.props.hasFetched &&
          <Grid item style={{ width: "300px" }}>
            <AddOrganizationForm
                registerOrganization={this.props.registerOrganization}
                showModal={this.props.showModal}
                hideModal={this.props.hideModal}
            />
          </Grid>
        }
        </Grid>
        <div className={classes.marginTop}>
        {organizations?.privilege !== 0 && this.props.hasFetched &&  <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.hide_inactive}
                onChange={(event) => this.handleInactiveToggleChange(event)}
                color="primary"
              />
            }
            name="hide_inactive"
            label="Hide Inactive Organizations"
          />
        </FormGroup>}
        {this.loadingOrganizations()}
        {organizations?.privilege !== 0 &&
          <div className={classes.marginBottom}>
            <h3 className={classes.title}>Active Users</h3>
            <ActiveUsersChart
              start_date={this.state.start_date}
              end_date={this.state.end_date}
            />
          </div>
          }
        </div>
        
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organizationReducer.organizations,
    hasFetched: state.organizationReducer.hasFetched,
    modal: state.modalReducer,
  };
};

export default connect(mapStateToProps, {
  getOrganizations,
  setPrivilege,
  registerOrganization,
  showModal,
  hideModal,
})(withStyles(styles)(Organizations));
