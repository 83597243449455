import moment from "moment";

export const createDateString = (days) => {
  let date = moment();
  date = date.subtract(days, "days");
  return date.format("YYYY-MM-DD");
};

/**
 * Gets the value from local storage, or null if it is undefined or null
 * @param {string} keyName The name of the key to get from local storage
 */
export function GetLocalStorageValueSafe(keyName) {
  let result = localStorage.getItem(keyName);
  if (
    result === undefined ||
    result === null ||
    result === "undefined" ||
    result === "null"
  ) {
    result = null;
  }
  return result;
}

export class Debug {
  static log(value) {
    if (process.env.NODE_ENV === "development") {
      console.log(value);
    }
  }

  static error(value) {
    console.error(value);
  }
}

export function mapObjectPath(obj, path) {
  var paths = path.split(".");
  var current = obj;
  for (var i = 0; i < paths.length; i++) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}

/**
 * Returns a random number withing the given range
 * @param {number} min The minimum value of the range (inclusive)
 * @param {number} max The maximum value of the range (exclusive)
 */
export function random_range(min, max) {
  return min + Math.random() * (max - min);
}

/**
 * Clamps a value between an upper and lower bounds
 * @param {number} value The value to clamp
 * @param {number} min The minimum value of the range
 * @param {number} max The maximum value of the range
 */
export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export function trimRight(string, character) {
  if (character === "]") character = "\\]";
  if (character === "^") character = "\\^";
  if (character === "\\") character = "\\\\";
  return string.replace(new RegExp("[" + character + "]+$", "g"), "");
}

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lower cased
 * @return {string}
 * @see https://stackoverflow.com/questions/2332811/capitalize-words-in-string
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
