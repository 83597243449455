import React from "react";
import AdminActions from "../../actions/AdminActions";
import { Debug } from "../../lib";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";

export default class OrgChartBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isError: false,
      chartData: undefined,
      chartOptions: undefined,
      available_levels : {}
    };
    this.parseData = this.parseData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.dispatchLoad = this.dispatchLoad.bind(this);
    this.dispatchAPI = this.dispatchAPI.bind(this);
    this.shouldLoad = this.shouldLoad.bind(this);
  }

  /**
   * Invoked immediately after a component is mounted (inserted into the tree).
   * This will handle dispatching our initial request for the user's session data
   *
   * @see https://reactjs.org/docs/react-component.html#componentdidmount
   */
  componentDidMount() {
    this.dispatchLoad();
    (async () => {
      let available_levels  = await AdminActions.GetAvailableLevelVersions({
        org_id: undefined,
        start_date : this.props.start_date ,
        end_date : this.props.end_date
      })(() => {});
      this.setState({
        ...this.state ,
        available_levels : available_levels
      })
    })();
  }

  /**
   * Invoked immediately after updating occurs. This method is not called for the initial render.
   * If the properties have changed, we will dispatch another load request
   *
   * @param {*} prevProps The previous properties for the component
   * @param {*} prevState The previous state of the component
   * @see https://reactjs.org/docs/react-component.html#componentdidupdate
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      this.shouldLoad(prevProps, prevState) ||
      prevProps.org_id !== this.props.org_id
    ) {
      this.dispatchLoad();
      (async () => {
        let available_levels  = await AdminActions.GetAvailableLevelVersions({
          org_id: undefined,
          start_date : this.props.start_date ,
          end_date : this.props.end_date
        })(() => {});
        this.setState({
          ...this.state ,
          available_levels : available_levels
        })
      })();
    }
  }

  /**
   * Provides child classes an injection point to invoke loads on state changes
   * @param {*} prevProps
   * @param {*} prevState
   */
  shouldLoad(prevProps, prevState) {
    return false;
  }

  /**
   * Dispatches the API request if the state is valid, and handles the result
   */
  dispatchLoad() {
    if (!this.state.isFetching) {
      this.setState({ ...this.state, isFetching: true, isError: false });
      // Dispatch the fetch request with our data
      this.fetchData().then(
        (result) => {
          // Store the parsed result into our state so that react will re-render
          this.setState({
            ...this.state,
            chartData: result.chartData,
            chartOptions: result.chartOptions,
            isFetching: false,
          });
          this.forceUpdate();
        },
        (error) => {
          Debug.error(error);
          this.setState({
            ...this.state,
            chartData: undefined,
            chartOptions: undefined,
            isFetching: false,
            isError: true,
          });
        }
      );
    } else {
      this.setState({
        ...this.state,
        chartData: undefined,
        chartOptions: undefined,
        isFetching: false,
        isError: true,
      });
    }
  }

  /**
   * Asyncronously gets the skill graphing data and parses it into the ChartJS data
   * @returns {Promise<Object, Object>} A promise that returns a chartData and chartOptions pair
   */
  async fetchData() {
    const org_id = this.props.org_id;
    const start_date = this.props.start_date;
    const end_date = this.props.end_date;

    // Fetch data (async), using an empty dispatcher
    let response = await this.dispatchAPI()((x) => {});
    // Parse the results and return the graphing information
    return this.parseData(response);
  }

  dispatchAPI() {
    throw new Error("dispatchAPI must be overridden in child classes!");
  }

  /**
   * Handles parsing the result of the API request and generating a ChartJS chart
   * @param {Object} payload
   */
  parseData(payload) {
    throw new Error("parseData must be overridden by child classes!");
  }

  renderChart() {
    throw new Error("renderChart must be overridden by child classes!");
  }

  render() {
    return this.state.isFetching ? (
      <LoadingSpinner />
    ) : this.state.chartData !== undefined ? (
      this.renderChart()
    ) : (
      <div>Data not loaded</div>
    );
  }
}
