import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";

import {
  Divider,
  IconButton,
  Badge,
  Grid,
  Paper,
  TextField,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import DateRangePickers from "../../sharedComponents/DateRangePickers";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: "2.5rem 3.5rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      padding: "0",
    },
  },
  inlineInput: {
    width: "100%",
  },
  blueColor: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.primary.contrastText,
  },
  sessionTitle: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function NewSessionDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  /* TEMPORARY : To show the forms submitted in console */
  const [name, setName] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [note, setNote] = React.useState("");

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleSubmit = async (data) => {
    setName(data.nameInput);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setNote(data.notes);

    console.log(name + " " + startDate + " " + endDate + " " + note);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.sessionTitle} id="simple-dialog-title">
        Create New Session
      </DialogTitle>

      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {/* Session Name */}
          <Grid item sm={11}>
            <FormControl fullWidth className={classes.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                Session Name
              </InputLabel>
              <Input id="nameInput" name="nameInput" />
            </FormControl>
          </Grid>

          {/* Date Range */}
          <Grid item>
            <DateRangePickers />
          </Grid>

          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Notes"
              multiline
              rows={4}
              defaultValue="Default Value"
              variant="outlined"
              name="notes"
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Create New Session
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

NewSessionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function NewSession() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.blueColor}
        onClick={handleClickOpen}
      >
        New Session
      </Button>

      <NewSessionDialog open={open} onClose={handleClose} />
    </div>
  );
}
