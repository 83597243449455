import {
  min,
  max,
  scaleLinear,
  scaleTime,
  select,
  axisBottom,
  axisLeft,
  selectAll,
  line,
  entries,
  area,
} from "d3";

const MARGIN = { TOP: 50, BOTTOM: 80, LEFT: 70, RIGHT: 180 };
const WIDTH = 800 - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 600 - MARGIN.TOP - MARGIN.BOTTOM;

class GeneralMetricsChart {
  constructor(
    element,
    data,
    attentionLineToggle,
    processingLineToggle,
    memoryLineToggle
  ) {
    let vis = this;

    vis.g = select(element)
      .append("svg")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

    //Set our range scales to the height and width of our visualization
    vis.x = scaleTime().range([0, WIDTH]);

    vis.y = scaleLinear().range([HEIGHT, 0]);

    //Create axis groups for x y on main vis instance
    vis.xAxisGroup = vis.g
      .append("g")
      .attr("transform", `translate(0, ${HEIGHT})`);

    vis.yAxisGroup = vis.g.append("g");

    //X axis label
    vis.g
      .append("text")
      .attr("x", WIDTH / 2)
      .attr("y", HEIGHT + 40)
      .attr("font-size", 20)
      .attr("text-anchor", "middle")
      .text("Time of Session");
    //Y Axis Label
    vis.g
      .append("text")
      .attr("x", -(HEIGHT / 2))
      .attr("y", -50)
      .attr("transform", "rotate(-90)")
      .attr("font-size", 20)
      .attr("text-anchor", "middle")
      .text("Accuracy %");

    vis.update(
      data,
      attentionLineToggle,
      processingLineToggle,
      memoryLineToggle
    );
  }

  update(data, attentionLineToggle, processingLineToggle, memoryLineToggle) {
    let vis = this;
    let raw_response = entries(data);
    let processingLineData = [];
    let memoryLineData = [];
    let attentionLineData = [];
    let allLines = [];

    for (let i = 0; i < raw_response.length; i++) {
      const lineData = entries(raw_response[i].value.daily_aggregates);
      allLines.push(lineData);
      switch (raw_response[i].value.categories) {
        case "memory":
          memoryLineData.push(lineData);
          break;
        case "processing":
          processingLineData.push(lineData);
          break;
        case "attention":
          attentionLineData.push(lineData);
          break;
        default:
          break;
      }
    }

    function calculateAveragePercentage(data) {
      return (
        (parseFloat(data.correct) * 100) /
        (parseFloat(data.correct) + parseFloat(data.incorrect))
      );
    }

    function sortedMetricData(lineData) {
      let line = [];

      lineData[0].map((item, i) => {
        //find matching item in array by key, and filter out item from array.
        const found_match = lineData[1].find(
          (element) => element.key === item.key
        );

        //found a matching key, (need to comibine data when it's accurate)
        if (found_match) {
          //update our second array and filter out the object we just added to the matching day.
          lineData[1] = lineData[1].filter(
            (element) => element.key !== item.key
          );
        }
        //push point data
        return line.push(lineData[0][i]);
      });
      //combine what's left of our second array with our line array
      return line.concat(lineData[1]);
    }

    if (memoryLineData[0]) {
      //sort our data
      memoryLineData = sortedMetricData(memoryLineData);
      processingLineData = sortedMetricData(processingLineData);
      attentionLineData = sortedMetricData(attentionLineData);
      allLines = allLines.flat(1);

      //add data to visualization oject
      vis.data = {};
      vis.data.memoryLine = memoryLineData.sort(
        (a, b) => new Date(a.key) - new Date(b.key)
      );
      vis.data.processingLine = processingLineData.sort(
        (a, b) => new Date(a.key) - new Date(b.key)
      );
      vis.data.attentionLine = attentionLineData.sort(
        (a, b) => new Date(a.key) - new Date(b.key)
      );
      vis.data.allLines = allLines.sort(
        (a, b) => new Date(a.key) - new Date(b.key)
      );

      //Set our domains for each axis
      //Take our domain and parse string back into a date
      if (vis.data.allLines.length > 0) {
        vis.x.domain([
          new Date(vis.data.allLines[0].key),
          new Date(vis.data.allLines[vis.data.allLines.length - 1].key),
        ]);
        //Calculate the total amont of correct vs incorrect values get the percentage of correct to total for each entry in our general dataset
        vis.y.domain([
          min(
            vis.data.allLines,
            (d) =>
              (d.value.max.correct * 100) /
              (d.value.max.correct + d.value.max.incorrect)
          ),
          max(
            vis.data.allLines,
            (d) =>
              (d.value.min.correct * 100) /
              (d.value.min.correct + d.value.min.incorrect)
          ),
        ]);

        //create axis calls
        const xAxisCall = axisBottom(vis.x);
        const yAxisCall = axisLeft(vis.y);

        //render axis with a transition of 1s
        vis.xAxisGroup.transition(1000).call(xAxisCall);
        vis.yAxisGroup.transition(1000).call(yAxisCall);
      }

      const makeLine = line()
        .x(function (d) {
          return vis.x(new Date(d.key));
        })
        .y(function (d) {
          return vis.y(
            (Number(d.value.avg.correct) * 100) /
              (Number(d.value.avg.correct) + Number(d.value.avg.incorrect))
          );
        });

      const makeErrorBar = area()
        .x(function (d) {
          return vis.x(new Date(d.key));
        })
        .y0(function (d) {
          return vis.y(
            (d.value.min.correct * 100) /
              (d.value.min.correct + d.value.min.incorrect)
          );
        })
        .y1(function (d) {
          return vis.y(
            (d.value.max.correct * 100) /
              (d.value.max.correct + d.value.max.incorrect)
          );
        });

      const circleStyles = {
        circleRadius: 5,
        circleRadiusHover: 7,
      };

      // //JOIN MEMORY
      const memoryLine = vis.g
        .selectAll("memory-line-group")
        .data([vis.data.memoryLine], (d) => d);

      const memoryErrorBars = vis.g
        .selectAll("memory-line-group")
        .data(vis.data.memoryLine, (d) => d);

      const memoryLineCircles = vis.g
        .selectAll("memory-circle")
        .data(vis.data.memoryLine, (d) => d);

      // //EXIT MEMORY
      memoryLine.exit();
      selectAll(".memory-line-group").remove();

      memoryLineCircles.exit();
      selectAll(".memory-circle").remove();
      //UPDATE

      if (memoryLineToggle) {
        //ENTER MEMORY
        memoryLine
          .enter()
          .append("g")
          .attr("class", "memory-line-group")
          .on("mouseover", function (d) {
            select(this)
              .append("text")
              .attr("class", "line-text")
              .text(`Memory`)
              .attr("x", WIDTH / 2 - 35)
              .attr("y", -10);
          })
          .on("mouseout", function (d) {
            selectAll(".line-text").remove();
          })
          .append("path")
          .attr("class", "memory-line")
          .transition(1000)
          .attr("id", (d) => `line-${d.id}`)
          .attr("d", (d) => makeLine(d));

        memoryErrorBars
          .enter()
          .append("g")
          .attr("class", "memory-line-group")
          .append("path")
          .attr("class", "memory-line-errorbar")
          .transition(1000)
          .attr("id", (d) => `line-${d.id}`)
          .attr("d", (d) => makeErrorBar([d]));

        memoryLineCircles
          .enter()
          .append("g")
          .append("g")
          .attr("class", "memory-circle")
          .on("mouseover", function (d) {
            select(this)
              .style("cursor", "pointer")
              .append("text")
              .attr("class", "circle-text")
              .text(`${calculateAveragePercentage(d.value.avg).toFixed(2)}%`)
              .attr("x", (d) => vis.x(new Date(d.key)) - 30)
              .attr(
                "y",
                (d) => vis.y(calculateAveragePercentage(d.value.avg)) - 35
              );
            select(this)
              .append("text")
              .attr("class", "circle-text")
              .attr("x", (d) => vis.x(new Date(d.key)) - 30)
              .text(`At  ${new Date(d.key).toLocaleDateString()} for Memory`)
              .attr(
                "y",
                (d) => vis.y(calculateAveragePercentage(d.value.avg)) - 20
              );
          })
          .on("mouseout", function (d) {
            select(this)
              .style("cursor", "none")
              .selectAll(".circle-text")
              .transition(500)
              .remove();
          })
          .append("circle")
          .attr("cx", (d) => vis.x(new Date(d.key)))
          .attr("cy", (d) => vis.y(calculateAveragePercentage(d.value.avg)))
          .attr("r", circleStyles.circleRadius)
          .style("opacity", 1)
          .on("mouseover", function (d) {
            select(this)
              .transition(500)
              .attr("r", circleStyles.circleRadiusHover);
          })
          .on("mouseout", function (d) {
            select(this).transition(500).attr("r", circleStyles.circleRadius);
          });
      }

      //JOIN ATTENTION\
      const attentionLine = vis.g
        .selectAll("attention-line-group")
        .data([vis.data.attentionLine], (d) => d);

      const attentionCircles = vis.g
        .selectAll("attention-circle")
        .data(vis.data.attentionLine, (d) => d.id);

      const attentionBars = vis.g
        .selectAll("attention-line-group")
        .data(vis.data.attentionLine, (d) => d);

      // //EXIT ATTENTION
      attentionLine.exit();
      selectAll(".attention-line-group").remove();

      attentionCircles.exit();
      selectAll(".attention-circle").remove();

      // //UPDATE
      if (attentionLineToggle) {
        //ENTER ATTENTION
        attentionLine
          .enter()
          .append("g")
          .attr("class", "attention-line-group")
          .on("mouseover", function (d) {
            select(this)
              .append("text")
              .attr("class", "line-text")
              .text(`Attention`)
              .attr("x", WIDTH / 2 - 35)
              .attr("y", -10);
          })
          .on("mouseout", function (d) {
            selectAll(".line-text").remove();
          })
          .append("path")
          .attr("class", "attention-line")
          .transition(1000)
          .attr("id", (d) => `line-${d.id}`)
          .attr("d", (d) => makeLine(d));

        attentionCircles
          .enter()
          .append("g")
          .append("g")
          .attr("class", "attention-circle")
          .on("mouseover", function (d) {
            select(this)
              .style("cursor", "pointer")
              .append("text")
              .attr("class", "circle-text")
              .text(`${calculateAveragePercentage(d.value.avg).toFixed(2)}%`)
              .attr("x", (d) => vis.x(new Date(d.key)) - 30)
              .attr(
                "y",
                (d) => vis.y(calculateAveragePercentage(d.value.avg)) - 35
              );
            select(this)
              .append("text")
              .attr("class", "circle-text")
              .attr("x", (d) => vis.x(new Date(d.key)) - 30)
              .text(`At  ${new Date(d.key).toLocaleDateString()} for Attention`)
              .attr(
                "y",
                (d) => vis.y(calculateAveragePercentage(d.value.avg)) - 20
              );
          })
          .on("mouseout", function (d) {
            select(this)
              .style("cursor", "none")
              .selectAll(".circle-text")
              .transition(500)
              .remove();
          })
          .append("circle")
          .attr("cx", (d) => vis.x(new Date(d.key)))
          .attr("cy", (d) => vis.y(calculateAveragePercentage(d.value.avg)))
          .attr("r", circleStyles.circleRadius)
          .style("opacity", 1)
          .on("mouseover", function (d) {
            select(this)
              .transition(500)
              .attr("r", circleStyles.circleRadiusHover);
          })
          .on("mouseout", function (d) {
            select(this).transition(500).attr("r", circleStyles.circleRadius);
          });

        attentionBars
          .enter()
          .append("g")
          .attr("class", "attention-line-group")
          .append("path")
          .attr("class", "attention-line-errorbar")
          .transition(1000)
          .attr("id", (d) => `line-${d.id}`)
          .attr("d", (d) => makeErrorBar([d]));
      }

      //JOIN PROCESSING
      const processingLine = vis.g
        .selectAll("processing-line-group")
        .data([vis.data.processingLine], (d) => d.id);

      const processingCircles = vis.g
        .selectAll("processing-circle")
        .data(vis.data.processingLine, (d) => d.id);

      const processingBars = vis.g
        .selectAll("processing-line-group")
        .data(vis.data.processingLine, (d) => d);

      //EXIT PROCESSING
      processingLine.exit();
      selectAll(".processing-line-group").remove();

      processingCircles.exit();
      selectAll(".processing-circle").remove();

      //UPDATE
      if (processingLineToggle) {
        //ENTER PROCESSING
        processingLine
          .enter()
          .append("g")
          .attr("class", "processing-line-group")
          .on("mouseover", function (d) {
            select(this)
              .append("text")
              .attr("class", "line-text")
              .text(`Processing`)
              .attr("x", WIDTH / 2 - 35)
              .attr("y", -10);
          })
          .on("mouseout", function (d) {
            selectAll(".line-text").remove();
          })

          .append("path")
          .attr("class", "processing-line")
          .attr("id", (d) => `line-${d.id}`)
          .attr("d", (d) => makeLine(d));

        processingCircles
          .enter()
          .append("g")
          .append("g")
          .attr("class", "processing-circle")
          .on("mouseover", function (d) {
            select(this)
              .style("cursor", "pointer")
              .append("text")
              .attr("class", "circle-text")
              .text(`${calculateAveragePercentage(d.value.avg).toFixed(2)}%`)
              .attr("x", (d) => vis.x(new Date(d.key)) - 30)
              .attr(
                "y",
                (d) => vis.y(calculateAveragePercentage(d.value.avg)) - 35
              );
            select(this)
              .append("text")
              .attr("class", "circle-text")
              .attr("x", (d) => vis.x(new Date(d.key)) - 30)
              .text(
                `At  ${new Date(d.key).toLocaleDateString()} for Processing`
              )
              .attr(
                "y",
                (d) => vis.y(calculateAveragePercentage(d.value.avg)) - 20
              );
          })
          .on("mouseout", function (d) {
            select(this)
              .style("cursor", "none")
              .selectAll(".circle-text")
              .transition(500)
              .remove();
          })
          .append("circle")
          .attr("cx", (d) => vis.x(new Date(d.key)))
          .attr("cy", (d) => vis.y(calculateAveragePercentage(d.value.avg)))
          .attr("r", circleStyles.circleRadius)
          .style("opacity", 1)
          .on("mouseover", function (d) {
            select(this)
              .transition(500)
              .attr("r", circleStyles.circleRadiusHover);
          })
          .on("mouseout", function (d) {
            select(this).transition(500).attr("r", circleStyles.circleRadius);
          });

        processingBars
          .enter()
          .append("g")
          .attr("class", "processing-line-group")
          .append("path")
          .attr("class", "processing-line-errorbar")
          .attr("id", (d) => `line-${d.id}`)
          .attr("d", (d) => makeErrorBar([d]));
      }
    }
  }
}

export default GeneralMetricsChart;
