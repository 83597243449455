export default function privilegeText(privilege) {
  switch (privilege) {
    case -1:
      return "Inactive"
    case 0:
      return "Active"

    case 1:
      return "Admin"

    default:
      return "Error"
  }
}
