import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import NFButton from "../sharedComponents/NFButton"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  titleRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

const TitleRow = ({ title, backDestination }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid
      item
      container
      direction='row'
      alignItems='center'
      spacing={2}
      className={classes.titleRow}>
      <Grid item>
        <NFButton
          type='button'
          variant='secondary'
          onClick={() =>
            backDestination ? history.push(backDestination) : history.go(-1)
          }>
          <ArrowBackIosIcon />
          Back
        </NFButton>
      </Grid>
      <Grid item>
        <Typography variant='h2' style={{ fontSize: "2rem", fontWeight: 500 }}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TitleRow
