import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateSelectedOrganization,
  getSessionsByOrganization,
} from "../../actions";
import DateRangePickers from "../sharedComponents/DateRangePickers";

import moment from "moment";
import { compose } from "redux";

const styles = (theme) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(2),
    },
  };
};

class BillingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      orgId: "",
    };

    this.handleDatesChanged = this.handleDatesChanged.bind(this);
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
    this.renderSelectItems = this.renderSelectItems.bind(this);
  }

  handleDatesChanged(startDate, endDate) {
    this.setState({
      startDate: startDate.local(),
      endDate: endDate.local(),
    });
    if (this.state.orgId != null) {
      this.props.dispatch(
        getSessionsByOrganization(
          this.state.orgId,
          startDate.toISOString(),
          endDate.toISOString()
        )
      );
    }
  }

  async handleOrganizationChange(event) {
    let newState = this.state;
    newState.orgId = event.target.value;
    this.setState(newState);
    this.props.dispatch(
      getSessionsByOrganization(
        newState.orgId,
        this.state.startDate.toISOString(),
        this.state.endDate.toISOString()
      )
    );
  }

  renderSelectItems() {
    return this.props.organizations.map((organization) => {
      return (
        <MenuItem key={organization.id} value={organization.id}>
          {organization.name}
        </MenuItem>
      );
    });
  }

  render() {
    const selectItems = this.renderSelectItems();
    const classes = this.props.classes;

    return (
      <Paper className={classes.paper} elevation={3}>
        <FormControl>
          <InputLabel id="organizationLabel">Organization</InputLabel>
          <Select
            labelId="organizationLabel"
            id="organization"
            value={this.state.orgId}
            onChange={this.handleOrganizationChange}
          >
            {selectItems}
          </Select>
        </FormControl>
        <DateRangePickers onValueChanged={this.handleDatesChanged} />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    startDate: state.generalGraphReducer.startDateSelect,
    endDate: state.generalGraphReducer.endDateSelect,
    organizations: state.organizationReducer.organizations,
  };
};
export default compose(
  withStyles(styles, { name: "BillingForm" }),
  connect(mapStateToProps, null)
)(BillingForm);

/*
export default function BillingForm(props) {
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const organizationEmail = useSelector(state => state.organizationReducer.selectedOrganization);
    const selectedStartDate = useSelector(state => state.generalGraphReducer.startDateSelect);
    const selectedEndDate = useSelector(state => state.generalGraphReducer.endDateSelect);    
    const handleOrganizationChange = async(e) =>  {
        await dispatch(updateSelectedOrganization(e.target.value));
        dispatch(getSessionsByOrganization(organizationEmail, moment(selectedStartDate).toISOString(), moment(selectedEndDate).toISOString()))
    }
    const selectItems = renderSelectItems(props);


    return (
        <Paper className={classes.paper} elevation={3}>
            <FormControl>
                <InputLabel id="organizationLabel">Organization</InputLabel>
                <Select
                    labelId="organizationLabel"
                    id="organization"
                    value={organizationEmail}
                    onChange={handleOrganizationChange}
                >
                {selectItems}
                </Select>
            </FormControl>
            <DateRangePickers onValueChanged={(start, end) => {
                if (organizationEmail !== '') {
                    dispatch(getSessionsByOrganization(organizationEmail, moment(start).toISOString(), moment(end).toISOString()))
                }
            }}/>
        </Paper>
    )
};
*/
