import React, { Component } from "react";

import BillingForm from "./BillingForm";
import BillingSessions from "./BillingSessions";
import BillingTotalTime from "./BillingTotalTime";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import { getOrganizations } from "../../actions";

class BillingOrganizations extends Component {
  renderForm = () => {
    if (this.props.organizations.length === 0) {
      this.props.getOrganizations();
      return <LoadingSpinner></LoadingSpinner>;
    } else {
      return <BillingForm organizations={this.props.organizations} />;
    }
  };

  renderSessions = () => {
    if (this.props.sessions.length === 0) {
      return (
        <Typography variant="h5" gutterBottom>
          No sessions to display.
        </Typography>
      );
    } else {
      return (
        <BillingSessions
          sessions={this.props.sessions.records}
        ></BillingSessions>
      );
    }
  };

  renderTotal = () => {
    if (this.props.sessions.length !== 0) {
      return (
        <BillingTotalTime
          totalTime={this.props.sessions.time}
        ></BillingTotalTime>
      );
    }
  };

  render() {
    return (
      <Container maxWidth="lg" style={{ marginTop: "24px" }}>
        <Typography variant="h3" gutterBottom>
          Billing
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={4}>
            {this.renderForm()}
          </Grid>
          <Grid item md={6}>
            {this.renderSessions()}
          </Grid>
          <Grid item md={2}>
            {this.renderTotal()}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organizationReducer.organizations,
    sessions: state.organizationReducer.sessions || [],
    selectedOrganization: state.organizationReducer.selectedOrganization,
    selectedStartDate: state.generalGraphReducer.selectedStartDate,
    selectedEndDate: state.generalGraphReducer.selectedEndDate,
  };
};

export default connect(mapStateToProps, { getOrganizations })(
  BillingOrganizations
);
