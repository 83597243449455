import { useSelector } from "react-redux"
import { useMemo } from "react"

import enUS from "./en_US"
import frCA from "./fr_CA"

const languageFiles = {
  "en-US": enUS,
  "fr-CA": frCA,
}

export const useTranslation = () => {
  const language = useSelector((state) => state.userReducer.language)

  const t = (key) => {
    const translations = languageFiles[language] || enUS

    const translation = translations[key]
    return translation || key
  }

  return t
}

// export const useTranslation = () => {
//   const language = useSelector((state) => state.userReducer.language)

//   const t = () => {
//     const translations = languageFiles[language] || enUS // Fallback to English if language not found

//     // Return a function that retrieves the translation for a given key
//     return (key) => {
//       if (typeof key !== "string") {
//         console.warn("Invalid key provided to translation function:", key)
//         return ""
//       }
//       const translation = translations[key]
//       return translation !== undefined ? translation : key
//     }
//   }

//   return t
// }

// export const translate = (key, language) => {
//   const translations = languageFiles[language] || enUS
//   return translations[key] || key
// }
