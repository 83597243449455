import React from "react";
import "./NotEnoughNF.scss";
import Button from "@material-ui/core/Button";
import PermissionsService from '../../../lib/PermissionsSystem'

function NotEnoughNF(props) {
  return (
    <div className="notEnoughNF-container">
        <main>
          {props.notEnoughNF}
        </main>

      {
         PermissionsService.HasPermission("admin.billing.read")
          ?
          <div className="buttons">
              <Button
                  variant="outliend"
                  color="primary"
                  className="cancel"
                  onClick={props.handleReset}
                >
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={props.MoveToBilling}>
                Upgrade Plan
                </Button>
              </div>
            :
            <div className="buttons">
               <Button
                  variant="outliend"
                  color="primary"
                  className="cancel"
                  onClick={props.handleReset}
                >
                    Cancel
                </Button>
            </div>
      }
     
    </div>
  );
}

export default NotEnoughNF;
