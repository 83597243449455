import moment from "moment";
import http from "../http-common";
import util from "util";
import { Debug } from "../lib";
import {
  GET_ORGANIZATION_SINGLE,
  GET_ACTIVE_USERS,
  PERMADMIN_GET_PERMISSIONS_LISTING,
  PERMADMIN_GET_ROLES_LISTING,
  PERMADMIN_CREATE_PERMISSION,
  PERMADMIN_UPDATE_PERMISSION,
  PERMADMIN_DELETE_PERMISSION,
  GET_META_TEMPLATES,
  GET_META_TEMPLATE_SESSIONS,
  GET_COUNT_PAITENTS_ORG,
} from "./types";

function tryDispatch(dispatch, args) {
  if (dispatch) {
    dispatch(args);
  }
}

export default class AdminActions {
  static ACTIVE_USERS_ROUTE = "organizations/active_users";
  static ACTIVE_USERS_ROUTE_ORG = "organizations/:org_id/active_users";
  static ACTIVE_USERS_ROUTE_ORG_COUNTS = "organizations/:org_id/active_users/patients";
  static PERMISSIONS_LIST_ROUTE = "developer/permissions";
  static PERMISSIONS_MODIFY_ROUTE = "developer/permissions/:id";
  static ROLES_LIST_ROUTE = "developer/permissions/roles";
  static ROLES_LIST_ROUTE = "developer/permissions/roles";
  static GET_LEVELS_ROUTE = "levels";
  static GET_LEVELS_INSPECTED_ROUTE = "levels/inspect_all";
  static GET_SESSION_META = "sessions/:level_id/:level_version/meta_data";
  static GET_ORGANIZATION_ROUTE = "organizations/:org_id";

  static GetActiveUsers(options) {
    const start_date = options.start_date;
    const end_date = options.end_date;
    const org_id = options.org_id;
    const is_daily = options.is_daily;

    const route =
      org_id !== undefined
        ? AdminActions.ACTIVE_USERS_ROUTE_ORG.replace(":org_id", options.org_id)
        : AdminActions.ACTIVE_USERS_ROUTE;

    return async (dispatch) => {
      const response = await http
        .get(route, {
          params: {
            start_date: start_date,
            end_date: end_date,
            is_daily: is_daily,
          },
        })
        .then((response) => {
          let data = response.data;
          data.sort((a, b) => {
            if (a.date > b.date) return 1;
            if (b.date > a.date) return -1;
            return 0;
          });
          return data;
        })
        .catch((error) => {
          return error;
        });

      tryDispatch(dispatch, {
        type: GET_ACTIVE_USERS,
        payload: response,
      });

      return response;
    };
  }

  static GetCountPatientOrg (options) {
    const route = AdminActions.ACTIVE_USERS_ROUTE_ORG_COUNTS.replace(":org_id", options?.org_id)
    return async (dispatch) => {
      
      const response = await http
        .get(route,  {
          params: {
            between_start: options.start_date,
            between_end: options.end_date,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      dispatch({
        type: GET_COUNT_PAITENTS_ORG,
        payload: response.data,
        response: response,
      });

      return response;
    };
  }

  static GetPermissionsList() {
    return async (dispatch) => {
      const response = await http
        .get(AdminActions.PERMISSIONS_LIST_ROUTE)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      dispatch({
        type: PERMADMIN_GET_PERMISSIONS_LISTING,
        payload: response.data,
        response: response,
      });

      return response;
    };
  }

  static CreatePermission(options) {
    return async (dispatch) => {
      const data = {
        name: options.name,
        privilege_req: options.privilege || 0,
        parent_id: options.parent_id,
        description: options.description,
      };
      const response = await http
        .post(AdminActions.PERMISSIONS_LIST_ROUTE, data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      dispatch({
        type: PERMADMIN_CREATE_PERMISSION,
        payload: response.data || response,
        response: response,
      });

      return response;
    };
  }

  static UpdatePermission(options) {
    return async (dispatch) => {
      const data = {
        privilege_req: options.privilege_req,
        parent_id: options.parent_id,
        description: options.description,
      };
      const response = await http
        .put(
          AdminActions.PERMISSIONS_MODIFY_ROUTE.replace(":id", options.id),
          data
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      dispatch({
        type: PERMADMIN_UPDATE_PERMISSION,
        payload: response.data || response,
        response: response,
      });

      return response;
    };
  }

  static DeletePermission(id) {
    return async (dispatch) => {
      const response = await http
        .delete(AdminActions.PERMISSIONS_MODIFY_ROUTE.replace(":id", id))
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      dispatch({
        type: PERMADMIN_DELETE_PERMISSION,
        payload: response.data || response,
        response: response,
      });

      return response;
    };
  }

  static GetRoleList() {
    return async (dispatch) => {
      const response = await http
        .get(AdminActions.ROLES_LIST_ROUTE)
        .then((response) => {
          let data = response.data.roles;
          data.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
          });
          return data;
        })
        .catch((error) => {
          return error;
        });

      dispatch({
        type: PERMADMIN_GET_ROLES_LISTING,
        payload: response,
      });

      return response;
    };
  }

  static GetAvailableLevelVersions(options) {
    options = options || {};
    return async (dispatch) => {
      const response = await http
        .get(AdminActions.GET_LEVELS_INSPECTED_ROUTE, {
          params: {
            org_id: options.org_id || undefined,
            start_date: options.start_date || undefined,
            end_date: options.end_date || undefined ,
          },
        })
        .then(async (response) => {
          let data = response.data;
          return data;
        })
        .catch((error) => {
          return error;
        });

      dispatch({
        type: GET_META_TEMPLATES,
        payload: response,
      });

      return response;
    };
  }

  static GetSessions(options) {
    return async (dispatch) => {
      const response = await http
        .get(
          AdminActions.GET_SESSION_META.replace(
            ":level_id",
            options.level_id
          ).replace(":level_version", options.level_version),
          {
            params: {
              offset: options.offset || 0,
              limit: options.limit || undefined,
              org_id: options.org_id || undefined,
              start_date : options.start_date || undefined ,
              end_date : options.end_date || undefined
            },
          }
        )
        .then((response) => {
          let data = response.data;
          return data;
        })
        .catch((error) => {
          return error;
        });

      dispatch({
        type: GET_META_TEMPLATE_SESSIONS,
        payload: response,
      });
      return response;
    };
  }

  static GetOrganization(options) {
    return async (dispatch) => {
      const response = await http
        .get(
          AdminActions.GET_ORGANIZATION_ROUTE.replace(":org_id", options.org_id)
        )
        .then((response) => {
          let data = response.data;
          return data;
        })
        .catch((error) => {
          return error;
        });

      dispatch({
        type: GET_ORGANIZATION_SINGLE,
        payload: response,
      });
      return response;
    };
  }
}
