import React from "react"
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    backgroundColor: theme.palette.white,
  },
}))

const ActivityInput = ({
  parameter,
  value,
  onChange,
  name,
  inputRef,
  error,
}) => {
  const classes = useStyles()

  const renderParameter = (parameter) => {
    switch (parameter.type) {
      case "integer":
        return (
          <TextField
            key={parameter.key}
            name={name}
            label={parameter.name}
            type='number'
            variant='outlined'
            InputProps={{ min: parameter.min, max: parameter.max }}
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value, 10))}
            helperText={error ? error.message : parameter.description}
            error={!!error}
            fullWidth
            margin='normal'
            className={classes.textField}
            inputRef={inputRef}
          />
        )
      case "enum":
        return (
          <FormControl
            key={parameter.key}
            className={classes.formControl}
            variant='outlined'
            fullWidth
            error={!!error}>
            <InputLabel>{parameter.name}</InputLabel>
            <Select
              name={name}
              value={value}
              variant='outlined'
              label={parameter.name}
              onChange={(e) => onChange(e.target.value)}
              inputRef={inputRef}>
              {parameter.values?.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              )) || []}
            </Select>
            <Typography
              variant='caption'
              color={error ? "error" : "textSecondary"}>
              {error ? error.message : parameter.description}
            </Typography>
          </FormControl>
        )
      case "boolean":
        return (
          <FormControlLabel
            key={parameter.key}
            control={
              <Checkbox
                name={name}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                inputRef={inputRef}
              />
            }
            label={
              <div>
                <Typography variant='body1'>{parameter.name}</Typography>
                <Typography
                  variant='caption'
                  color={error ? "error" : "textSecondary"}>
                  {error ? error.message : parameter.description}
                </Typography>
              </div>
            }
          />
        )
      default:
        return null
    }
  }

  if (!parameter || !parameter.type) {
    return <></>
  }
  return <div>{renderParameter(parameter)}</div>
}

export default ActivityInput
