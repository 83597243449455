import React, { Component } from "react";
import RadarPlot from "./RadarPlot";
import { Radar } from "react-chartjs-2";

export default class RadarPlotWrapper extends Component {
  componentDidMount() {
    this.setState({
      chart: new RadarPlot(this.refs.chart, this.props.data),
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillReceiveProps(nextProps) {
    this.state.chart.update(nextProps.data);
  }

  render() {
    return <div className="chart-area" ref="chart"></div>;
  }
}
