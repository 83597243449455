import React, { Component } from "react";
import { Container } from "@material-ui/core";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { reqPasswordReset } from "../../actions";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import * as yup from "yup";

import logo from "../../assets/images/neurofit-logo.svg";

const style = (theme) => ({
  alertBox: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      message: undefined,
      email: "",
      confirmEmail: "",
      errors: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderSubmittedMessage = this.renderSubmittedMessage.bind(this);
    this.renderFormErrors = this.renderFormErrors.bind(this);

    this.schema = yup.object().shape({
      email: yup.string().email().required(),
      emailConfirm: yup.string().oneOf([yup.ref("email")], "Emails must match"),
    });
  }

  async onSubmit(event) {
    event.preventDefault();
    try {
      const data = {
        email: this.state.email,
        confirmEmail: this.state.confirmEmail,
      };
      const validData = this.schema.validateSync(data);
      let response = await reqPasswordReset(validData)(() => {});

      if (response) {
        this.setState({
          ...this.state,
          submitted: true,
          message: "An email has been sent with further instructions",
        });
      } else {
        this.setState({
          ...this.state,
          submitted: true,
          message: "An error has occurred, please try again later",
        });
      }
      setTimeout(() => {
        this.props.history.push("/");
        console.log("Returning to login");
      }, 5000);
    } catch (e) {
      if (e.name === "ValidationError") {
        this.setState({
          ...this.state,
          submitted: false,
          message: undefined,
          errors: e.errors,
        });
      } else {
        console.error(e);
        this.setState({
          ...this.state,
          submitted: false,
          message: undefined,
          errors: ["An unexpected error has occurred"],
        });
      }
    }
  }

  renderFormErrors() {
    return this.state.errors.map((errorMessage, ix) => (
      <div key={ix}>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    ));
  }

  renderForm() {
    const errors = this.state.errors;
    const { classes } = this.props;
    return (
      <Grid item container sm={12} md={6} lg={6} className="half-page-primary">
        <Grid item sm={12}>
          <Typography variant="h2">Request Password Reset</Typography>
        </Grid>
        <Grid item sm={12}>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email || ""}
              onChange={(event) => {
                this.setState({ ...this.state, email: event.target.value });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmEmail"
              label="Confirm Email"
              name="confirmEmail"
              value={this.state.confirmEmail || ""}
              onChange={(event) => {
                this.setState({
                  ...this.state,
                  confirmEmail: event.target.value,
                });
              }}
            />
            {this.renderFormErrors()}
            <Button type="submit" variant="contained">
              Confirm
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }

  renderSubmittedMessage() {
    return (
      <Grid item sm={12} md={6} lg={6}>
        <Typography component="h3" variant="h3">
          {this.state.message}
        </Typography>
        <Typography>Returning to login page...</Typography>
      </Grid>
    );
  }

  render() {
    return (
      <Container maxWidth="xl" className="half-page-container">
        <Grid container className="half-page-grid-container">
          {this.state.submitted
            ? this.renderSubmittedMessage()
            : this.renderForm()}
          {/* neurofit logo and title */}
          <Grid item sm={12} md={6} lg={6} className="half-page-secondary">
            <img src={logo} alt="Neurofit logo" />
            <Typography component="h1" variant="h1">
              Neurofit VR
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withStyles(style)(ForgotPassword));
