import moment from "moment";
import {
  GET_PATIENT_NOTES,
  UPDATE_PATIENT_NOTE,
  CREATE_PATIENT_NOTE,
  DELETE_PATIENT_NOTE,
} from "../actions/types";

const INITIAL_STATE = {
  notes: [],
  has_fetched: false,
  last_saved: undefined,
};

/**
 * Sorts notes in descending order of dates
 * @param {*} a
 * @param {*} b
 * @returns
 */
function sortDates(a, b) {
  let aTime = moment(a.note_date);
  let bTime = moment(b.note_date);
  if (aTime < bTime) return 1;
  if (aTime > bTime) return -1;
  return 0;
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PATIENT_NOTES: {
      let result = {
        ...state,
        response: action.response,
        has_fetched: true,
        note_count: action.payload.note_count,
        notes: action.payload.notes,
      };
      result.notes.sort(sortDates);
      return result;
    }
    case UPDATE_PATIENT_NOTE: {
      const note = action.payload.note;
      let result = {
        ...state,
        response: action.response,
      };
      let notes = [...state.notes];
      notes = result.notes.map((x) => {
        if (x.id == note.id) {
          x.note = note.note;
          x.note_date = note.note_date;
        }
        return x;
      });
      notes.sort(sortDates);
      result.notes = notes;
      return result;
    }
    case CREATE_PATIENT_NOTE: {
      let result = {
        ...state,
        response: action.response,
      };
      let notes = [...state.notes];
      notes.push(action.payload.note);
      notes.sort(sortDates);
      console.log("I am adding a note to the array");
      result.notes = notes;
      return result;
    }
    case DELETE_PATIENT_NOTE: {
      let result = {
        ...state,
        response: action.response,
      };
      if (action.response.status == 200) {
        let notes = [...state.notes];
        notes = notes.filter((x) => x.id != action.payload.note_id);
        result.notes = notes;
      }
      return result;
    }
    default:
      return state;
  }
};
