import React, { useEffect, useState } from "react";
import "./UploadingResult.scss";
import InvalidInputRow from "./helpers/InvalidInputRow";
import Button from "@material-ui/core/Button";
import {
  MAIN_COLUMN_PATIENT,
  MAIN_COLUMN_PRACTITONER,
} from "../../../lib/Constants";

function UploadingResult(props) {
  const { userType, errors, validUsersCount, numberOfUsers } = props;
  const [isClicked, setIsClicked] = useState(false);
  const [users, setUsers] = useState([]);
  const currentColumn =
    userType === "PATIENT"
      ? [...MAIN_COLUMN_PATIENT]
      : [...MAIN_COLUMN_PRACTITONER];

  useEffect(() => {
    if (users.length === errors.length) {
      props.tryUsersAfterEdit(users);
    }
  }, [users.length]);
  const handleNext = () => {
    setIsClicked(true);
  };
  const getUsers = (user) => {
    setUsers((preUser) => {
      return [...preUser, user];
    });
  };
  console.log(errors)
  return (
    <React.Fragment>
      <div className="UploadingResult-container">
        <div className="title">
          {validUsersCount} of {numberOfUsers}{" "}
          {numberOfUsers === 1 ? `user` : `users`} were successfully uploaded
        </div>
        <main>
          <div className="main-header">Please review an entry below:</div>
          <div className="main-content">
            <div className="error-header">
              {Array.from({ length: currentColumn.length }, (_, index) => (
                <div className="cell-header">{currentColumn[index]}</div>
              ))}
            </div>
            <div className="error-rows">
              {errors.map((element) => {
                return (
                  <InvalidInputRow
                    currentColumn={currentColumn}
                    user={element.user}
                    error={element.error}
                    userType={userType}
                    isClicked={isClicked}
                    getUser={getUsers}
                  />
                );
              })}
            </div>
          </div>
        </main>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        className="next-forUploadingResult"
      >
        Next
      </Button>
    </React.Fragment>
  );
}

export default UploadingResult;
