import React from "react";
import "./StepTwo.scss";
import ReactTooltip from "react-tooltip";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Switch from "react-switch";

const toggleStyles = {
  isSelected: {
    opacity: "100%",
  },
  isNotSelected: {
    opacity: "20%",
  },
};

const savingsDisplay = {
  isSelected: {
    opacity: "100%",
  },
  isNotSelected: {
    opacity: "0%",
  },
};

// hard code the prices for now (visually)

const StepTwo = ({ callbackHandlePricingClick, toggle, togglePrice }) => {
  return (
    <>
      {/* toggle container */}
      <div className="d-flex flex-row align-items-center justify-content-center mt-5">
        <span
          className="mr-3 subscription-label"
          style={toggle ? { color: "black" } : { color: "#A54747" }}
        >
          Monthly
        </span>
        <label>
          <Switch
            checked={toggle}
            onChange={togglePrice}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor="#a54747"
            onColor="#a54747"
          />
        </label>
        <span
          className="ml-3 subscription-label"
          style={!toggle ? { color: "black" } : { color: "#A54747" }}
        >
          Yearly
        </span>
      </div>

      <div className="stepTwoContainer">
        <div className="stepTwoGridItem" id="sub_pricing_one">
          <a data-tip data-for="sub_one_tip" className="tip">
            <AiOutlineQuestionCircle />
          </a>
          <ReactTooltip id="sub_one_tip" type="info">
            <span>
              This plan is suitable for those looking to get started with <br />
              Neurofit and for small clinics or independent practices.
            </span>
          </ReactTooltip>
          <h4>Starter Plan</h4>

          <div className="w-100 text-left subscription_description p-2">
            <p>
              <span>5 users</span> <br />
              or less
            </p>

            <section
              id="monthly_section"
              style={
                !toggle ? toggleStyles.isSelected : toggleStyles.isNotSelected
              }
            >
              <p>
                <span>$15 USD</span>
                <br />
                per patient <br />
                <b>monthly</b>
              </p>
            </section>

            <p className="text-white">or</p>

            <section
              id="annually_section"
              style={
                toggle ? toggleStyles.isSelected : toggleStyles.isNotSelected
              }
            >
              <p>
                <span>$162 USD</span>
                <br />
                per patient <br />
                <b>anually</b>
              </p>
            </section>

            <small
              style={
                toggle
                  ? savingsDisplay.isSelected
                  : savingsDisplay.isNotSelected
              }
            >
              10% savings
            </small>
          </div>

          <button
            className="select_button"
            id="pricing_one_button"
            value={0}
            onClick={callbackHandlePricingClick}
          >
            select
          </button>
        </div>

        <div className="stepTwoGridItem" id="sub_pricing_two">
          <a data-tip data-for="sub_two_tip" className="tip">
            <AiOutlineQuestionCircle />
          </a>
          <ReactTooltip id="sub_two_tip" type="info">
            <span>Plan 2 description</span>
          </ReactTooltip>
          <h4>Standard Plan</h4>

          <div className="w-100 subscription_description p-2">
            <p>
              6 to 30 <br />
              users
            </p>

            <section
              id="monthly_section"
              style={
                !toggle ? toggleStyles.isSelected : toggleStyles.isNotSelected
              }
            >
              <p>
                <span>$15 USD</span>
                <br />
                per patient <br />
                <b>monthly</b>
              </p>
            </section>

            <p className="text-white">or</p>

            <section
              id="annually_section"
              style={
                toggle ? toggleStyles.isSelected : toggleStyles.isNotSelected
              }
            >
              <p>
                <span>$126 USD</span>
                <br />
                per patient <br />
                <b>anually</b>
              </p>
            </section>

            <small
              style={
                toggle
                  ? savingsDisplay.isSelected
                  : savingsDisplay.isNotSelected
              }
            >
              15% savings
            </small>
          </div>

          <button
            className="select_button"
            id="pricing_two_button"
            value={1}
            onClick={callbackHandlePricingClick}
          >
            select
          </button>
        </div>

        <div className="stepTwoGridItem" id="sub_pricing_three">
          <a data-tip data-for="sub_three_tip" className="tip">
            <AiOutlineQuestionCircle />
          </a>
          <ReactTooltip id="sub_three_tip" type="info">
            <span>Plan 3 description</span>
          </ReactTooltip>
          <h4>Premium Plan</h4>

          <div className="w-100  subscription_description p-2">
            <p>
              More than <br />
              30 users
            </p>

            <section
              id="monthly_section"
              style={
                !toggle ? toggleStyles.isSelected : toggleStyles.isNotSelected
              }
            >
              <p>
                <span>$15 USD</span>
                <br />
                per patient <br />
                <b>monthly</b>
              </p>
            </section>

            <p className="text-white">or</p>

            <section
              id="annually_section"
              style={
                toggle ? toggleStyles.isSelected : toggleStyles.isNotSelected
              }
            >
              <p>
                <span>$105.60 USD</span>
                <br />
                per patient <br />
                <b>anually</b>
              </p>
            </section>

            <small
              style={
                toggle
                  ? savingsDisplay.isSelected
                  : savingsDisplay.isNotSelected
              }
            >
              20% savings
            </small>
          </div>

          <button
            className="select_button"
            id="pricing_three_button"
            value={2}
            onClick={callbackHandlePricingClick}
          >
            select
          </button>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
