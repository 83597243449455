import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

const useStyles = makeStyles({
  root: {
    width: 281,
    height: 202,
    backgroundColor: "#E2EFF9",
    "@media (max-width: 950px)": {
      margin: "18px",
    },
  },
  wizardButtonTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
});

export default function AddTypeStep(props) {
  const [additionType, setAdditionType] = React.useState("additionType");
  const classes = useStyles();

  const handleChange = (type) => {
    setAdditionType(type);
    if ("onTypeChange" in props) {
      props.onTypeChange(type);
    }
  };
  return (
    <div className="row">
      <div className="col d-flex justify-content-center">
        <div>
          <div
            onClick={() => {
              handleChange("PATIENT");
            }}
          >
            <Card
              className={`d-flex ${classes.root}`}
              variant={additionType === "PATIENT" ? "outlined" : ""}
            >
              <CardActionArea>
                <p
                  style={{
                    fontFamily: "MontserratBold",
                    fontSize: "16px",
                    color: "#333437",
                  }}
                >
                  Patient
                </p>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
      <div className="col d-flex justify-content-center">
        <div>
          <div
            onClick={() => {
              handleChange("ORG_USER");
            }}
          >
            <Card
              className={`d-flex ${classes.root}`}
              variant={additionType === "ORG_USER" ? "outlined" : ""}
            >
              <CardActionArea>
                <p
                  style={{
                    fontFamily: "MontserratBold",
                    fontSize: "16px",
                    color: "#333437",
                  }}
                >
                  Practitioner
                </p>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
