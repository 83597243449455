import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import OrganizationsListRow from "./OrganizationsListRow";
import TableSortLabel from '@mui/material/TableSortLabel';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
    padding: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    backgroundColor: '#c4dfef',
  },
  tableHeadCell: {
    color : '#2290D1'
  },
  sortTableActive : {
    color : 'rgba(34, 144, 209 , 0.87) !important'  ,
  },
  wideCell: {
    minWidth: "150px",
  },
  dataDeep : {
    minWidth: "100px",
    color : '#2290D1'
  }
}));

function renderTableRows(props , order , orderBy) {

  function descendingComparator(a, b, orderBy) {

    if (b['Owner'] && a['Owner'] && b['Owner'][orderBy] < a['Owner'][orderBy]) {
      return -1;
    }
    if (b['Owner'] && a['Owner'] && b['Owner'][orderBy] > a['Owner'][orderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingComparator_1(a, b, orderBy) {

    if (b && a && b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b && a && b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    if(orderBy === 'name' || orderBy === 'created_at' || orderBy === 'updated_at') {
      return order === "desc"
      ? (a, b) => descendingComparator_1(a, b, orderBy)
      : (a, b) => -descendingComparator_1(a, b, orderBy);
    }
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  if (Array.isArray(props.organizations)) {
    let data = props.hide_inactive
      ? props.organizations.filter((x) => x.privilege != -1)
      : props.organizations;
    return data.sort(getComparator(order,orderBy)).map((row) => {
      return (
        <OrganizationsListRow
          key={row.id}
          {...row}
          dataPre = {1}
          setPrivilege={props.setPrivilege}
        ></OrganizationsListRow>
      );
    });
  } else if (props.organizations?.privilege === 0) {
    let data = props.organizations
    return (
        <OrganizationsListRow
          key={data.id}
          dataPre = {0}
          {...data}
        ></OrganizationsListRow>
    )
  } else {
    return (
      <TableRow>
        <TableCell colSpan={7}>Failed to load organizations</TableCell>
      </TableRow>
    );
  }
}

export default function OrganizationsList(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [tableRows , setTableRows] = React.useState(renderTableRows(props ,  order , orderBy));
  useEffect(() => { 
    if(order || orderBy || props.hide_inactive !== null) {
      setTableRows(renderTableRows(props , order , orderBy))
    }
  },[order , orderBy , props.hide_inactive])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };


  return (
    <TableContainer className={classes.paper} component={Paper}>
      <Table className={classes.table} aria-label="Organization Table">
        <TableHead  component="thead" className={classes.tableHead}>
          <TableRow component="tr">
            <TableCell className={classes.dataDeep}>
            Organization Report
            </TableCell>
            <TableCell  className={classes.tableHeadCell}>
                <TableSortLabel 
                  active={orderBy === "first_name"}
                  direction={orderBy === "first_name" ? order: 'asc'}
                  onClick={createSortHandler("first_name")}
                  className={orderBy === "first_name" && classes.sortTableActive}
                >
                First Name
                </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
            <TableSortLabel 
                  active={orderBy === "last_name"}
                  direction={orderBy === "last_name" ? order: 'asc'}
                  onClick={createSortHandler("last_name")}
                  className={orderBy === "last_name" && classes.sortTableActive}
                >
            Last Name
            </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell} >
            <TableSortLabel 
                  active={orderBy === "email"}
                  direction={orderBy === "email" ? order: 'asc'}
                  onClick={createSortHandler("email")}
                  className={orderBy === "email" && classes.sortTableActive} >
              Email
            </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell} >
            <TableSortLabel 
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order: 'asc'}
                  onClick={createSortHandler("name")}
                  className={orderBy === "name" && classes.sortTableActive} >
            Organization Name
            </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell} >
            <TableSortLabel 
                  active={orderBy === "updated_at"}
                  direction={orderBy === "updated_at" ? order: 'asc'}
                  onClick={createSortHandler("updated_at")}
                  className={orderBy === "updated_at" && classes.sortTableActive} >
              Last Update
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell} >
            <TableSortLabel 
                  active={orderBy === "created_at"}
                  direction={orderBy === "created_at" ? order: 'asc'}
                  onClick={createSortHandler("created_at")}
                  className={orderBy === "created_at" && classes.sortTableActive} >
             Creation date
             </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell} >
              Privilege
            </TableCell>
            {props?.organizations?.privilege !== 0 &&  <TableCell className={classes.tableHeadCell} >
              Set Privilege
            </TableCell>}
            {props?.organizations?.privilege !== 0 && <TableCell className={classes.tableHeadCell}>
              Update Privilege
            </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
