import React from "react";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleLogoutModal } from "../../actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: "#000 ",
    "&:hover": {
      color: "#f00",
      // background: "#f00"
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px soild #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  drawerButton: {
    width: "100%",
  },
}));

export default function LogoutButton(props) {
  const classes = useStyles();
  let history = useHistory();
  const open = useSelector((state) => state.appbarReducer.logoutModalOpen);
  const users = useSelector((state) => state.userReducer.users);
  const dispatch = useDispatch();
  const handleOpen = () => dispatch(toggleLogoutModal(true));
  const handleClose = () => dispatch(toggleLogoutModal(false));

  function goToLogin() {
    history.push("/");
  }

  let canLogin = true;
  if (Array.isArray(users)) {
    if (
      users.filter(function (e) {
        return e.can_login === false;
      }).length > 0
    ) {
      // if we find any users that are logged in, the toggle with logout all users.
      canLogin = false;
    }
  }

  const logoutComponent = () => {
    return (
      <Button
        style={{
          paddingLeft: 0,
          height: "42px",
          width: "125px",
          color: "#fff",
          backgroundColor: "rgb(220, 0, 78)",
        }}
        varient="contained"
        color="secondary"
        endIcon={<ExitToAppIcon />}
        onClick={() => {
          dispatch(props.logoutUser());
          goToLogin();
        }}
      >
        Logout
      </Button>
    );
  };

  const logoutButtonStyle = () => {
    if (props.inDrawer) {
      return (
        <Button
          varient="contained"
          color="secondary"
          style={{
            paddingLeft: 0,
            height: "42px",
            width: "auto",
            color: "#fff",
            backgroundColor: "rgb(220, 0, 78)",
          }}
          endIcon={<ExitToAppIcon />}
          onClick={() => {
            if (canLogin === false) {
              dispatch(props.logoutUser());
              goToLogin();
            } else {
              handleOpen();
            }
          }}
        ></Button>
      );
    } else {
      return (
        <Button
          varient="contained"
          className={classes.button}
          endIcon={<ExitToAppIcon />}
          onClick={() => {
            if (canLogin === false) {
              dispatch(props.logoutUser());
              goToLogin();
            } else {
              handleOpen();
            }
          }}
        >
          Logout
        </Button>
      );
    }
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {logoutButtonStyle()}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Finished? Toggle off.</h2>
            <p id="transition-modal-description">
              Leaving a user's login status switch on can allow them access
              while you are not logged in.
            </p>
            {logoutComponent()}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
