import React, { Component, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// importing Material UI Components
import {
  Fab,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { capitalize, trimRight } from "../../../lib/index";
import { updateUser } from "../../../actions/index";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import PermissionsService from "../../../lib/PermissionsSystem";
import {
  DEFAULT_PRONOUN_SETS,
  DEFAULT_GENDERS,
  USER_TYPES,
} from "../../../lib/Constants";

import Tooltip from "@material-ui/core/Tooltip";

// imports the user data that will be used later on when rendering
// import { useDispatch, useSelector, connect } from 'react-redux';
// import { updateSelectedUser } from '../../actions/';
// import { BrowserRouter as Router, Route, Link, useLocation, Switch } from 'react-router-dom'

const styles = (theme) => ({
  patientContainer: {
    backgroundColor: "#fafafa",
    border: "3px solid #D0EEFB",
    borderRadius: "5px",
    margin: "0",
    width: "90%",
    padding: ".5rem",
    color: "#555555",
  },
  infoLabel: {
    fontWeight: "bold",
    float: "right",
    paddingRight: "0.5em",
  },
  icon: {
    width: "32px",
    height: "32px",
    float: "right",
    marginLeft: "16px",
  },
  input: {
    width: "100%",
  },
});

/**
 * Component for patient basic information (name, gender, contact info, etc...)
 */
class UserFile extends React.Component {
  constructor(props) {
    super(props);

    this.submitUpdate = this.submitUpdate.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.mapToState = this.mapToState.bind(this);
    this.mapToStateValue = this.mapToStateValue.bind(this);
    this.updateGender = this.updateGender.bind(this);
    this.updatePronounSet = this.updatePronounSet.bind(this);
    this.generatePronounSample = this.generatePronounSample.bind(this);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const props = this.props;

    return {
      isEditing: false,
      user_id: props.user_id,
      user_data: {
        ...props.user,
        ...props.patient_data,
        dob: moment(props.user.dob),
      },
      // User data that has been edited
      working_user_data: {},
      has_custom_pronouns:
        DEFAULT_PRONOUN_SETS.find(
          (x) => x.subject === props.user.pronoun_subject
        ) === undefined,
      has_custom_gender: !DEFAULT_GENDERS.includes(
        props.user.gender.toLowerCase()
      ),
      info_message: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      console.log("Refreshing user state");
      this.setState(this.getInitialState());
    }
  }

  async submitUpdate() {
    try {
      const result = await updateUser(
        this.state.user_id,
        this.state.working_user_data
      )();
      this.setState({
        ...this.state,
        user_data: {
          ...this.state.user_data,
          ...this.state.working_user_data,
        },
        info_message: (
          <span className={this.props.classes.statusOk}>
            Updated user account
          </span>
        ),
        isEditing: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        ...this.state,
        working_user_data: {},
        error_message: (
          <span className={this.props.classes.statusBad}>
            Failed to update account
          </span>
        ),
        isEditing: false,
      });
    }
  }

  async cancelChanges() {
    this.setState({
      ...this.state,
      working_user_data: {},
      isEditing: false,
    });
  }

  mapToState(target) {
    return {
      value:
        this.state.working_user_data[target] === undefined
          ? this.state.user_data[target]
          : this.state.working_user_data[target],
      onChange: (event) => {
        this.setState({
          ...this.state,
          working_user_data: {
            ...this.state.working_user_data,
            [target]: event.target.value,
          },
        });
      },
    };
  }

  mapToStateValue(target) {
    return {
      value:
        this.state.working_user_data[target] === undefined
          ? this.state.user_data[target]
          : this.state.working_user_data[target],
      onChange: (value) => {
        this.setState({
          ...this.state,
          working_user_data: {
            ...this.state.working_user_data,
            [target]: value,
          },
        });
      },
    };
  }

  updateGender(event) {
    let newState = this.state;
    newState.has_custom_gender = event.target.value === "custom";
    if (newState.has_custom_gender) {
      newState.working_user_data.gender =
        this.state.working_user_data.gender || newState.user_data.gender;
    } else {
      newState.working_user_data.gender = event.target.value;
    }
    console.log(newState.gender);
    this.setState(newState);
  }

  updatePronounSet(event) {
    let newState = this.state;
    newState.has_custom_pronouns = event.target.value === "custom";
    if (!newState.has_custom_pronouns) {
      let set = DEFAULT_PRONOUN_SETS.find(
        (x) => x.subject == event.target.value
      );
      newState.working_user_data.pronoun_subject = set.subject;
      newState.working_user_data.pronoun_possessive = set.possessive;
      newState.working_user_data.pronoun_object = set.object;
    }
    this.setState(newState);
  }

  generatePronounSample(options) {
    const pronoun_subject =
      this.state.working_user_data.pronoun_subject ||
      this.state.user_data.pronoun_subject;
    const pronoun_object =
      this.state.working_user_data.pronoun_object ||
      this.state.user_data.pronoun_object;
    const pronoun_possessive =
      this.state.working_user_data.pronoun_possessive ||
      this.state.user_data.pronoun_possessive;
    const pronoun_reflexive = pronoun_object + "self";
    const pronoun_is_plural = pronoun_subject === "they";

    return (
      <React.Fragment>
        {options.capitalize_subject ? (
          <em>
            <b>{capitalize(pronoun_subject)}</b>
          </em>
        ) : (
          <span>{capitalize(pronoun_subject)}</span>
        )}
        &nbsp;
        {pronoun_is_plural ? "are" : "is"} early for{" "}
        {options.capitalize_possessive ? (
          <em>
            <b>{pronoun_possessive}</b>
          </em>
        ) : (
          <span>{pronoun_possessive}</span>
        )}{" "}
        appointment, can you go talk to{" "}
        {options.capitalize_object ? (
          <em>
            <b>{pronoun_object}</b>
          </em>
        ) : (
          <span>{pronoun_object}</span>
        )}
        ?{" "}
        {options.capitalize_subject ? (
          <em>
            <b>{capitalize(pronoun_subject)}</b>
          </em>
        ) : (
          <span>{capitalize(pronoun_subject)}</span>
        )}{" "}
        {pronoun_is_plural ? "don't" : "doesn't"} like waiting by{" "}
        {pronoun_reflexive}.
      </React.Fragment>
    );
  }

  render() {
    const { patientContainer, infoLabel } = this.props.classes;
    const classes = this.props.classes;
    const {
      first_name,
      last_name,
      gender,
      dob,
      email,
      education,
      diagnosis,
      fitpoints,
    } = this.state.user_data;

    const isEditing = this.state.isEditing;

    const canEdit =
      !(
        PermissionsService.GetUserID() == this.state.user_id &&
        !PermissionsService.HasPermission("org.admin.self.update")
      ) &&
      (this.state.user_data.user_type === USER_TYPES.Patient
        ? PermissionsService.HasPermission("org.admin.patients.update")
        : this.state.user_data.user_type === USER_TYPES.Practitioner
        ? PermissionsService.HasPermission("org.admin.users.update")
        : PermissionsService.GetUser().type === USER_TYPES.Admin);

    return (
      <Grid
        style={{ width: "100%" }}
        container
        spacing={3}
        className={patientContainer}
      >
        {/* Form Group */}
        <Grid item container xs={12}>
          {canEdit && (
            <Grid item container xs={12} direction="row-reverse">
              {this.state.isEditing ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Fab onClick={this.submitUpdate} className={classes.icon}>
                      <SaveIcon />
                    </Fab>
                    <Fab onClick={this.cancelChanges} className={classes.icon}>
                      <CancelIcon />
                    </Fab>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item xs={12}>
                  <Fab
                    onClick={() => {
                      this.setState({ ...this.state, isEditing: true });
                    }}
                    className={classes.icon}
                  >
                    <EditIcon />
                  </Fab>
                </Grid>
              )}
            </Grid>
          )}
          {/* Basic Info */}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <Typography variant="h5">Basic Information</Typography>
            </Grid>
            {/* Name row */}
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <span className={infoLabel}>Name:</span>
              </Grid>
              <Grid item xs={10}>
                {this.state.isEditing ? (
                  <React.Fragment>
                    <Input type="text" {...this.mapToState("first_name")} />
                    <Input type="text" {...this.mapToState("last_name")} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {first_name}&nbsp;
                    {last_name}
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            {/* DOB row */}
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <span className={infoLabel}>Date of Birth:</span>
              </Grid>
              <Grid item xs={10}>
                {this.state.isEditing ? (
                  <React.Fragment>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disableFuture={true}
                        format="MMMM Do, YYYY"
                        {...this.mapToStateValue("dob")}
                      />
                    </MuiPickersUtilsProvider>
                  </React.Fragment>
                ) : (
                  <React.Fragment>{dob.format("MMMM Do, YYYY")}</React.Fragment>
                )}
              </Grid>
            </Grid>
            {/* Gender row */}
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <span className={infoLabel}>Gender:</span>
              </Grid>
              <Grid item xs={10}>
                {this.state.isEditing ? (
                  <React.Fragment>
                    <Grid item container>
                      <Select
                        className={classes.input}
                        value={
                          this.state.has_custom_gender
                            ? "custom"
                            : this.state.working_user_data.gender ||
                              this.state.user_data.gender
                        }
                        onChange={this.updateGender}
                      >
                        <MenuItem value="pnts">Prefer not to say</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="non-binary">Non-binary</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                      </Select>
                      {this.state.has_custom_gender && (
                        <Input
                          type="text"
                          className={classes.input}
                          {...this.mapToState("gender")}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>{capitalize(gender)}</React.Fragment>
                )}
              </Grid>
            </Grid>
            {/* Pronouns row */}
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <Tooltip
                  title={
                    <React.Fragment>
                      <b>EX:</b>
                      <br />
                      {this.generatePronounSample({})}
                    </React.Fragment>
                  }
                  aria-label="pronouns"
                >
                  <span className={infoLabel}>Pronouns:</span>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                {this.state.isEditing ? (
                  <Grid item container>
                    <Select
                      className={classes.input}
                      value={
                        this.state.has_custom_pronouns
                          ? "custom"
                          : this.state.working_user_data.pronoun_subject ||
                            this.state.user_data.pronoun_subject
                      }
                      onChange={this.updatePronounSet}
                    >
                      <MenuItem value="she">Feminine (she/her/hers)</MenuItem>
                      <MenuItem value="he">Masculine (he/him/his)</MenuItem>
                      <MenuItem value="they">
                        Neuter (they/them/theirs)
                      </MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                    {this.state.has_custom_pronouns && (
                      <React.Fragment>
                        <Grid item xs={4}>
                          <Tooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  Pronoun subject
                                </Typography>
                                <b>EX:</b> he, she, they
                                <br />
                                {this.generatePronounSample({
                                  capitalize_subject: true,
                                })}
                              </React.Fragment>
                            }
                            aria-label="pronoun subject"
                          >
                            <Input
                              type="text"
                              {...this.mapToState("pronoun_subject")}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Tooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  Pronoun objective
                                </Typography>
                                <b>EX:</b> him, her, them
                                <br />
                                {this.generatePronounSample({
                                  capitalize_object: true,
                                })}
                              </React.Fragment>
                            }
                            aria-label="pronoun object"
                          >
                            <Input
                              type="text"
                              {...this.mapToState("pronoun_object")}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Tooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  Pronoun possessive
                                </Typography>
                                <b>EX:</b> his, her, their
                                <br />
                                {this.generatePronounSample({
                                  capitalize_possessive: true,
                                })}
                              </React.Fragment>
                            }
                            aria-label="pronoun subject"
                          >
                            <Input
                              type="text"
                              {...this.mapToState("pronoun_possessive")}
                            />
                          </Tooltip>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <b>EX:</b>
                          <br />
                          {this.generatePronounSample({})}
                        </React.Fragment>
                      }
                      aria-label="pronouns"
                    >
                      <span>
                        {this.state.user_data.pronoun_subject}/
                        {this.state.user_data.pronoun_object}/
                        {this.state.user_data.pronoun_possessive}
                      </span>
                    </Tooltip>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            {/* Contact Email */}
            <Grid item container xs={12}>
              <Grid item xs={2}>
                <span className={infoLabel}>Email:</span>
              </Grid>
              <Grid item xs={10}>
                <React.Fragment>{email}</React.Fragment>
              </Grid>
            </Grid>
          </Grid>
          {/* Patient Info */}
          {PermissionsService.HasPermission(
            "org.admin.patients.notes.read"
          ) && (
            <Grid item container xs={12} md={6}>
              <Grid item xs={10}>
                <Typography variant="h5">Patient Information</Typography>
              </Grid>
              {/* Diagnosis */}
              <Grid item container xs={12}>
                <Grid item xs={2}>
                  <span className={infoLabel}>Diagnosis:</span>
                </Grid>
                <Grid item xs={10}>
                  {this.state.isEditing ? (
                    <React.Fragment>
                      <Input
                        type="text"
                        className={classes.input}
                        {...this.mapToState("diagnosis")}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>{diagnosis}</React.Fragment>
                  )}
                </Grid>
              </Grid>
              {/* Education */}
              <Grid item container xs={12}>
                <Grid item xs={2}>
                  <span className={infoLabel}>Education:</span>
                </Grid>
                <Grid item xs={10}>
                  {this.state.isEditing ? (
                    <React.Fragment>
                      <Input
                        type="text"
                        className={classes.input}
                        {...this.mapToState("education")}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>{education}</React.Fragment>
                  )}
                </Grid>
              </Grid>
              {/* Fitpoints */}
              <Grid item container xs={12}>
                <Grid item xs={2}>
                  <span className={infoLabel}>Fitpoints:</span>
                </Grid>
                <Grid item xs={10}>
                  <React.Fragment>{fitpoints}</React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h5">{this.state.info_message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UserFile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  patient_data: PropTypes.object.isRequired,
  user_id: PropTypes.string.isRequired,
};

export default withStyles(styles)(UserFile);
