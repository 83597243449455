import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Divider } from "@material-ui/core";
import "./index.scss";
import {
  TiSocialFacebookCircular,
  TiSocialInstagramCircular,
  TiSocialLinkedinCircular,
  TiSocialTwitterCircular,
} from "react-icons/ti";
import Instagram from "../../../assets/images/instagram.svg";
const Footer = () => {
  return (
    <div id="footer-container">
      <Divider className="divider" />
      <div className="div-1">
        <ul className="list-1">
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/neurofitvr/neurofit-check-in">
              {" "}
              CONTACT SUPPORT
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.loom.com/share/folder/42c8d3fa8e0a4a0993d26f1d7b1b602e">
              FAQ
            </a>
          </li>
        </ul>

        <div className="list-2">
          <a href="https://www.neurofit.ca/" target="_blank" ><Button variant="contained">NEUROFIT</Button></a>
          <div className="social-media">
            <a href="https://ca.linkedin.com/company/neurofitvr">
              <TiSocialLinkedinCircular className="social" />
            </a>
            <a href="https://twitter.com/neurofitvr">
              <TiSocialTwitterCircular className="social" />
            </a>
            <a href="https://www.facebook.com/neurofitvr/">
              {" "}
              <TiSocialFacebookCircular className="social" />
            </a>
            <a href="https://www.instagram.com/neurofitvr/">
              <img
                src={Instagram}
                alt={"instagram"}
                className="social"
                height={"33px"}
                width={"33px"}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="copyright-terms">
        <div>
          <span>&copy;</span>Neurofit VR Inc. 2022 | info@neurofit.ca
        </div>
      </div>
    </div>
  );
};
export default withRouter(Footer);
