import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import util from "util";
import { Debug } from "../../lib";
import {
  Education , 
  DIAGNOSIS
} from '../../lib/Constants'
import { useStore } from "react-redux";
import { createUser, getUsersByOrg } from "../../actions";
import "./AddUserForm.scss";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  tiltStyle: {
    maxWidth: "800px",
    margin: "2.5rem auto",
    border: `2.5px solid #16A0D8`,
    borderRadius: "10px",
    outline: "none",
    height: "90%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "800px",
    width: "auto",
    height: "auto",
    // backgroundColor: '#fafafa',
    // padding: theme.spacing(6),
    padding: "0",
    // overflow: 'auto',
    margin: "auto 0",
    borderRadius: "10px",
    outline: "none",
  },
  form: {
    width: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    padding: "2.5rem 3.5rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      padding: "0",
    },
  },
  addBtn: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
  inlineInput: {
    width: "100%",
  },
  radioContainer: {
    marginTop: theme.spacing(1),
  },
  radioGroup: {
    flexDirection: "row",
  },
  personIcon: {
    fontSize: "3.5rem",
    margin: "0 auto",
    backgroundColor: "#E8F5FB",
    borderRadius: "50%",
    padding: "5px",
    border: "2px solid #16A0D8",
  },
  heading: {
    textAlign: "center",
    padding: "10px 0",
    marginTop: "0",
    marginBottom: "0",
  },
  input_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const USER_TYPES = {
  Patient: "patient",
  Practitioner: "practitioner",
};

const schema = yup.object().shape({
  email: yup.string().trim().email().required().label("Email"),
  firstName: yup.string().trim().required().label("First Name"),
  lastName: yup.string().trim().required().label("Last Name"),
  gender: yup.string().trim().required().label("Gender"),
  dob: yup.string().required().label("Date of Birth"),
  user_type: yup.string().optional(),
  practitioner : yup.string().required().label("practitioner"),
  user_password: yup.string().optional(),
  passwordConfirmation: yup.string().optional(),

  education: yup
    .string()
    .trim()
    .when("user_type", {
      is: USER_TYPES.Patient,
      then: yup.string().required(),
      otherwise: yup.string().strip(),
    })
    .label("Education"),
  diagnosis: yup
    .string()
    .trim()
    .when("user_type", {
      is: USER_TYPES.Patient,
      then: yup.string().required(),
      otherwise: yup.string().strip(),
    })
    .label("Education"),
});

export const initialState = {
  user_type: USER_TYPES.Patient,
  email: "",
  firstName: "",
  lastName: "",
  dob: new Date(),
  gender: "",
  diagnosis: "",
  password: "",
  passwordConfirm: "",
  practitioner : ""
};

class AddUserFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
}

export default function AddUserForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const dispatch = useDispatch();

  const responseMessage = useSelector((state) => state.userReducer.response);
  const { enqueueSnackbar } = useSnackbar();  
  const { register, handleSubmit, setValue, errors, reset, control, setError } =
    useForm({
      resolver: yupResolver(schema),
    });

  const open = useSelector((state) => state.modalReducer.modalProps.open);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let [user_type, handleUserTypeChanged] = React.useState(USER_TYPES.Patient);
  let [dob, handleDobChanged] = React.useState(new Date());


  useEffect(() => {
    const unsubscribe = store.subscribe((action) => {
      handleSubmit(onSubmit)();
    });
    return () => unsubscribe();
  }, []);

  const onSubmit = async (data) => {
    // Prepare the data that will be saved for the patient
    let req_data = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      gender: data.gender,
      practitioner : data.practitioner,
      dob: data.dob.split("T")[0],
      diagnosis: data.diagnosis,
      education: data.education,
      is_patient: user_type === USER_TYPES.Patient,
      password:user_type === USER_TYPES.Patient ? undefined : data.user_password,
    };
    Debug.log("data: " + util.inspect(data));
    Debug.log("req: " + util.inspect(req_data));

    let result = await dispatch(createUser(req_data));

    if (result !== undefined && result.status === 200) {
      props.moveToFinalStep();
      await dispatch(getUsersByOrg(props.user_types));
      reset(initialState);
      handleDobChanged(new Date());
    } else {
      if (result.errorType === "DuplicateEntryError") {
        setError("email", {
          type: "manual",
          message: "This email already exists",
        });
      }else {
        props.setActiveStep(result)
      }

    }
  };

  const renderResponseMessage = () => {
    if (responseMessage.status === 200) {
      return (
        <div className={classes.messageContainer}>
          <Alert color="success" variant="standard">
            {responseMessage.message}
          </Alert>
        </div>
      );
    } else if (responseMessage.status === 400) {
      return (
        <div className={classes.messageContainer}>
          <Alert severity="error">{responseMessage.message}</Alert>
        </div>
      );
    }
  };

  const renderFormError = (errorMessage) => {
    return (
      <div className={classes.messageContainer}>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    );
  };

  const renderPatientTooltip = () => {
    return (
      <React.Fragment>
        <Typography>
          A patient cannot access the Neurofit dashboard, but can sign into the
          Neurofit web client via their email when their account is enabled
        </Typography>
      </React.Fragment>
    );
  };

  const renderPractitionerTooltip = () => {
    return (
      <React.Fragment>
        <Typography>
          A practitioner is a user that can create and manage patients within
          your organization. They can sign onto the dashboard using an email and
          password
        </Typography>
      </React.Fragment>
    );
  };

  const body = (
    // <div className={classes.paper}>

    <div className={`${classes.paper} form-paper-container`}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} className={classes.input_container}>
          {/* first name */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth={false}
              id="firstName"
              label="First Name"
              name="firstName"
              autoFocus
              inputRef={register}
              className={classes.inlineInput}
            />
          </Grid>
          {/* last name */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth={false}
              id="lastName"
              label="Last Name"
              name="lastName"
              autoFocus
              inputRef={register}
              className={classes.inlineInput}
            />
          </Grid>

          {/* email */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              inputRef={register}
            />
          </Grid>

          {/* date of birth */}
          <Grid item xs={12} sm={12} md={6}>
            <Input
              type="hidden"
              name="dob"
              id="dob"
              inputRef={register}
              value={moment(dob).toISOString()}
            ></Input>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="dialog"
                format="MM/dd/yyyy"
                margin="normal"
                label="Date of Birth"
                disableFuture
                fullWidth
                value={dob}
                onChange={(date) => {
                  handleDobChanged(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* Gender & Pronouns options */}
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              className={classes.radioContainer + " w-100"}
            >
              <FormLabel component="legend">Sex</FormLabel>
              <Controller
                control={control}
                id="gender"
                name="gender"
                label="Gender"
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    value={value ? value : ""}
                    name={name}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  >
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          {/*<Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              className={classes.radioContainer + " w-100"}
            >
              <FormLabel component="legend">Pronouns</FormLabel>
              <Controller
                control={control}
                id="pronouns"
                name="pronouns"
                label="Pronouns"
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    value={value ? value : ""}
                    name={name}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  >
                    <MenuItem value="feminine">She/Her/Hers</MenuItem>
                    <MenuItem value="masculine">He/Him/His</MenuItem>
                    <MenuItem value="neuter">They/Them/Theirs</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
                  </Grid>*/}

          <Grid item xs={12} sm={12} md={6}>
            <FormControl  component="fieldset"
              className={classes.radioContainer + " w-100"}>
                        <FormLabel  component="legend">
                          Select Practitioner
                        </FormLabel>
                        <Controller
                          control={control}
                          id="practitioner"
                          name="practitioner"
                          label="practitioner"
                          render={({ onChange, value, onBlur, name }) => (
                            <Select
                            labelId="parent-select-label"
                            id="parent-select"
                            fullWidth={false}
                            value={value ? value : ""}
                            onChange={(e) =>
                              onChange(e)
                            }
                          >
                            {props.practitioner &&
                              props.practitioner.map((prac) => {
                                return (
                                  <MenuItem
                                    key={prac.email + prac.first_name}
                                    value={prac.id}
                                  >
                                    {prac.first_name.concat(" " + prac.last_name)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          )}
                        />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              className={classes.radioContainer + " w-100"}
            >
              <FormLabel component="legend">Education</FormLabel>
              <Controller
                control={control}
                id="education"
                label="Education"
                name="education"
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    value={value ? value : ""}
                    name={name}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  >
                    {
                      Education && Education.map(item => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
                component="fieldset"
                className={classes.radioContainer + " w-100"}
              >
                <FormLabel component="legend">Diagnosis</FormLabel>
                <Controller
                  control={control}
                  id="diagnosis"
                  label="Diagnosis"
                  name="diagnosis"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      value={value ? value : ""}
                      name={name}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    >
                      {
                        DIAGNOSIS && DIAGNOSIS.map(item => (
                          <MenuItem
                              key={item}
                              value={item}
                              >
                              {item}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  )}
                />
              </FormControl>
          </Grid>
        </Grid>
      </form>
      {renderResponseMessage()}
      {errors.email && renderFormError(errors.email.message)}
      {errors.firstName && renderFormError(errors.firstName.message)}
      {errors.lastName && renderFormError(errors.lastName.message)}
      {errors.education && renderFormError(errors.education.message)}
      {errors.diagnosis && renderFormError(errors.diagnosis.message)}
      {errors.gender && renderFormError(errors.gender.message)}
      {errors.practitioner && renderFormError(errors.practitioner.message)}
      {errors.user_password && renderFormError(errors.user_password.message)}
      {errors.passwordConfirmation &&
        renderFormError(errors.passwordConfirmation.message)}

    </div>
    // </div>
  );

  return <React.Fragment>{body}</React.Fragment>;
}
