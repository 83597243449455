import React, { Component, useEffect } from "react";
import moment from "moment";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import UsersDropdown from "../../sharedComponents/UsersDropdown";
import GeneralMetricsChartWrapper from "../../d3Components/GeneralMetricsChartWrapper";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
import DateRangePickers from "../../sharedComponents/DateRangePickers";
import MetricSwitches from "../../d3Components/MetricSwitches";
import RadarPlotWrapper from "../../d3Components/RadarPlotWrapper";
import RadarLegend from "../../d3Components/RadarLegend";
import UserSession from "./UserSession";
import UserSkillLevels from "./UserSkillLevels";
import UserPerformanceRatio from "./UserPerformanceRatio";
import UserMood from "./UserMood";

// icon
import {
  DesktopWindows,
  FolderShared,
  Label,
  Mood,
  ExpandMore,
  Comment,
} from "@material-ui/icons";
import BarChartIcon from "@material-ui/icons/BarChart";
import NotesIcon from "@material-ui/icons/Notes";

//import patient file
import UserFile from "./UserFile";
import PatientNotes from "./PatientNotes";

import { connect } from "react-redux";
import {
  getGraphData,
  getUsersByOrg,
  getUserSessionData,
  getRadarPlotData,
  updateSelectedUser,
  getUserSession,
  getUser,
  updateEndDateSelect,
  updateStartDateSelect,
} from "../../../actions/index";
import { Debug } from "../../../lib/index";
import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  capitalize,
} from "@material-ui/core";
import { dispatch } from "d3-dispatch";
import NotesActions from "../../../actions/NotesActions";

class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radarPlotData: [],
      userSkillLevels: [],
      userSession: [],
      graphData: [],
      user: undefined,
      patient_data: undefined,
      start_date: moment().subtract(1, "month").toISOString(),
      end_date: moment().toISOString(),
      accordions: {
        profile_info: true,
        charts: true,
        notes: false,
      },
    };

    this.renderPlaysOverTime = this.renderPlaysOverTime.bind(this);
    this.jumpToSingleDay = this.jumpToSingleDay.bind(this);
    this.handleDateRangeChanged = this.handleDateRangeChanged.bind(this);
    this.handleAccordionStatusChanged =
      this.handleAccordionStatusChanged.bind(this);

    this.chartsRef = React.createRef();
  }

  componentDidMount() {
    // Get the user's information
    this.props.getUser(this.props.match.params.id)(this.props.dispatch);
  }

  handleAccordionStatusChanged(key_name, value) {
    // Update the relevant accordion state depending on the id of the accordion
    this.setState({
      ...this.state,
      accordions: {
        ...this.state.accordions,
        [key_name]: value,
      },
    });
  }

  handleDateRangeChanged(start_date, end_date) {
    console.log(start_date);
    console.log(end_date);
    this.setState({
      ...this.state,
      start_date: moment(start_date).utc().toISOString(),
      end_date: moment(end_date).utc().toISOString(),
    });
  }

  jumpToSingleDay(date) {
    let start_date = moment(date).local().startOf("day");
    let end_date = moment(date).local().endOf("day");
    console.log(start_date);
    console.log(end_date);
    let newState = this.state;
    newState.accordions.charts = true;
    this.setState(newState);
    this.chartsRef?.current?.scrollIntoView();
    updateStartDateSelect(start_date.utc())(this.props.dispatch);
    updateEndDateSelect(end_date.utc())(this.props.dispatch);
    this.handleDateRangeChanged(start_date, end_date);
  }

  renderPlaysOverTime = () => {
    if (this.props.graphData) {
      return (
        <UserSession
          users={this.props.user}
          sessions={this.props.graphData}
          skillSession={this.props.skillLevel}
        />
      );
    } else {
      return <h2>No Session Data</h2>;
    }
  };

  render() {
    const center_style = {
      textAlign: "center",
      width: "100%",
      display: "inline-block",
      height: "1.5em",
      margin: "0 auto",
    };
    return (
      <Container style={{ paddingBottom: "8em" }} maxWidth="xl">
        {this.props.user === undefined && <LoadingSpinner />}
        {this.props.user !== undefined && (
          <React.Fragment>
            <Typography
              variant="h3"
              component="h3"
              style={{ textAlign: "left" }}
            >
              {this.props.user.first_name} {this.props.user.last_name}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{ paddingBottom: "0.25em", textAlign: "left" }}
            >
              {capitalize(this.props.user.gender)} |&nbsp;
              {moment().diff(this.props.user.dob, "years")} |&nbsp;
              <em>{`${this.props.user.pronoun_subject}/${this.props.user.pronoun_object}/${this.props.user.pronoun_possessive}`}</em>{" "}
              |&nbsp;
              {this.props.user.patient_data?.diagnosis}
            </Typography>
            <Grid container spacing={3}>
              <Grid container item xs={12} sm={12} md={12} spacing={3}>
                <Grid container item xs={12}>
                  <Accordion
                    defaultExpanded={true}
                    expanded={this.state.accordions.profile_info}
                    onChange={(e, v) => {
                      this.handleAccordionStatusChanged("profile_info", v);
                    }}
                    style={{ width: "100%" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="patient-info-content"
                      id="profile_info"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="h4"
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <FolderShared
                            fontSize="large"
                            style={{ alignSelf: "center" }}
                          />{" "}
                          &nbsp;Patient File
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <UserFile
                        key={this.props.match.params.id}
                        user_id={this.props.match.params.id}
                        user={this.props.user}
                        patient_data={this.props.patient_data}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/**   CHARTS AND GRAPHS  */}
                <Grid container item xs={12} ref={this.chartsRef}>
                  <Accordion
                    defaultExpanded={true}
                    expanded={this.state.accordions.charts}
                    onChange={(e, v) => {
                      this.handleAccordionStatusChanged("charts", v);
                    }}
                    style={{ width: "100%" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="charts-content"
                      id="charts"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="h4"
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <BarChartIcon
                            fontSize="large"
                            style={{ alignSelf: "center" }}
                          />{" "}
                          &nbsp;Charts and Graphs
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        style={{
                          border: "3px solid #D0EEFB",
                          borderRadius: "5px",
                          padding: ".5rem",
                          color: "#555555",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Select Data Range
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <DateRangePickers
                            startDate={this.state.start_date}
                            endDate={this.state.end_date}
                            last_month
                            disableFuture
                            onValueChanged={this.handleDateRangeChanged}
                          />
                        </Grid>
                        {/* renders the mood chart --- radar plot */}
                        <Grid container item xs={12}>
                          <Grid item xs={12} md={12} lg={6}>
                            <Typography align="center" variant="h6">
                              Average Mood
                            </Typography>
                            <UserMood
                              user_id={this.props.match.params.id}
                              start_date={this.state.start_date}
                              end_date={this.state.end_date}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <Typography align="center" variant="h6">
                              Skill Over Time
                            </Typography>
                            <UserSkillLevels
                              user_id={this.props.match.params.id}
                              start_date={this.state.start_date}
                              end_date={this.state.end_date}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <Typography align="center" variant="h6">
                              Performance Ratio
                            </Typography>
                            <UserPerformanceRatio
                              user_id={this.props.match.params.id}
                              start_date={this.state.start_date}
                              end_date={this.state.end_date}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align="center" variant="h6">
                              Raw Session Data
                            </Typography>
                            <UserSession
                              user={this.props.user}
                              user_id={this.props.match.params.id}
                              start_date={this.state.start_date}
                              end_date={this.state.end_date}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/** Patient Notes */}
                <Grid container item xs={12}>
                  <Accordion
                    defaultExpanded={true}
                    expanded={this.state.accordions.notes}
                    onChange={(e, v) => {
                      this.handleAccordionStatusChanged("notes", v);
                    }}
                    style={{ width: "100%" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="notes-content"
                      id="notes"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="h4"
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <Comment
                            fontSize="large"
                            style={{ alignSelf: "center" }}
                          />{" "}
                          &nbsp;Patient Notes
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        style={{
                          border: "3px solid #D0EEFB",
                          borderRadius: "5px",
                          padding: ".5rem",
                          color: "#555555",
                        }}
                      >
                        <Grid item xs={12}>
                          <PatientNotes
                            jumpToDate={this.jumpToSingleDay}
                            user_id={this.props.match.params.id}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.viewed_user,
    patient_data: state.userReducer.viewed_patient_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: getUser,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
