import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import util from "util";
import { capitalize, Debug } from "../../../lib/index";
import pluginTrendLineLinear from "../../../assets/js/chartjs-plugin-trendline";
import { Chart } from "react-chartjs-2";
import { dispatch } from "d3";
import PropTypes from "prop-types";
import { getUserSessionData } from "../../../actions/index";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";

export default class ChartBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isError: false,
      chartData: undefined,
      chartOptions: undefined,
    };
    this.parseData = this.parseData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.dispatchLoad = this.dispatchLoad.bind(this);
    this.dispatchAPI = this.dispatchAPI.bind(this);
  }

  /**
   * Invoked immediately after a component is mounted (inserted into the tree).
   * This will handle dispatching our initial request for the user's session data
   *
   * @see https://reactjs.org/docs/react-component.html#componentdidmount
   */
  componentDidMount() {
    this.dispatchLoad();
  }

  /**
   * Invoked immediately after updating occurs. This method is not called for the initial render.
   * If the properties have changed, we will dispatch another load request
   *
   * @param {*} prevProps The previous properties for the component
   * @param {*} prevState The previous state of the component
   * @see https://reactjs.org/docs/react-component.html#componentdidupdate
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user_id !== this.props.user_id ||
      prevProps.start_date !== this.props.start_date ||
      prevProps.end_date !== this.props.end_date
    ) {
      this.dispatchLoad();
    }
  }

  /**
   * Dispatches the API request if the state is valid, and handles the result
   */
  dispatchLoad() {
    if (this.props.user_id !== undefined && !this.state.isFetching) {
      this.setState({ ...this.state, isFetching: true, isError: false });
      Debug.log("Fetching user data for skills: " + this.props.user_id);
      // Dispatch the fetch request with our data
      this.fetchData().then(
        (result) => {
          // Store the parsed result into our state so that react will re-render
          this.setState({
            ...this.state,
            chartData: result.chartData,
            chartOptions: result.chartOptions,
            isFetching: false,
          });
          this.forceUpdate();
        },
        (error) => {
          Debug.error(error);
          this.setState({
            ...this.state,
            chartData: undefined,
            chartOptions: undefined,
            isFetching: false,
            isError: true,
          });
        }
      );
    } else {
      this.setState({
        ...this.state,
        chartData: undefined,
        chartOptions: undefined,
        isFetching: false,
        isError: true,
      });
    }
  }

  /**
   * Asyncronously gets the skill graphing data and parses it into the ChartJS data
   * @returns {Promise<Object, Object>} A promise that returns a chartData and chartOptions pair
   */
  async fetchData() {
    const user_id = this.props.user_id;
    const start_date = this.props.start_date;
    const end_date = this.props.end_date;

    // Fetch data (async), using an empty dispatcher
    let response = await this.dispatchAPI(
      user_id,
      start_date,
      end_date
    )((x) => {});
    // Parse the results and return the graphing information
    return this.parseData(response);
  }

  dispatchAPI(user_id, start_date, end_date) {
    throw new Error("dispatchAPI must be overridden in child classes!");
  }

  /**
   * Handles parsing the result of the API request and generating a ChartJS chart
   * @param {Object} payload
   */
  parseData(payload) {
    throw new Error("parseData must be overridden by child classes!");
  }

  renderChart() {
    throw new Error("renderChart must be overridden by child classes!");
  }

  render() {
    return this.state.isFetching ? (
      <LoadingSpinner />
    ) : this.state.chartData !== undefined ? (
      this.renderChart()
    ) : (
      <div>Data not loaded</div>
    );
  }
}
