import React, { useEffect, useState } from "react";
import http from "../../../http-common";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import UploadFile from "../UploadFile";
import AddTypeStep from "./AddTypeStep";
import AddUserTypes from "./AddUserTypes";
import AddUserForm from "../../organizationView/AddUserForm";
import { useDispatch } from "react-redux";
import { createUser, submitUserData } from "../../../actions";
import AddPractitionerUsers from "../AddPractitionerUsers";
import "./AddUserStepper.scss";
import ConfirmInputFields from "./ConfirmInputFields";
import ConfirmImport from "./ConfirmImport";
import ImportInProgress from "./ImportInProgress";
import * as yup from "yup";
import SuccessComponent from "./SuccessComponent";
import UploadingResult from "./UploadingResult";
import moment from "moment/moment";
import {
  MAIN_COLUMN_PATIENT,
  MAIN_COLUMN_PRACTITONER,
  SEX_TYPES,
  DIAGNOSIS,
  OCCUPATION,
  UserTypes,
  MAPPING_OCCUPATION,
} from "../../../lib/Constants";
import { formatDate, getColumn } from "./Assets/HelperFunctions";
import { useSnackbar } from "notistack";
import NotEnoughNF from "./NotEnoughNF";
import { useHistory } from "react-router";
const patientSchema = yup.object().shape({
  FirstName: yup
    .string()
    .trim()
    .required("The First Name is required field")
    .label("First Name"),
  LastName: yup
    .string()
    .trim()
    .required("The Last Name is required field")
    .label("Last Name"),
  DateOfBirth: yup
    .date()
    .typeError("The Date of Birth must be valid")
    .required()
    .test("dateOfBirthTest", function (value) {
      const { createError } = this;
      if (formatDate(value) === "Invalid Date") {
        return createError({
          message: "The Date of Birth must be valid",
        });
      } else {
        return true;
      }
    })
    .label("Date of Birth"),
  Sex: yup
    .string()
    .trim()
    .required()
    .test("sexTest", function (value) {
      const { createError } = this;
      if (!SEX_TYPES.includes(value.toLowerCase())) {
        return createError({
          message: "Please, make sure that the Sex is: male, female, or other",
        });
      } else {
        return true;
      }
    })
    .label("Sex"),
  Email: yup
    .string()
    .trim()
    .email()
    .required()
    .test("testForEmailDuplication", function (value) {
      const { createError } = this;
      return new Promise((resolve) => {
        http
          .request({
            url: `/user/check/${value}/`,
            method: "get",
            params: {
              email: value,
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            resolve(
              createError({
                message: "This email is already taken",
              })
            );
          });
      });
    })
    .label("Email"),
  Diagnosis: yup
    .string()
    .required()
    .trim()
    .test("diagnosisTest", function (value) {
      const { createError } = this;
      if (!DIAGNOSIS.includes(value)) {
        return createError({
          message: "Please, make sure that the Diagnosis is supported",
        });
      } else {
        return true;
      }
    })
    .label("Diagnosis"),
});
const practitionerSchema = yup.object().shape({
  FirstName: yup
    .string()
    .trim()
    .required("The First Name is required field")
    .label("First Name"),
  LastName: yup
    .string()
    .trim()
    .required("The Last Name is required field")
    .label("Last Name"),
  Occupation: yup
    .string()
    .required()
    .trim()
    .test("occupationTest", function (value) {
      const { createError } = this;
      console.log(MAPPING_OCCUPATION.find( item => item.id === value))
      if (OCCUPATION.includes(value) || MAPPING_OCCUPATION.find( item => item.id === value) ) {
        return true;
      } else {
        return createError({
          message: "Please, make sure that the Occupation is supported",
        });
      }
    })
    .label("Occupation"),
  Email: yup
    .string()
    .trim()
    .email()
    .required()
    .test("testForEmailDuplication", function (value) {
      const { createError } = this;
      return new Promise((resolve) => {
        http
          .request({
            url: `/user/check/${value}/`,
            method: "get",
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            resolve(
              createError({
                message: "This email is already taken",
              })
            );
          });
      });
    })
    .label("Email"),
  Sex: yup
    .string()
    .trim()
    .required()
    .test("sexTest", function (value) {
      const { createError } = this;
      if (!SEX_TYPES.includes(value.toLowerCase())) {
        return createError({
          message: "Please, make sure that the Sex is: male, female, or other",
        });
      } else {
        return true;
      }
    })
    .label("Sex"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    textTransform: "capitalize",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  WizardTitle: {
    fontSize: "30px",
    width: "auto",
    lineHeight: "37px",
    margin: "20px auto",
    fontFamily: "MontserratBold",
    color: "#333437",
  },
  WizardTitle_2: {
    margin: "0px auto",
    fontSize: "30px",
    width: "auto",
    lineHeight: "37px",
    fontFamily: "MontserratBold",
    color: "#333437",
  },
  stepper: {
    padding: "24px 113px",
    display: "block",
  },
  buttons: {
    fontFamily: "MontserratBold",
    fontSize: "16.86px",
    color: "#333437",
  },
  buttonCnacel: {
    textTransform: "capitalize",
  },
  btnNext: {
    width: "172px",
    textTransform: "capitalize",
    "@media (max-width: 600px)": {
      width: "100px",
      padding: "6px",
    },
  },
  btnNextText: {
    textTransform: "capitalize",
  },
  resetbtn: {
    width: "191px",
    borderRaduis: "5px",
    textTransform: "capitalize",
  },
  divResetbutton: {
    margin: "0px 5rem 7rem",
  },
}));

export default function AddUsersStepper(props) {
  const getSteps = () => {
    if (user_types === "PATIENT") {
      if (addingType === "individual") {
        return [
          "Who are you creating an account for?",
          "How many users would you like to add?",
          "Create New User Profile",
        ];
      }
      return [
        "Select master blaster campaign settings",
        "Create an ad group",
        "Create an ad",
        "test test",
        "test test",
        "test test",
        "test test",
        "test test",
        "test test",
        "test test",
      ];
    } else {
      if (addingType === "individual") {
        return [
          "Who are you creating an account for?",
          "How many users would you like to add?",
          "Create New User Profile",
        ];
      }
      return [
        "Select master blaster campaign settings",
        "Create an ad group",
        "Create an ad",
        "test test",
        "test test",
        "test test",
        "test test",
        "test test",
        "test test",
        "test test",
      ];
    }
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [user_types, setUserTypes] = React.useState("");
  const [addingType, setAddingType] = React.useState("");
  const [users, setUsers] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [successImportedUser, setSuccessImportedUser] = useState(0);
  const [practitioner, setPractitioner] = useState([]);
  const [notEnoughNF,setNotEnoughNF] = useState(false)

  const { enqueueSnackbar } = useSnackbar();
  const steps = getSteps();
  let history = useHistory();

  const addUsers = async function (user) {
    // Prepare the data that will be saved for the patient
    let req_data;
    if (user_types === "PATIENT") {
      req_data = {
        email: user.Email,
        first_name: user.FirstName,
        last_name: user.LastName,
        gender: user.Sex,
        dob: formatDate(user.DateOfBirth),
        diagnosis: user.Diagnosis,
        education: user.education,
        is_patient: user_types === "PATIENT",
        password: undefined,
      };
    } else {
      req_data = {
        email: user.Email,
        first_name: user.FirstName,
        last_name: user.LastName,
        occupation: MAPPING_OCCUPATION.find(item => item.label ===  user.Occupation)?.id || user.Occupation,
        gender: user.Sex,
        dob: moment().toISOString().split("T")[0],
        role: "GENERAL_PRACTITIONER",
        is_patient: false,
      };
    }
    return dispatch(createUser(req_data))
      .then((res) => {
        if (res && res.status === 200) {
          setSuccessImportedUser((preVal) => {
            return preVal + 1;
          });
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
  };
  const validateUser = function (user) {
    let schema = user_types === "PATIENT" ? patientSchema : practitionerSchema;
    return schema
      .validate(user, { abortEarly: false })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };
  const tryUsers = async (users) => {
    const userPromises = [];
    users.forEach((user) => {
      userPromises.push(validateUser(user));
    });
    const _users = await new Promise((resolve) => {
      const validatedUsers = Promise.all(userPromises).then((res) => {
        return res;
      });
      resolve(validatedUsers);
    });
    const __errors = [];
    for (let i = 0; i < _users.length; i++) {
      if (_users[i].name !== "ValidationError") {
        await new Promise((resolve) => {
          return addUsers(_users[i]).then((res) => {
            if (res && res.status === 200) {
              enqueueSnackbar(res.message , {variant : 'success'})
              return resolve(true);
            }else {
              setNotEnoughNF(res.message)
              setActiveStep(8)
            }
          });
        });
      } else {
        let tempVar = [];
        _users[i].inner.forEach((inn) => {
          tempVar.push({
            errors: [...inn.errors],
            path: inn.path,
            oldValue: inn.value,
          });
        });
        __errors.push({ error: tempVar, user: _users[i].value });
      }
    }
    setErrors(__errors);
    setActiveStep(6);
  };
  const handleStartImport = () => {
    const numberOfUsers = users[0].length;
    const _columns = getColumn(users, 0);
    const _users = [];
    let forValidation;
    if (user_types === "PATIENT") {
      forValidation = [
        "FirstName",
        "LastName",
        "DateOfBirth",
        "Sex",
        "Email",
        "Diagnosis",
      ];
    } else {
      forValidation = [
        "FirstName",
        "LastName",
        "Occupation",
        "Email",
        "Sex",
      ];
    }
    for (let i = 1; i < numberOfUsers; i++) {
      const tempUser = {};
      for (let j = 0; j < users.length; j++) {
        tempUser[`${forValidation[j]}`] =
          users[
            _columns.indexOf(
              user_types === "PATIENT"
                ? MAIN_COLUMN_PATIENT[j]
                : MAIN_COLUMN_PRACTITONER[j]
            )
          ][i];
      }
      _users.push(tempUser);
    }
    tryUsers(_users);
  };
  const handleNext = async (users) => {
    if (activeStep === steps.length - 1) {
      await dispatch(submitUserData());
    } else {
      if (activeStep === 6) {
        if (errors && errors.length) {
          tryUsers(users);
          setActiveStep(5);
        } else {
          setActiveStep(7);
        }
      } else if (activeStep === 4) {
        setActiveStep(5);
        handleStartImport();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 4) {
      setUsers(null);
      setActiveStep(2);
    } else {
      if (activeStep === 1) {
        setUserTypes("");
      } else if (activeStep === 2) {
        setAddingType("");
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setUserTypes("");
    setAddingType("");
    setUsers(null);
    setErrors([]);
    setActiveStep(0);
    setSuccessImportedUser(0);
    setNotEnoughNF(null)
  };

  const upgradePlanes = () => {
    history.push("/billing")
    setNotEnoughNF(null)
    setActiveStep(0)
  }

  const handleSetAddingType = (type) => {
    setAddingType(type);
  };

  const handleSetUserType = (type) => {
    setUserTypes(type);
  };
  const handleConfirmInputFields = (users) => {
    setUsers(users);
    handleNext();
  };

  const showSuccessMessage = () => {
    setActiveStep(7);
  };
  const handleAddOrUpload = () => {
    setUsers(null);
    setActiveStep(2);
    setSuccessImportedUser(0);
  };

  useEffect(() =>  {
    if(props.users) {
      const prac = props.users.filter(user => {
        var  roles = user.roles.map(function(item) {
          return item['role_name'];
        });
        return roles.includes(UserTypes[1].value2)
      })
      setPractitioner(prac)
    }
  },[props.users])
  const getStepTitle = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return "Who are you creating an account for?";
      case 1:
        return "How many users would you like to add?";
      case 2:
        return user_types === "PATIENT"
          ? addingType === "individual"
            ? "Create New Patient Profile"
            : "Upload Your File"
          : addingType === "individual"
          ? "Create New Practitioner Profile"
          : "Upload Your File";
      case 3:
        return "";
      case 4:
        return addingType !== "individual" ? "Confirm Import" : "";
      case 5:
        return "";
      case 6:
        return "Uploading Result";
      case 7:
        return "";
      case 8:
        return "Not enough Neurofit?";
      default:
        return "Unknown stepIndex";
    }
  };
  const setActiveStepNOTNF= (res) => {
    setNotEnoughNF(res?.message)
    setActiveStep(8)
  }
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <AddUserTypes onTypeChange={handleSetUserType} />;
      case 1:
        return <AddTypeStep onTypeChange={handleSetAddingType} />;
      case 2:
        return user_types === "PATIENT" ? (
          addingType === "individual" ? (
            <AddUserForm moveToFinalStep={showSuccessMessage} practitioner={practitioner} setActiveStep={setActiveStepNOTNF}/>
          ) : (
            <UploadFile isPatient setUsers={setUsers} />
          )
        ) : addingType === "individual" ? (
          <AddPractitionerUsers moveToFinalStep={showSuccessMessage} />
        ) : (
          <UploadFile setUsers={setUsers} />
        );
      case 3:
        return addingType !== "individual" ? (
          <ConfirmInputFields
            user_type={user_types}
            users={users}
            toggle={props.toggle}
            setUsers={handleConfirmInputFields}
          />
        ) : (
          ""
        );
      case 4:
        return addingType !== "individual" ? (
          <ConfirmImport
            userType={user_types}
            numberOfUsers={users[0].length ? users[0].length - 1 : 0}
          />
        ) : (
          ""
        );
      case 5:
        return addingType !== "individual" ? (
          <ImportInProgress
            numberOfUsers={users[0].length ? users[0].length - 1 : 0}
          />
        ) : (
          ""
        );
      case 6:
        return addingType !== "individual" ? (
          <UploadingResult
            userType={user_types}
            errors={errors}
            validUsersCount={successImportedUser}
            numberOfUsers={users[0].length ? users[0].length - 1 : 0}
            tryUsersAfterEdit={handleNext}
          />
        ) : (
          ""
        );
      case 7:
        return addingType !== "individual" ? (
          <SuccessComponent
            numberOfUsers={successImportedUser}
            addingType={addingType}
            handleReset={handleReset}
            handleAddOrUpload={handleAddOrUpload}
          />
        ) : (
          <SuccessComponent
            numberOfUsers={null}
            addingType={addingType}
            handleReset={handleReset}
            handleAddOrUpload={handleAddOrUpload}
          />
        );

        case 8 : 
          return  (       
            <NotEnoughNF 
              notEnoughNF = {notEnoughNF}
              handleReset = {handleReset}
              MoveToBilling = {upgradePlanes}
            />
          )
      default:
        return "Unknown stepIndex";
    }
  };
    return (
      <div className={classes.root}>
       <h2 className={addingType === "individual" && activeStep === 2 ? classes.WizardTitle_2  : classes.WizardTitle}>{getStepTitle(activeStep)}</h2>
        <Stepper
          style={{ padding: "0" }}
          activeStep={activeStep}
          className={`${
            activeStep === 0 || activeStep === 1 ? `${classes.stepper}` : ""
          }`}
          alternativeLabel
        >
          <Step key={activeStep}>{getStepContent(activeStep)}</Step>
        </Stepper>
        {activeStep !== 5 && activeStep !== 7 && activeStep !== 8 && (
          <div className={`${classes.buttons} ${addingType === "individual" && activeStep === 2 && "buttons-individual-step-3"} buttons`}>
            {activeStep !== 3 && (
              <Button
                className={`${classes.buttonCnacel}`}
                onClick={props.toggle}
                variant="outlined"
              >
                Cancel
              </Button>
            )}
            {activeStep !== 3 && activeStep !== 6 && activeStep !== 7 && (
              <div>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    style={{
                      display: `${
                        addingType === "individual" &&
                        activeStep === steps.length - 1 &&
                        !(user_types === "PATIENT") &&
                        "none"
                      }`,
                    }}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
  
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${
                      addingType !== "individual" && activeStep === 2
                        ? `${classes.btnNext}`
                        : activeStep === steps.length - 1
                        ? `${classes.btnNext}`
                        : `${classes.btnNextText}`
                    }`}
                    onClick={handleNext}
                    disabled={
                      (activeStep === 0 && user_types === "") ||
                      (addingType === "" && activeStep === 1) ||
                      (!users && addingType !== "individual" && activeStep === 2)
                    }
                  >
                    {activeStep === 4
                      ? "Start Import"
                      : activeStep === 2 && addingType === "individual"
                      ? "Add User"
                      : "Next"}
                  </Button>
                </div>
              </div>
            )}
            
          </div>
        )}
      </div>
    );
}
