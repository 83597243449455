import React, { useState, useEffect } from "react";
import {
  NavbarMobile,
  OpenIcon,
  OpenIconContainer,
  Icon,
  CloseIcon,
} from "./NavbarMobileElements";
import { Link, useHistory } from "react-router-dom";
import "./NavbarMobile.scss";
import Logo from "../../../../assets/images/neurofit-logo.svg";
import PermissionsService from "../../../../lib/PermissionsSystem";

const NavbarMobileContainer = ({ logoutUser }) => {
  const [isNavbarMobileOpen, setIsNavbarMobileOpen] = useState(false);

  const history = useHistory();

  const onSignOutClick = (e) => {
    logoutUser(e);
    history.push("/");
  };

  const setToggleOpen = () => {
    setIsNavbarMobileOpen(!isNavbarMobileOpen);
  };
  // hide scrollbar when the mobile navigation is popped out
  useEffect(() => {
    document.body.style.overflow = isNavbarMobileOpen ? "hidden" : "auto";
  }, [isNavbarMobileOpen]);

  return (
    <div style={{ position: "relative" }}>
      <OpenIconContainer>
        <OpenIcon onClick={setToggleOpen} />
      </OpenIconContainer>

      <NavbarMobile
        isNavbarMobileOpen={isNavbarMobileOpen}
        onClick={setToggleOpen}
      >
        <Icon>
          <CloseIcon
            isNavbarMobileOpen={isNavbarMobileOpen}
            onClick={setToggleOpen}
          />
        </Icon>

        <div className="navbar-mobile-links-container">
          <div className="navbar-mobile-logo">
            <Link to={"/"}>
              <img src={Logo} />
            </Link>
          </div>

          <Link
            style={{ textDecoration: "none" }}
            to={"/"}
            onClick={setToggleOpen}
          >
            <h2>Manage Users</h2>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to={"/schedule"}
            onClick={setToggleOpen}
          >
            <h2>Session Schedule</h2>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to={"/organizations"}
            onClick={setToggleOpen}
          >
            <h2>Manage Organizations</h2>
          </Link>
          {
              PermissionsService.HasPermission("admin.billing.read") && 
              <Link
              style={{ textDecoration: "none" }}
              to={"/billing"}
              onClick={setToggleOpen}
            >
              <h2>Billing Details</h2>
            </Link>
          }
         
          <h2 style={{ cursor: "pointer" }} onClick={onSignOutClick}>
            Sign Out
          </h2>
        </div>
      </NavbarMobile>
    </div>
  );
};

export default NavbarMobileContainer;
