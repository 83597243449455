import { updateUserSuccess } from "actions";
import {
  GET_USERS_BY_ORG,
  GET_USER,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CREATE_USER,
  UPDATE_USER,
  UPDATE_SELECTED_USER,
  TOGGLE_LOGIN_FOR_USERS,
  ARCHIVE_USER,
  GET_SELECTED_USER,
  SUBMIT_USER_DATA,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_LANGUAGE,
  GET_USER_ACTIVITY_STATS_REQUEST,
  GET_USER_ACTIVITY_STATS_SUCCESS,
  GET_USER_ACTIVITY_STATS_FAILURE,
  GET_PATIENT_REPORT_CONFIG_REQUEST,
  GET_PATIENT_REPORT_CONFIG_SUCCESS,
  GET_PATIENT_REPORT_CONFIG_FAILURE,
  GET_PATIENT_REPORT_CONFIG_CLEAR,
  CREATE_PATIENT_REPORT_CONFIG_REQUEST,
  CREATE_PATIENT_REPORT_CONFIG_SUCCESS,
  CREATE_PATIENT_REPORT_CONFIG_FAILURE,
  CREATE_PATIENT_REPORT_CONFIG_CLEAR,
} from "../actions/types"

const INITIAL_STATE = {
  language: "en-US",
  users: [],
  selectedUser: "",
  user: {},
  viewed_user: {},
  patient_data: {},
  hasFetched: false,
  response: {},
  userRatings: [],
  userSessions: [],
  sessionType: "",
  isArchive: false,
  include_archived: true,
  userRadarChartData: [],
  fetchingUser: false,
  fetchUserSuccess: null,
  fetchUserFailure: null,
  updatingUser: false,
  updateUserSuccess: null,
  updateUserMessage: "",
  fetchingActivityStats: {},
  fetchActivityStatsSuccess: {},
  fetchActivityStatsFailure: {},
  activityStats: {},
  activityStatsError: {},
  fetchingReportConfig: false,
  fetchReportConfigSuccess: null,
  fetchReportConfigFailure: null,
  reportConfig: null,
  reportConfigError: null,
  creatingReportConfig: false,
  reportConfigCreateSuccess: null,
  reportConfigCreateFailure: null,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      }
    case GET_USERS_BY_ORG:
      return {
        ...state,
        users: action.payload,
        user_list: action.payload,
        hasFetched: true,
        include_archived: true,
      }
    case CREATE_USER:
      return { ...state, hasFetched: true, response: action.payload }
    case UPDATE_USER:
      return { ...state, hasFetched: true }
    case UPDATE_SELECTED_USER:
      return { ...state, selectedUser: action.userEmail }
    case TOGGLE_LOGIN_FOR_USERS:
      return { ...state, response: action.payload }
    case ARCHIVE_USER:
      return {
        ...state,
        user_list: [...state.user_list].map((user) => {
          if (user.id === action.payload.user_id) {
            user.status = action.payload.status
            console.log("Updated user status")
          }
          return user
        }),
        selectedUser: action.payload.user_id,
        isArchive: false,
      }
    case GET_SELECTED_USER:
      return { ...state, selectedUser: action.id }
    case GET_USER:
      return {
        ...state,
        viewed_user: action.payload,
        viewed_patient_data: action.payload.patient_data,
      }
    case GET_USER_REQUEST:
      return {
        ...state,
        fetchingUser: true,
        fetchUserSuccess: false,
        fetchUserFailure: false,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        fetchingUser: false,
        fetchUserSuccess: true,
        fetchUserFailure: false,
        viewed_user: {
          ...action.payload,
          basicInformation: {
            id: action.payload.id,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
            dob: action.payload.dob,
            org_id: action.payload.org_id,
            diagnosis: action.payload.patient_data.diagnosis,
            fitpoints: action.payload.patient_data.fitpoints,
          },
        },
      }
    case GET_USER_FAILURE:
      return {
        ...state,
        fetchingUser: false,
        fetchUserSuccess: false,
        fetchUserFailure: true,
        fetchError: action.payload,
      }
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updatingUser: true,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false,
        updateUserSuccess: true,
        updateUserMessage: action.payload,
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updatingUser: false,
        updateUserSuccess: false,
        updateUserMessage: action.payload,
      }
    case GET_USER_ACTIVITY_STATS_REQUEST:
      return {
        ...state,
        fetchingActivityStats: { ...state.fetchingActivityStats, [action.payload.userId]: true },
        fetchActivityStatsSuccess: { ...state.fetchActivityStatsSuccess, [action.payload.userId]: false },
        fetchActivityStatsFailure: { ...state.fetchActivityStatsFailure, [action.payload.userId]: false },
      }
    case GET_USER_ACTIVITY_STATS_SUCCESS:
      return {
        ...state,
        fetchingActivityStats: { ...state.fetchingActivityStats, [action.payload.userId]: false },
        fetchActivityStatsSuccess: { ...state.fetchActivityStatsSuccess, [action.payload.userId]: true },
        fetchActivityStatsFailure: { ...state.fetchActivityStatsFailure, [action.payload.userId]: false },
        activityStats: {
          ...state.activityStats,
          [action.payload.userId]: action.payload.data,
        },
      }
    case GET_USER_ACTIVITY_STATS_FAILURE:
      return {
        ...state,
        fetchingActivityStats: { ...state.fetchingActivityStats, [action.payload.userId]: false },
        fetchActivityStatsSuccess: { ...state.fetchActivityStatsSuccess, [action.payload.userId]: false },
        fetchActivityStatsFailure: { ...state.fetchActivityStatsFailure, [action.payload.userId]: true },
        activityStatsError: {
          ...state.activityStatsError,
          [action.payload.userId]: action.payload,
        },
      }
    case GET_PATIENT_REPORT_CONFIG_REQUEST:
      return {
        ...state,
        fetchingReportConfig: true,
        fetchReportConfigSuccess: false,
        fetchReportConfigFailure: false,
      }
    case GET_PATIENT_REPORT_CONFIG_SUCCESS:
      return {
        ...state,
        fetchingReportConfig: false,
        fetchReportConfigSuccess: true,
        fetchReportConfigFailure: false,
        reportConfig: action.payload,
      }
    case GET_PATIENT_REPORT_CONFIG_FAILURE:
      return {
        ...state,
        fetchingReportConfig: false,
        fetchReportConfigSuccess: false,
        fetchReportConfigFailure: true,
        reportConfigError: action.payload,
      }
    case GET_PATIENT_REPORT_CONFIG_CLEAR:
      return {
        ...state,
        fetchingReportConfig: false,
        fetchReportConfigSuccess: false,
        fetchReportConfigFailure: false,
        reportConfigError: null,
      }
    case CREATE_PATIENT_REPORT_CONFIG_REQUEST:
      return {
        ...state,
        creatingReportConfig: true,
        reportConfigCreateSuccess: false,
        reportConfigCreateFailure: false,
      }
    case CREATE_PATIENT_REPORT_CONFIG_SUCCESS:
      return {
        ...state,
        creatingReportConfig: false,
        reportConfigCreateSuccess: true,
        reportConfigCreateFailure: false,
        reportConfig: action.payload,
      }
    case CREATE_PATIENT_REPORT_CONFIG_FAILURE:
      return {
        ...state,
        creatingReportConfig: false,
        reportConfigCreateSuccess: false,
        reportConfigCreateFailure: true,
        reportConfigError: action.payload,
      }
    case CREATE_PATIENT_REPORT_CONFIG_CLEAR:
      return {
        ...state,
        creatingReportConfig: false,
        reportConfigCreateSuccess: false,
        reportConfigCreateFailure: false,
        reportConfigError: null,
      }

    case SUBMIT_USER_DATA: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}

export default userReducer
