import { Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

class PermissionDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const parent_id = this.props.permission?.parent_id;
    const perm = this.props.permission;

    return (
      <Grid
        id={`permission-${perm.id}`}
        item
        container
        xs={12}
        direction="row"
        style={{ border: "1px solid black" }}
      >
        <Grid item container xs={12} sm={6} direction="column">
          <Grid item xs={12}>
            Name: {perm?.name}
          </Grid>
          <Grid item xs={12}>
            Parent:
            {parent_id ? (
              <a href={`permission-${parent_id}`}>
                {this.props.permissions.find((x) => x.id === parent_id)?.name ||
                  "Not Found"}
              </a>
            ) : (
              <span />
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} direction="column">
          <Grid item xs={12}>
            Description: {perm?.description}
          </Grid>
          <Grid item xs={12}>
            Requires Privileged Account? {perm?.description}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissionsReducer.permissions,
    hasFetchedPerms: state.permissionsReducer.hasFetchedPerms,
  };
};

export default connect(mapStateToProps)(PermissionDetail);
