import React, { Component } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import UsersDropdown from "../sharedComponents/UsersDropdown";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";
import RadarPlotWrapper from "./RadarPlotWrapper";
import RadarLegend from "./RadarLegend";
import ChartRangeDropdown from "../sharedComponents/ChartRangeDropdown";

import { connect } from "react-redux";
import { getRadarPlotData, getUsersByOrg } from "../../actions/";
import { Divider } from "@material-ui/core";

import { Radar } from "react-chartjs-2";

class UserFeedbackGraph extends Component {
  loadingClients = () => {
    if (this.props.users.length === 0) {
      this.props.getUsersByOrg();
      return <LoadingSpinner />;
    } else {
      return (
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "18px",
          }}
        >
          <UsersDropdown users={this.props.users} />
          <div style={{ marginTop: "24px" }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={this.props.selectedUser ? false : true}
              onClick={() => {
                this.props.getRadarPlotData(
                  this.props.selectedUser,
                  this.props.dateSelect
                );
              }}
            >
              Get Data
            </Button>
          </div>

          {/* <ChartRangeDropdown /> */}
        </Paper>
      );
    }
  };

  renderChart = () => {
    if (this.props.userChartData.length !== 0) {
      if (this.props.userChartData[0].Difficulty !== null) {
        return (
          <React.Fragment>
            <Grid item md={7} lg={7}>
              <Paper>
                <RadarPlotWrapper data={this.props.userChartData} />
              </Paper>
            </Grid>
            <Grid item md={4} lg={3} style={{ marginTop: "2.5rem" }}>
              <RadarLegend data={this.props.userChartData} />
            </Grid>
          </React.Fragment>
        );
      } else {
        return (
          <Typography variant="h4" component="h4">
            No rating data found
          </Typography>
        );
      }
    } else {
      return (
        <Typography variant="h4" component="h4">
          No rating data found
        </Typography>
      );
    }
  };

  // button

  render() {
    return (
      <Container style={{ marginTop: "72px" }} maxWidth="xl">
        <Typography
          variant="h3"
          component="h3"
          style={{ marginBottom: "24px", textAlign: "left" }}
        >
          User Mood
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
            {this.loadingClients()}
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
            {this.renderChart()}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users,
    selectedUser: state.userReducer.selectedUser,
    userChartData: state.userRadarPlotReducer.userChartData,
    dateSelect: state.userRadarPlotReducer.dateSelect,
  };
};

export default connect(mapStateToProps, { getUsersByOrg, getRadarPlotData })(
  UserFeedbackGraph
);
