import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import OrganizationsListRow from "./OrganizationsListRow"
import TableSortLabel from "@mui/material/TableSortLabel"

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
    padding: theme.spacing(1),
  },
  paper: {
    // coupled with _variables.scss gray-200
    border: "1px solid rgba(237, 236, 236, 1)",
    borderRadius: "0.75rem",
    marginBottom: theme.spacing(3),
    boxShadow: "none",
  },
  sortTableActive: {
    color: "rgba(34, 144, 209 , 0.87) !important",
  },
  wideCell: {
    minWidth: "150px",
  },
  dataDeep: {
    minWidth: "100px",
    color: "#2290D1",
  },
}))

function renderTableRows(props, order, orderBy) {
  function descendingComparator(a, b, orderBy) {
    if (b["Owner"] && a["Owner"] && b["Owner"][orderBy] < a["Owner"][orderBy]) {
      return -1
    }
    if (b["Owner"] && a["Owner"] && b["Owner"][orderBy] > a["Owner"][orderBy]) {
      return 1
    }
    return 0
  }

  function descendingComparator_1(a, b, orderBy) {
    if (b && a && b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b && a && b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    if (
      orderBy === "name" ||
      orderBy === "created_at" ||
      orderBy === "updated_at"
    ) {
      return order === "desc"
        ? (a, b) => descendingComparator_1(a, b, orderBy)
        : (a, b) => -descendingComparator_1(a, b, orderBy)
    }
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  if (Array.isArray(props.organizations) && props.organizations.length > 0) {
    let data = props.hide_inactive
      ? props.organizations.filter((x) => x.privilege !== -1)
      : props.organizations
    return data.sort(getComparator(order, orderBy)).map((row) => {
      return (
        <OrganizationsListRow
          key={row.id}
          {...row}
          dataPre={1}
          setPrivilege={props.setPrivilege}></OrganizationsListRow>
      )
    })
  } else if (props.searchString && props.organizations.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={7}>
          Your search {<b>"{props.searchString}"</b>} did not return any results.
        </TableCell>
      </TableRow>
    )
  } else if (props.organizations?.privilege === 0) {
    let data = props.organizations
    return (
      <OrganizationsListRow
        key={data.id}
        dataPre={0}
        {...data}></OrganizationsListRow>
    )
  } else {
    return (
      <TableRow>
        <TableCell colSpan={7}>Failed to load organizations</TableCell>
      </TableRow>
    )
  }
}

export default function OrganizationsList(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState("")
  const [orderBy, setOrderBy] = React.useState("")
  const [tableRows, setTableRows] = React.useState(
    renderTableRows(props, order, orderBy)
  )
  useEffect(() => {
    if (order || orderBy || props.hide_inactive !== null) {
      setTableRows(renderTableRows(props, order, orderBy))
    }
  }, [props, order, orderBy, props.hide_inactive])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  return (
    <TableContainer className={classes.paper} component={Paper}>
      <Table className={classes.table} aria-label='Organization Table'>
        <TableHead component='thead' className={classes.tableHead}>
          <TableRow component='tr'>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={createSortHandler("name")}
                className={orderBy === "name" ? classes.sortTableActive : ""}>
                ORGANIZATION
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "type"}
                direction={orderBy === "type" ? order : "asc"}
                onClick={createSortHandler("type")}
                className={orderBy === "type" ? classes.sortTableActive : ""}>
                TYPE
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "last_name"}
                direction={orderBy === "last_name" ? order : "asc"}
                onClick={createSortHandler("last_name")}
                className={
                  orderBy === "last_name" ? classes.sortTableActive : ""
                }>
                ADMIN NAME
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "email"}
                direction={orderBy === "email" ? order : "asc"}
                onClick={createSortHandler("email")}
                className={orderBy === "email" ? classes.sortTableActive : ""}>
                EMAIL
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "updated_at"}
                direction={orderBy === "updated_at" ? order : "asc"}
                onClick={createSortHandler("updated_at")}
                className={
                  orderBy === "updated_at" ? classes.sortTableActive : ""
                }>
                UPDATED
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "created_at"}
                direction={orderBy === "created_at" ? order : "asc"}
                onClick={createSortHandler("created_at")}
                className={
                  orderBy === "created_at" ? classes.sortTableActive : ""
                }>
                CREATED
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "status"}
                direction={orderBy === "status" ? order : "asc"}
                onClick={createSortHandler("status")}
                className={orderBy === "status" ? classes.sortTableActive : ""}>
                STATUS
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <TableSortLabel
                active={orderBy === "billing"}
                direction={orderBy === "billing" ? order : "asc"}
                onClick={createSortHandler("billing")}
                className={orderBy === "billing" ? classes.sortTableActive : ""}>
                BILLING
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeadCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  )
}
