import { makeStyles, withStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputBase from "@material-ui/core/InputBase"

const BootstrapInput = withStyles((theme) => ({
  root: {
    margin: 0,
    width: "100%",
  },
  input: {
    height: "3rem",
    minWidth: "12rem",
    boxSizing: "border-box",
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.gray[300]}`,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  select: {
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.gray[300]}`,
    padding: "10px 26px 10px 12px",
    height: "2rem",
  },
  label: {
    zIndex: 200,
    paddingLeft: "1rem",
    transform: "translate(0, 18px) scale(1)",
  },
}))

const SelectField = ({
  label = "Select",
  value = "",
  handleChange,
  options = [],
}) => {
  const classes = useStyles()

  return (
    <FormControl>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select value={value} onChange={handleChange} input={<BootstrapInput />}>
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {options.map((o) => (
          <MenuItem value={o.value}>{o.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectField
