import React from "react";

import store from "../../../store";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";

import {
  Divider,
  IconButton,
  Badge,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

//Testing actions for Scheduled sessions (Temporary)
import addSession from "../scheduledSessionsActions";
const localStore = store.getState();
const sessionsArray = localStore.sessions;
console.log(sessionsArray);

/* Testing Array for the session dialog element (TEMPORARY) */
const sessions = [
  ["John", "Rolando", "James"],
  ["Grant", "Godrick", "Charles"],
];

//Testing for sessions in a month (TEMPORARY)
const sessionIconTest = [1, 2, 6, 12, 13, 14, 20, 25, 28];

//Styling
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  badge: {
    color: "#4dd2ff",
  },
  sessionTitle: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

/* Simple Dialog takes  */
function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = (value) => {
    onClose(value);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.sessionTitle}>
        Sessions Overview
      </DialogTitle>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {sessions.map((value) => (
            <Grid key={value} item>
              {/* List component displays all the users per  */}
              <List>
                <ListItem>
                  <Typography className={classes.sessionTitle}>
                    {" "}
                    Session: {"Memory 1"} - {"4:00PM"}{" "}
                  </Typography>
                </ListItem>

                {value.map((user) => (
                  <ListItem
                    button
                    onClick={() => handleListItemClick(user)}
                    key={user}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary={user} />
                  </ListItem>
                ))}

                <ListItem
                  autoFocus
                  button
                  onClick={() => handleListItemClick("addAccount")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AddIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Add Patient" />
                </ListItem>

                <ListItem>
                  <TextField
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    variant="outlined"
                  />
                </ListItem>
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SessionDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(sessions[1]);
  const [invisible, setInvisible] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  //TEMPORARY TEST TO SHOW ICON ONLY WHEN THERE IS A SESSION
  const checkForSession = (value) => {
    if (sessionIconTest.find((element) => element == value)) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <IconButton
        xs={1}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        {/*Evaluates if the current day has a valid date and has a session*/}
        {props.valid && checkForSession(props.day) && (
          <Badge badgeContent={sessions.length} className={classes.badge}>
            <FitnessCenterIcon />
          </Badge>
        )}
      </IconButton>

      <SimpleDialog open={open} onClose={handleClose} />
    </div>
  );
}
