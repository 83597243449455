import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import * as queryString from "query-string";
import { validateResetToken, resetPassword, signIn } from "../../actions";

import logo from "../../assets/images/neurofit-logo.svg";

const style = (theme) => ({
  alertBox: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      validToken: false,
      password: "",
      confirmPassword: "",
      token: "",
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderFormErrors = this.renderFormErrors.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderSubmittedPage = this.renderSubmittedPage.bind(this);
    this.query = queryString.parse(this.props.location.search);
    this.state.token = this.query.token;

    this.schema = yup.object().shape({
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password should be at least 6 characters!"),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("user_password"), null], "Passwords must match"),
    });
  }

  async componentDidMount() {
    const isValid = await validateResetToken({ token: this.query.token })(
      () => {}
    );
    this.setState({
      ...this.state,
      validToken: isValid,
    });
    console.log(isValid);
  }

  renderFormErrors() {
    return this.state.errors.map((errorMessage, ix) => (
      <div key={ix}>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    ));
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      const data = {
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      };
      const validated = this.schema.validateSync(data);
      const reqData = {
        new_password: validated.password,
        token: this.state.token,
      };

      const reset = await resetPassword(reqData)(() => {});
      if (reset) {
        this.setState({
          ...this.state,
          submitted: true,
          message: "Your password has been updated, redirecting to login...",
        });
        setTimeout(async () => {
          this.props.history.push("/");
          console.log("Returning to login");
        }, 4000);
      } else {
        this.setState({
          ...this.state,
          submitted: true,
          errors: ["Failed to update password. Please try again"],
          message: "Failed to update password. Please try again.",
        });
      }
    } catch (e) {
      if (e.name === "ValidationError") {
        this.setState({
          ...this.state,
          submitted: false,
          message: undefined,
          errors: e.errors,
        });
      } else {
        console.error(e);
        this.setState({
          ...this.state,
          submitted: false,
          message: undefined,
          errors: ["An unexpected error has occurred"],
        });
      }
    }
  }

  renderForm() {
    const { classes } = this.props;
    return this.state.validToken ? (
      <React.Fragment>
        <Typography component="h2" variant="h2">
          Reset Password
        </Typography>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            type="password"
            label="New Password"
            name="password"
            autoFocus
            value={this.state.password || ""}
            onChange={(event) => {
              this.setState({ ...this.state, password: event.target.value });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            value={this.state.confirmPassword || ""}
            onChange={(event) => {
              this.setState({
                ...this.state,
                confirmPassword: event.target.value,
              });
            }}
          />
          {this.renderFormErrors()}
          <Button type="submit" variant="contained">
            Confirm
          </Button>
        </form>
      </React.Fragment>
    ) : (
      <Typography>Token is invalid or expired, please try again</Typography>
    );
  }

  renderSubmittedPage() {
    return (
      <React.Fragment>
        <Typography component="h1" variant="h1">
          {this.state.message}
        </Typography>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className="half-page-container">
        <Grid container className="half-page-grid-container">
          <Grid item sm={12} md={6} lg={6} className="half-page-primary">
            {this.state.submitted
              ? this.renderSubmittedPage()
              : this.renderForm()}
          </Grid>
          {/* neurofit logo and title */}
          <Grid item sm={12} md={6} lg={6} className="half-page-secondary">
            <img src={logo} alt="Neurofit logo" />
            <Typography component="h1" variant="h1">
              Neurofit VR
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withStyles(style)(ResetPassword));
