import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  dividerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    width: "75%",
    display: "block",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: 0,
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.gray[300],
  },
}))

const Divider = () => {
  const classes = useStyles()

  return (
    <div className={classes.dividerWrapper}>
      <div className={classes.divider}></div>
    </div>
  )
}

export default Divider