import * as actionType from "../actions/types";
import { updateObject } from "../lib/Utility";

const initialState = {
  session: false,
  error: false,
  listSession: [],
  loading: false,
  count : {
    count_session : 0 ,
    percentage_attendanc_patients : 0
  },
  dataSet :  [],
  sessions_patient_assigned: []
};

const createSessionSuccess = (state, action) => {
  return updateObject(state, { session: action.session, loading: true });
};
const createSessionFail = (state, action) => {
  return updateObject(state, { loading: true, error: action.session });
};
const fetchSession = (state, action) => {
  return updateObject(state, {
    listSession: action.session,
    loading: false,
    session: null,
    error: false,
  });
};
const editSession = (state, action) => {
  return updateObject(state, { session: action.session, loading: true });
};
const deleteSession = (state, action) => {
  return updateObject(state, { session: action.session, loading: true });
};
const recSession = (state, action) => {
  return updateObject(state, { session: action.session, loading: true });
};

const countSession = (state , action) =>  {
  return updateObject(state, { ...state , count : action.count , dataSet : action.dataSet});
}

const assignPatients = (state , action) =>  {
  return updateObject(state, { ...state , sessions_patient_assigned : action.sessions_patient_assigned});
}

const scheduledSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_SCHEULE_SESSION:
      return createSessionSuccess(state, action);
    case actionType.MESSAGE_SCHEULE_SESSION_FAILER:
      return createSessionFail(state, action);
    case actionType.FETCH_SCHEULE_SESSION:
      return fetchSession(state, action);
    case actionType.EDIT_SCHEULE_SESSION:
      return editSession(state, action);
    case actionType.DELETE_SCHEULE_SESSION:
      return deleteSession(state, action);
    case actionType.RUCURRING_SCHEULE_SESSION:
      return recSession(state, action);
    case actionType.COUNT_SESSION_FOR_ORG:
      return countSession(state, action);
    case actionType.GET_ASSIGEND_PATIENT_SESSIONS_PARCTITIONER :
      return assignPatients(state, action);
    default:
      return state;
  }
};
export default scheduledSessionsReducer;
