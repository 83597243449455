import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { PersonAdd } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "6px 1em",
    boxSizing: "border-box",
  },
  button: {
    margin: "0px 6px",
    textTransform: "none",
    backgroundColor: "#8FCAED",
    borderRadius: "5px",
    display: "flex",
    padding: "0 10px",
    width: "100%",
    height: "40px",
    color: "#333437",
    fontWeight: "700",
    fontSize: "17px",
    "&:hover": {
      backgroundColor: "#8FCAED",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function FabIcon(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab
        onClick={props.onClick}
        className={classes.button}
        variant="extended"
        aria-label="add"
      >
        {/* <PersonAdd className={classes.extendedIcon} /> */}
        {props.iconText}
      </Fab>
    </div>
  );
}
