import React from "react";
import {
  Button,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import AdminActions from "../../../actions/AdminActions";

class AddPermissionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      privilege: false,
      parent_id: null,
      description: "",
    };

    this.handleAddPermission = this.handleAddPermission.bind(this);
  }

  async handleAddPermission() {
    if (window.confirm("Are you sure you wish to add this permission?")) {
      let result = await this.props.dispatch(
        this.props.CreatePermission({
          name: this.state.name,
          privilege: this.state.privilege ? 1 : 0,
          parent_id:
            this.state.parent_id === "null" ? null : this.state.parent_id,
          description: this.state.description,
        })
      );

      if (result.status === 200) {
        this.setState({
          name: "",
          privilege: false,
          parent_id: null,
          description: "",
        });
      }
    }
  }

  render() {
    return (
      <form id="add-perm" noValidate autoComplete="off">
        <Grid container direction="column">
          <Typography variant="h4" align="center">
            Add Permission
          </Typography>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="perm-name">Name</InputLabel>
              <Input
                id="perm-name"
                value={this.state.name}
                fullWidth={true}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    name: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="perm-privileged"
                  value={this.state.privilege}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      privilege: e.target.value,
                    });
                  }}
                  fullWidth={true}
                />
              }
              label="Requires Privilege?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="perm-desc">Description</InputLabel>
              <Input
                type="text"
                id="perm-desc"
                value={this.state.description}
                fullWidth={true}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    description: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel shrink id="parent-select-label">
                Parent Permission
              </InputLabel>
              <Select
                labelId="parent-select-label"
                id="parent-select"
                onClose={(e) =>
                  this.setState({ ...this.state, menuAnchorTarget: null })
                }
                displayEmpty
                value={this.state.parent_id || "null"}
                onChange={(e) =>
                  this.setState({ ...this.state, parent_id: e.target.value })
                }
                fullWidth={true}
              >
                <MenuItem key="none" value="null">
                  None
                </MenuItem>
                {this.props.permissions.map((x) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              id="add-permission"
              onClick={this.handleAddPermission}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissionsReducer.permissions,
    hasFetchedPerms: state.permissionsReducer.hasFetchedPerms,
  };
};

export default connect(mapStateToProps, (dispatch) => {
  return {
    GetPermissionsList: AdminActions.GetPermissionsList,
    GetRoleList: AdminActions.GetRoleList,
    CreatePermission: AdminActions.CreatePermission,
    dispatch,
  };
})(AddPermissionForm);
