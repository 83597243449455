import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import individual from "../../../assets/images/individual.svg";
import multiple from "../../../assets/images/multiple.svg";

const useStyles = makeStyles({
  root: {
    width: 281,
    height: 202,
    backgroundColor: "#E2EFF9",
    "@media (max-width: 950px)": {
      margin: "18px",
    },
  },
  wizardButtonTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  img: {
    marginBottom: "0px !important",
    border: "0px !important",
  },
});

export default function AddTypeStep(props) {
  const [additionType, setAdditionType] = React.useState("additionType");
  const classes = useStyles();

  const handleChange = (type) => {
    setAdditionType(type);
    if ("onTypeChange" in props) {
      props.onTypeChange(type);
    }
  };
  return (
    <div className="row">
      <div className="col d-flex justify-content-center">
        <div>
          <h5 className={classes.wizardButtonTitle}>Individual User</h5>
          <div
            onClick={() => {
              handleChange("individual");
            }}
          >
            <Card
              className={`d-flex ${classes.root}`}
              variant={additionType === "individual" ? "outlined" : ""}
            >
              <CardActionArea>
                <img className={classes.img} src={individual} />
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
      <div className="col d-flex justify-content-center">
        <div>
          <h5 className={classes.wizardButtonTitle}>Multiple Users</h5>
          <div
            onClick={() => {
              handleChange("multiple");
            }}
          >
            <Card
              className={`d-flex ${classes.root}`}
              variant={additionType === "multiple" ? "outlined" : ""}
            >
              <CardActionArea>
                <img className={classes.img} src={multiple} />
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
