import axios from "axios";
import util from "util";
import { Debug } from "./lib";
import { SIGN_OUT } from "./actions/types";
import PermissionsService from "./lib/PermissionsSystem";

import store from "./store";

Debug.log(process.env);
const API_ROUTE =
  process.env.REACT_APP_API_ROUTE || "https://api-staging.neurofit.ca/api";

const client = axios.create({
  baseURL: API_ROUTE,
  headers: {
    "Content-type": "application/json; charset=utf-8 ",
  },
});

client.defaults.headers["Content-Type"] = "application/json; charset=utf-8";

// Middleware to load the auth token into the requests
client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      Debug.log("Attaching token!");
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Middleware to handle route refresh on 401/403 errors
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalReq = err.config;
    const refresh_route = API_ROUTE + "/user/refresh_token";

    const reject = (err) => {
      Debug.log("Refresh failed!");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      PermissionsService.Reset();
      throw err;
    };
    if (
      err.url !== refresh_route &&
      (err.response.status === 401) | (err.response.status === 403) &&
      err.config &&
      !err.config._retry
    ) {
      originalReq._retry = true;
      Debug.log("Attempting to refresh token!");
      Debug.log("token: " + localStorage.getItem("refresh_token"));
      let result = axios
        .put(refresh_route, {
          refresh_token: localStorage.getItem("refresh_token"),
        })
        .then((res) => {
          Debug.log("Got refresh, re-calling route");
          Debug.log("Headers" + util.inspect(res.headers));
          localStorage.setItem("auth_token", res.headers.authorization);
          localStorage.setItem("refresh_token", res.headers.refresh_token);
          originalReq.headers["Authorization"] = res.headers.authorization;
          return axios(originalReq);
        })
        .catch((e) => {
          Debug.log(util.inspect(err));
          const thrown = {
            config: err.config,
            request: err.request,
            response: err.response,
            isAxiosError: err.isAxiosError,
          };
          store.dispatch({
            type: SIGN_OUT,
            payload: {},
          });
          reject(thrown);
        });

      return result;
    } else if (err.url === refresh_route) {
      store.dispatch({
        type: SIGN_OUT,
        payload: {},
      });
      reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export default client;
