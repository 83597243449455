import { useState } from "react"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import InputAdornment from "@material-ui/core/InputAdornment"

const styles = (theme) => ({
  root: {
    width: "100%",
    "& svg": {
      marginTop: "1rem",
      color: theme.palette.gray[500],
      zIndex: 100,
    },
  },
  input: {
    "& input": {
      zIndex: 200,
      height: "1rem",
      padding: 0,
      marginTop: "1.5rem",
    },

    "& fieldset": {
      background: theme.palette.white,
      border: "1px solid",
      borderColor: theme.palette.gray[300],
      borderRadius: "0.5rem",
      height: "3rem",
      padding: "0.5rem 0.75rem",
      top: 0,
    },
  },
})

const TypeaheadSearch = ({ value, label, handleChange, classes }) => {
  const [shrink, setShrink] = useState(false)

  return (
    <div>
      <TextField
        key={`search-${label}`}
        label={label}
        variant='outlined'
        InputLabelProps={{
          key: "label-search",
          shrink: shrink,
          style: {
            textOverflow: "ellipsis",
            marginLeft: "2rem",
            whiteSpace: "nowrap",
            height: "1rem",
            width: "calc(100% - 3rem)",
            overflowX: "hidden",
            overflowY: "clip",
          },
        }}
        onFocus={() => setShrink(true)}
        onBlur={(e) => setShrink(!!e.target.value)}
        onChange={handleChange}
        className={classes.root}
        InputProps={{
          type: "search",
          className: classes.input,
          value: value,
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

export default withStyles(styles)(TypeaheadSearch)
