import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useForm from "./useForm";
import {
  Typography,
  Button,
  Grid,
  Checkbox,
  TextField,
  OutlinedInput,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import BlockSharpIcon from "@material-ui/icons/BlockSharp";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles({
  mainContainer: {
    display: "grid",
    justifyContent: "center",
    position: "relative",
    zIndex: 5,
  },

  formContainer: {
    position: "relative",
    width: "28.125rem",
    height: "auto",
    padding: "2rem",
  },
  inputField: {
    width: "100%",
    marginBottom: "1rem",
  },

  btn: {
    width: "100%",
    height: "3rem",
    background: "red",
    color: "#fff",
    "&:hover": {
      color: "red",
      opacity: ".7",
      transition: ".3s eases-in-out ",
    },
  },
  disabledBtn: {
    background: "rbga(0,0,0, 0.38)",
    height: "3rem",
    width: "100%",
  },
});

const StepOne = ({
  activeStep,
  steps,
  callbackHandleNext,
  checkoutData,
  callbackSetCheckoutData,
}) => {
  // DEFINE THE STATE SCHEMA
  const stateSchema = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    email: { value: "", error: "" },
    gender: { value: "", error: "" },
    pronouns: { value: "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    firstName: {
      required: true,
      validator: {
        func: (value) =>
          /^([A-Za-z][A-Za-z'-])+([A-Za-z][A-Za-z'-]+)*/.test(value),
        error: "First Name must be more then 1 character",
      },
    },
    lastName: {
      required: true,
      validator: {
        func: (value) =>
          /^([A-Za-z][A-Za-z'-])+([A-Za-z][A-Za-z'-]+)*/.test(value),
        error: "Last Name must be more then 1 character",
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) =>
          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
            value
          ),
        error: "Invalid email format",
      },
    },
    password: {
      required: true,
      validator: {
        func: (value) =>
          /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/.test(value),
        error:
          "Minimum 6 character and at least one speacial character `@,$,!,%,*,#,?,&`",
      },
    },
  };

  const { values, errors, dirty, handleOnChange } = useForm(
    stateSchema,
    stateValidatorSchema
  );
  const [showPasswordValue, setShowPasswordValue] = useState({
    showPassword: false,
  });
  const [showConfirmPasswordValue, setShowConfirmPasswordValue] = useState({
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setShowPasswordValue({
      showPassword: !showPasswordValue.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPasswordValue({
      showConfirmPassword: !showConfirmPasswordValue.showConfirmPassword,
    });
  };

  const {
    firstName,
    lastName,
    email,
    gender,
    pronouns,
    password,
    confirmPassword,
  } = values;
  const classes = useStyles();
  const [value, setValue] = useState("");

  const handleClick = (e) => {
    console.log(values);
    let newArr = [...checkoutData];
    newArr[0] = values;
    callbackSetCheckoutData(newArr);
    callbackHandleNext();
  };

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h5" style={{ color: "#999", textAlign: "center" }}>
        Sign up with Email
      </Typography>
      <div className={classes.formContainer}>
        <form>
          <TextField
            className={classes.inputField}
            style={{ width: "100%", margin: "1rem 0" }}
            label="First Name"
            variant="outlined"
            name="firstName"
            value={firstName}
            onChange={handleOnChange}
          />
          {errors.firstName && dirty.firstName && (
            <Typography
              style={{ marginTop: "0", color: "red", fontWeight: "200" }}
            >
              {errors.firstName}
            </Typography>
          )}

          <TextField
            className={classes.inputField}
            style={{ width: "100%", margin: "1rem 0" }}
            label="Last Name"
            variant="outlined"
            name="lastName"
            value={lastName}
            onChange={handleOnChange}
          />

          {errors.lastName && dirty.lastName && (
            <Typography
              style={{ marginTop: "0", color: "red", fontWeight: "200" }}
            >
              {errors.lastName}
            </Typography>
          )}
          <PhoneInput
            className={classes.inputField}
            style={{ width: "100%", margin: "1rem 0" }}
            placeholder="+1 416-555-5555"
            value={value}
            onChange={setValue}
          />

          <TextField
            className={classes.inputField}
            style={{ width: "100%", margin: "1rem 0" }}
            label="Email"
            variant="outlined"
            name="email"
            value={email}
            onChange={handleOnChange}
          />
          {errors.email && dirty.email && (
            <Typography
              style={{ marginTop: "0", color: "red", fontWeight: "200" }}
            >
              {errors.email}
            </Typography>
          )}

          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              className={classes.inputField}
              aria-label="gender"
              defaultValue="female"
              name="gender"
              value={gender}
              onChange={handleOnChange}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>

          <FormControl className={classes.inputField} component="fieldset">
            <FormLabel component="legend">Preferred Pronouns</FormLabel>
            <RadioGroup
              aria-label="pronouns"
              defaultValue="her"
              name="pronouns"
              value={pronouns}
              onChange={handleOnChange}
            >
              <FormControlLabel value="he" control={<Radio />} label="He" />
              <FormControlLabel value="she" control={<Radio />} label="She" />
              <FormControlLabel value="they" control={<Radio />} label="They" />
            </RadioGroup>
          </FormControl>
          <FormControl className={classes.inputField} variant="outlined">
            <InputLabel> Password</InputLabel>
            <OutlinedInput
              labelWidth={80}
              name="password"
              value={password}
              type={showPasswordValue.showPassword ? "text" : "password"}
              onChange={handleOnChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClickShowPassword}>
                    {showPasswordValue.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />

            {errors.password && dirty.password && (
              <Typography
                style={{ marginTop: "0", color: "red", fontWeight: "200" }}
              >
                {errors.password}
              </Typography>
            )}
          </FormControl>

          <FormControl className={classes.inputField} variant="outlined">
            <InputLabel> Confirm Password</InputLabel>
            <OutlinedInput
              labelWidth={135}
              type={showPasswordValue.showPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleOnChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleClickShowConfirmPassword}
                  >
                    {showConfirmPasswordValue.showConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />

            {confirmPassword !== password ? (
              <Typography
                style={{ marginTop: "0", color: "red", fontWeight: "200" }}
              >
                Password fo not match.
              </Typography>
            ) : null}
          </FormControl>

          {!firstName ||
          !lastName ||
          !email ||
          !password ||
          !confirmPassword ||
          confirmPassword !== password ? (
            <Button
              className={classes.disabledBtn}
              variant="contained"
              diabled
              endIcon={<BlockSharpIcon />}
            >
              {activeStep === steps.length ? "Finish" : "SIGN UP"}
            </Button>
          ) : (
            <Button
              className={classes.btn}
              variant="contained"
              onClick={handleClick}
              endIcon={<SendSharpIcon />}
            >
              {/* {activeStep === steps.length ? "Finish" : "Next"} */}
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default StepOne;
