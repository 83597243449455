export function formatDate(string) {
  var options = { year: "numeric", month: "numeric", day: "numeric" };
  return new Date(string).toLocaleDateString([], options);
}
export const getColumn = (rows, col) => {
  const column = [];
  for (var i = 0; i < rows.length; i++) {
    if (
      formatDate(rows[i][col]) === "Invalid Date" ||
      i === 0 ||
      rows[i][col] === null ||
      typeof rows[i][col] == "number"
    ) {
      column.push(rows[i][col] === null ? "" : rows[i][col]);
    } else {
      column.push(formatDate(rows[i][col]));
    }
  }
  return column;
};