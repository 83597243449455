import { Debug, GetLocalStorageValueSafe } from ".";
import React from "react";

class PermissionsService {
  static _perms;
  static _user;

  static _Hydrate() {
    Debug.log("Hydrating permissions");
    PermissionsService._perms = JSON.parse(
      GetLocalStorageValueSafe("permissions_list") || "[]"
    );
    PermissionsService._user = JSON.parse(
      GetLocalStorageValueSafe("user_data") || "{}"
    );
  }

  static SetPermissions(permissions) {
    PermissionsService._perms = permissions;
  }
  static SetUserBlob(user) {
    PermissionsService._user = user;
  }

  static GetPermissions() {
    PermissionsService._perms = PermissionsService._perms || [];
    return PermissionsService._perms;
  }

  static HasPermission(perm) {
    PermissionsService._perms = PermissionsService._perms || [];
    return PermissionsService._perms.includes(perm);
  }

  static GetUserID() {
    PermissionsService._user = PermissionsService._user || {};
    return PermissionsService._user.id;
  }

  static GetUser() {
    PermissionsService._user = PermissionsService._user || {};
    return PermissionsService._user;
  }

  static Reset() {
    PermissionsService._perms = [];
    PermissionsService._user = undefined;
  }
}

export default PermissionsService;
