import React, { Component } from "react";

import Container from "@material-ui/core/Container";
import OrganizationsList from "./OrganizationsList";
import AddOrganizationForm from "./AddOrganizationForm";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import AdminActions from "../../actions/AdminActions";
import {
  Checkbox,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import ActiveUsersChart from "./ActiveUsersChart";
import DateRangePickers from "../sharedComponents/DateRangePickers";
import moment from "moment";

import store from "../../store";
import AlignedLabel from "../sharedComponents/AlignedLabel";

import AddPermissionForm from "./permissions/AddPermForm";
import PermissionsDetail from "./permissions/PermissionsDetail";
import MaterialTable from "material-table";

class PermissionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add_perm: {
        name: "",
        privilege: false,
        parent_id: null,
      },
    };
  }

  componentDidMount() {
    AdminActions.GetPermissionsList()(this.props.dispatch);
    AdminActions.GetRoleList()(this.props.dispatch);
  }

  render() {
    const permissions_grid_data = {
      columns: [
        { field: "name", title: "Name", editable: "onAdd" },
        { field: "description", title: "Description" },
        {
          field: "privilege_req",
          title: "Privilege",
          render: (row) => {
            return <Checkbox checked={row.privilege_req > 0} disabled />;
          },
          editComponent: (props) => (
            <Checkbox
              checked={props.value > 0}
              onChange={(e) => {
                props.onChange(e.target.checked ? 1 : 0);
              }}
            />
          ),
        },
        { field: "parent_permission", title: "Parent" },
      ],
      options: {
        search: true,
        headerStyle: {
          textAlign: "left",
          minWidth: "100px",
          position: "sticky",
          top: 0,
          backgroundColor: "#D0EEFB",
          color: "#555",
        },
        paging: true,
        pageSize: 10,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [10, 25, 50, 100],
        tableLayout: "auto",
      },
    };

    const permissions_editing_data = {
      onRowUpdate: (newData, oldData) => {
        return AdminActions.UpdatePermission({
          id: oldData.id,
          privilege_req: newData.privilege_req,
          description: newData.description,
          parent_id: newData.parent_id,
        })(this.props.dispatch);
      },
    };

    const permissions = this.props.hasFetchedPerms ? (
      <MaterialTable
        {...permissions_grid_data}
        data={this.props.permissions}
        editable={permissions_editing_data}
      />
    ) : (
      <LoadingSpinner />
    );

    const roles = this.props.hasFetchedRoles ? (
      this.props.roles.map((role) => {
        return (
          <Grid
            key={"role_" + role.id}
            item
            container
            xs={12}
            style={{ border: "1px solid black" }}
          >
            <Grid item xs={12}>
              {role.name}
            </Grid>
            {role.permissions.map((x) => {
              return (
                <Grid key={`role_${role.id}_p${x.id}`} item xs={12}>
                  {x.name}
                </Grid>
              );
            })}
          </Grid>
        );
      })
    ) : (
      <LoadingSpinner />
    );

    return (
      <Container maxWidth="lg" style={{ marginTop: "24px" }}>
        <Grid container direction="column">
          <Grid
            item
            container
            xs={12}
            md={6}
            direction="column"
            style={{
              border: "1px solid black",
              borderRadius: "4px",
              padding: "0.25em",
            }}
          >
            <AddPermissionForm />
          </Grid>
          <hr />
          <Grid item container xs={12} direction="column">
            <Typography>
              Found {this.props.permission_count} permissions
            </Typography>
            {permissions}
            <div>{this.props.hasFetchedPerms ? "Y" : "N"}</div>
            {roles}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissionsReducer.permissions,
    hasFetchedPerms: state.permissionsReducer.hasFetchedPerms,
    roles: state.permissionsReducer.roles,
    hasFetchedRoles: state.permissionsReducer.hasFetchedRoles,
    permission_count: state.permissionsReducer.permission_count,
  };
};

export default connect(mapStateToProps, (dispatch) => {
  return {
    GetPermissionsList: AdminActions.GetPermissionsList,
    GetRoleList: AdminActions.GetRoleList,
    UpdatePermission: AdminActions.UpdatePermission,
    dispatch,
  };
})(PermissionsPage);
