import React from "react";
import { useForm } from "react-hook-form";
import AddIcon from "../sharedComponents/AddIcon";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Alert from "@material-ui/lab/Alert";
import FormLabel from "@material-ui/core/FormLabel";
import { Grid } from "@material-ui/core";

import * as yup from "yup";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  paper: {
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "800px",
    backgroundColor: "#fdfdfd",
    padding: theme.spacing(6),
    border: `3px solid ${theme.palette.primary.main}`,
    margin: "auto",
    position: "fixed",
    top: theme.spacing(8),
    bottom: theme.spacing(8),
    right: 0,
    left: 0,
    overflowY: "scroll",
  },
  addBtn: {
    marginTop: theme.spacing(1),
    backgroundColor: "#8FCAED",
    fontSize: "16px",
    textTransform: "capitalize",
    color: "#333437",
    "&:hover": {
      backgroundColor: "#8FCAED",
    },
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
}));

const schema = yup.object().shape({
  org_name: yup.string().required(),
  user_email: yup.string().email(),
  user_password: yup
    .string()
    .required()
    .min(8, "Password should be at least 6 characters!"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("user_password")], "Passwords must match"),
  email: yup.string().email(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
});

export default function AddOrganizationForm(props) {
  const classes = useStyles();

  const { register, handleSubmit, setValue, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });


  const open = useSelector((state) => state.modalReducer.modalProps.open);
  const responseMessage = useSelector(
    (state) => state.organizationReducer.response
  );

  const handleOpen = () => {
    props.showModal({
      modalProps: {
        open: true,
      },
      modalType: "addOrganizationModal",
    });
  };

  const handleClose = () => props.hideModal();

  const onSubmit = async (data) => {

    const user_data = {
      email: data.user_email.trim(),
      password: data.user_password,
      first_name: data.first_name.trim(),
      last_name: data.last_name.trim(),
    };

    const org_data = {
      name: data.org_name,
    };

    const response = await props.registerOrganization({
      user: user_data,
      org: org_data,
    });

    if (response.status === 200) {
      reset();
      setTimeout(props.hideModal, 2000);
    }
  };

  const renderResponseMessage = () => {
    if (responseMessage.status === 200) {
      return (
        <div className={classes.messageContainer}>
          <Alert color="success" variant="standard">
            {responseMessage.message}
          </Alert>
        </div>
      );
    } else if (responseMessage.status === 400) {
      return (
        <div className={classes.messageContainer}>
          <Alert severity="error">{responseMessage.message}</Alert>
        </div>
      );
    }
  };

  const renderFormError = (errorMessage) => {
    return (
      <div className={classes.messageContainer}>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    );
  };

  const body = (
    <div className={classes.paper}>
      <Typography className={classes.heading} component="h1" variant="h5">
        Create an Organization
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="org_name"
              label="Name of Organization"
              name="org_name"
              autoFocus
              inputRef={register}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FormLabel>Admin User Account Settings</FormLabel>
          </Grid>
          {/* first name */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth={false}
              id="first_name"
              label="First Name"
              name="first_name"
              autoFocus
              inputRef={register}
              className={classes.inlineInput}
            />
          </Grid>
          {/* last name */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth={false}
              id="last_name"
              label="Last Name"
              name="last_name"
              autoFocus
              inputRef={register}
              className={classes.inlineInput}
            />
          </Grid>

          {/* email */}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user_email"
              label="Email"
              name="user_email"
              autoFocus
              inputRef={register}
              // InputProps={{
              //     startAdornment:(
              //         <InputAdornment position="start"><MailOutline/></InputAdornment>
              //     )
              // }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user_password"
              label="Password"
              name="user_password"
              type="password"
              autoFocus
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="passwordConfirmation"
              label="Confirm Password"
              name="passwordConfirmation"
              type="password"
              autoFocus
              inputRef={register}
            />
          </Grid>

          {/* date of birth 
          <Grid item xs={12} sm={12} md={6}>
            <Input
              type="hidden"
              name="dob"
              id="dob"
              inputRef={register}
              value={moment(date).toISOString()}
            ></Input>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="dialog"
                format="MM/dd/yyyy"
                margin="normal"
                label="Date of Birth"
                fullWidth
                value={date}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          
          <FormControl component="fieldset" className={classes.radioContainer}>
            <Grid item container xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender" required>
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio inputRef={register} required color="primary" />
                    }
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={
                      <Radio inputRef={register} required color="primary" />
                    }
                    label="Male"
                  />
                </RadioGroup>
              </Grid>

              {/*<Grid item xs={12} sm={12} md={6}>
                <FormLabel component="legend">Pronouns</FormLabel>
                <RadioGroup aria-label="pronouns" name="pronouns" required>
                  <FormControlLabel
                    value="masculine"
                    control={
                      <Radio inputRef={register} required color="primary" />
                    }
                    label="He/Him/His"
                  />
                  <FormControlLabel
                    value="feminine"
                    control={
                      <Radio inputRef={register} required color="primary" />
                    }
                    label="She/Her/Hers"
                  />
                  <FormControlLabel
                    value="neuter"
                    control={
                      <Radio inputRef={register} required color="primary" />
                    }
                    label="They/Them/Theirs"
                  />
                </RadioGroup>
                  </Grid>
            </Grid>
        </FormControl>
        */}
          <Grid item xs={12} sm={12} md={12}>
            <Button
              type="submit"
              variant="contained"
              className={classes.addBtn}
            >
              Add Organization
            </Button>
          </Grid>
        </Grid>
      </form>
      {renderResponseMessage()}
      {errors.email && renderFormError(errors.email.message)}
      {errors.username && renderFormError(errors.username.message)}
      {errors.password && renderFormError(errors.password.message)}
      {errors.passwordConfirmation &&
        renderFormError(errors.passwordConfirmation.message)}
    </div>
  );

  return (
    <div>
      <span onClick={handleOpen}>
        <AddIcon iconText="+ Create Organization"></AddIcon>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="register organization"
        aria-describedby="register organization"
      >
        {body}
      </Modal>
    </div>
  );
}
