import moment from "moment";
import {
  UPDATE_START_DATE_SELECT,
  UPDATE_END_DATE_SELECT,
  TOGGLE_MEMORY_LINE,
  TOGGLE_ATTENTION_LINE,
  TOGGLE_PROCESSING_LINE,
  GET_GRAPH_DATA,
} from "../actions/types";

const INITIAL_STATE = {
  startDateSelect: moment().subtract(1, "month").toISOString(),
  endDateSelect: new Date().toISOString(),
  graphData: null,
  attentionLineToggle: true,
  memoryLineToggle: true,
  processingLineToggle: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_START_DATE_SELECT:
      return { ...state, startDateSelect: action.date };
    case UPDATE_END_DATE_SELECT:
      return { ...state, endDateSelect: action.date };
    case TOGGLE_MEMORY_LINE:
      return { ...state, memoryLineToggle: action.value };
    case TOGGLE_PROCESSING_LINE:
      return { ...state, processingLineToggle: action.value };
    case TOGGLE_ATTENTION_LINE:
      return { ...state, attentionLineToggle: action.value };
    case GET_GRAPH_DATA:
      return { ...state, graphData: action.payload };
    default:
      return state;
  }
};
