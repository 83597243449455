import React, { useState } from "react";
import useForm from "./useCreditForm";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./StepThree.css";
import "react-credit-cards/es/styles-compiled.css";
import StripeCheckout from "react-stripe-checkout";

const StepThree = ({ callbackHandleToken, checkoutData }) => {
  // const { handleChange, handleFocus, handleSubmit, values, errors } = useForm();

  // billing_when should be a dynamic date when payment succeeds => for now just a plain text

  return (
    <div className="p-5 d-flex flex-column align-items-center">
      {/* <pre>{JSON.stringify(checkoutData,null,2)}</pre> */}
      <section className="text-left mb-5 w-100 p-2 card">
        <p>First Name: {checkoutData[0].firstName}</p>
        <p>Last Name: {checkoutData[0].lastName}</p>
        <p>Email: {checkoutData[0].email}</p>
        {/* <p>Plan: {checkoutData[1].title}</p> */}
        {checkoutData[1].is_yearly ? (
          <p>
            Thank you! We have confirmed your option of{" "}
            <b>{checkoutData[1].title} </b>
            starting at{" "}
            <b>
              ${checkoutData[1].pricing_anually_usd * checkoutData[1].starting}
              /year
            </b>
            . <br />
            <span className="billing_when">Yearly billing suggestion..?</span>
          </p>
        ) : (
          <p>
            Thank you! We have confirmed your option of{" "}
            <b>{checkoutData[1].title} </b>
            starting at{" "}
            <b>
              ${checkoutData[1].pricing_monthly_usd * checkoutData[1].starting}
              /month
            </b>
            .<br />
            <span className="billing_when">
              Don't worry! You won't be charged until the end of the month.
            </span>
          </p>
        )}
      </section>

      <StripeCheckout
        stripeKey="pk_test_51JRiXRBWsdetEdR0TsnTWija6pZV4oGzyaEk5S09upE3J2IvYZ2BRO5ssSrBxJXBONPyGDdrzAIT342dIQSRVbGg00Dw51jbpe"
        token={callbackHandleToken}
      />
    </div>
  );
};

export default StepThree;
