import React from "react";
import {
  CssBaseline,
  Card,
  Typography,
  Divider,
  makeStyles,
  CardContent,
  CardActions,
  Button,
  Badge,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

//Prompts the dialog box showing list of sessions with users
import SessionDialog from "./SessionDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "150px",
  },
  paper: {
    width: "150px",
    length: "10%",
  },
  badge: {
    color: "#4dd2ff",
  },
  divider: {
    paddingTop: "5px",
  },
}));

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/* Day function component holds the elements for the individual day.*/
export default function Day(props) {
  const classes = useStyles();

  let day = props.day;
  let month = props.month;
  let year = props.year;

  let days = function (month, year) {
    return new Date(year, month + 1, 0).getDate();
  };

  //Determines if the current date is valid
  const isValid = (day, month, year) => {
    return day && day <= days(month, year) ? true : false;
  };

  return (
    <div>
      <CssBaseline />

      {/* Card holds the individual content for the day*/}
      <Card className={classes.root} variant="outlined">
        <CardContent className={classes.paper}>
          <Typography>
            {/* Evaluates if a non-zero day is provided, and if the value is within the range allowed for that month */}
            {isValid(day, month, year) ? Months[month] : "-"}{" "}
            {isValid(day, month, year) ? day : " "}
          </Typography>

          <Divider className={classes.divider} />
        </CardContent>
        <CardActions>
          {/* Prompts the dialog box showing information on session tag, users, and notes */}
          {<SessionDialog valid={isValid(day, month, year)} day={day} />}
        </CardActions>
      </Card>
    </div>
  );
}
