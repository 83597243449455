import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Input,
  InputLabel,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import JoditEditor from "jodit-react";
// https://xdsoft.net/jodit/doc/
import { connect } from "react-redux";

import NotesActions from "../../../actions/NotesActions";
import Editor from "rich-markdown-editor";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Label, Save, ShowChart } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import PermissionsService from "../../../lib/PermissionsSystem";
import { Debug } from "../../../lib/index";

class PatientNotes extends React.Component {
  constructor(props) {
    super(props);

    this.onTextChanged = this.onTextChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.filterNotes = this.filterNotes.bind(this);
    this.handleFilterChanged = this.handleFilterChanged.bind(this);
    this.onCreateNewNote = this.onCreateNewNote.bind(this);
    this.noteDateChanged = this.noteDateChanged.bind(this);
    this.goToData = this.goToData.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.onDeleteModalClose = this.onDeleteModalClose.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);

    this.state = {
      note: "",
      start_date: undefined,
      end_date: undefined,
      note_date: moment(), // LOCAL TIME
      note_id: undefined,
      creator_first_name: undefined,
      creator_last_name: undefined,
      creator_id: undefined,
      can_edit_note: PermissionsService.HasPermission(
        "org.admin.patients.notes.create"
      ),
      can_delete_note: false,
      is_editing: true,
      delete_modal_open: false,
      message: <div />,
      filtered_notes: this.props.patient_notes,
      note_data_callback: () => {
        return "";
      },
    };
  }

  componentDidMount() {
    if (this.props.user_id) {
      NotesActions.GetNotes(this.props.user_id)(this.props.dispatch);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.patient_notes !== prevProps.patient_notes) |
      (this.props.patient_notes?.length != prevProps.patient_notes?.length)
    ) {
      Debug.log("Refresh in order!");
      this.setState({
        ...this.state,
        filtered_notes: this.filterNotes(this.state.filter),
      });
    }

    if (prevProps.user_id != this.props.user_id) {
      NotesActions.GetNotes(this.props.user_id)(this.props.dispatch);
    }
  }

  async deleteNote() {
    let result = await NotesActions.DeleteNote(
      this.props.user.id,
      this.state.note_id
    )(this.props.dispatch);

    if (result.status == 200) {
      this.setState({
        ...this.state,
        delete_modal_open: false,
        message: (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success">Note has been deleted</Alert>
          </Snackbar>
        ),
      });
      this.onCreateNewNote();
    } else {
      this.setState({
        ...this.state,
        delete_modal_open: false,
        message: (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="error">
              Failed to delete note {result.data?.message}
            </Alert>
          </Snackbar>
        ),
      });
    }
  }

  filterNotes(text) {
    const filter = text?.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") || "";
    if (/\s/g.test(filter)) {
      return this.props.patient_notes;
    } else {
      const regex = new RegExp(filter, "i");
      console.log("filter: " + filter);
      return this.props.patient_notes.filter((x) => {
        return (
          regex.test(x.note) ||
          regex.test(x.admin.first_name) ||
          regex.test(x.admin.last_name) ||
          regex.test(
            moment.utc(x.note_date).local().format("MMM D, YYYY h:mmA")
          )
        );
      });
    }
  }

  onTextChanged(contentCallback) {
    this.setState({
      ...this.state,
      note_data_callback: contentCallback,
    });
  }

  onCreateNewNote() {
    this.setState({
      ...this.state,
      note: " ",
      note_date: moment(),
      note_id: undefined,
      creator_first_name: undefined,
      creator_last_name: undefined,
      creator_id: undefined,
      can_edit_note: true,
      is_editing: PermissionsService.HasPermission(
        "org.admin.patients.notes.create"
      ),
      message: <div />,
    });
  }

  async handleFilterChanged(e) {
    let filter = e.target.value;
    this.setState({
      ...this.state,
      filter,
      filtered_notes: this.filterNotes(filter),
    });
  }

  goToData() {
    if (this.props.jumpToDate) {
      this.props.jumpToDate(moment(this.state.note_date).utc(true));
    }
  }

  noteDateChanged(newDate) {
    this.setState({
      ...this.state,
      note_date: newDate,
    });
  }

  onDeleteModalClose() {
    this.setState({
      ...this.state,
      delete_modal_open: false,
    });
  }

  openDeleteModal() {
    this.setState({
      ...this.state,
      delete_modal_open: true,
    });
  }

  async handleNoteChanged(note_id) {
    let note = this.props.patient_notes?.find((x) => x.id === note_id);
    if (note) {
      const can_edit = !PermissionsService.HasPermission(
        "org.admin.patients.notes.update"
      )
        ? false
        : note.admin.id != PermissionsService.GetUserID()
        ? PermissionsService.HasPermission(
            "org.admin.patients.notes.update.others"
          )
        : true;
      const can_delete_note = PermissionsService.HasPermission(
        "org.admin.patients.notes.delete"
      )
        ? note.admin.id != PermissionsService.GetUserID()
          ? PermissionsService.HasPermission(
              "org.admin.patients.notes.delete.others"
            )
          : true
        : false;
      this.setState({
        ...this.state,
        note: note.note,
        note_id: note.id,
        note_date: moment.utc(note.note_date).local(),
        can_edit_note: can_edit,
        can_delete_note: can_delete_note,
        creator_first_name: note.admin.first_name,
        creator_last_name: note.admin.last_name,
        creator_id: note.admin.id,
        is_editing: false,
      });
    }
  }

  async handleSave() {
    if (this.state.note_id) {
      let result = await NotesActions.UpdateNote(
        this.props.user.id,
        this.state.note_id,
        {
          note: this.state.note_data_callback(),
          note_date: moment(this.state.note_date).utc().toISOString(),
        }
      )(this.props.dispatch);

      if (result.status == 200) {
        this.setState({
          ...this.state,
          is_editing: false,
          can_edit: true,
          note_id: result.data?.note?.id,
          message: (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="success">Updated note</Alert>
            </Snackbar>
          ),
        });
      } else {
        this.setState({
          ...this.state,
          message: (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="error">
                Failed to update note {result.data?.message}
              </Alert>
            </Snackbar>
          ),
        });
      }
    }
    // We are creating a new note
    else {
      let result = await NotesActions.CreateNote(this.props.user.id, {
        note: this.state.note_data_callback(),
        note_date: moment(this.state.note_date).utc().toISOString(),
      })(this.props.dispatch);

      if (result.status == 200) {
        this.setState({
          message: (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="success">Created new note</Alert>
            </Snackbar>
          ),
        });
        this.handleNoteChanged(result.data.note.id);
      } else {
        this.setState({
          ...this.state,
          message: (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="error">
                Failed to create note {result.data?.message}
              </Alert>
            </Snackbar>
          ),
        });
      }
    }
  }

  render() {
    const editorOptions = {
      width: "100%",
      minHeight: "400px",
    };
    const padding = {
      padding: "2px 4px",
    };

    const button = this.state.is_editing ? (
      <Button onClick={this.handleSave} variant="contained" color="primary">
        <SaveIcon />
        {this.state.note_id ? "Update" : "Save"}
      </Button>
    ) : !this.state.is_editing & this.state.can_edit_note ? (
      <Button
        onClick={() => {
          this.setState({ ...this.state, is_editing: true });
        }}
        variant="contained"
        color="primary"
      >
        <EditIcon />
        Edit
      </Button>
    ) : (
      <div />
    );
    const deleteButton =
      !this.state.is_editing && this.state.can_delete_note ? (
        <Button
          onClick={this.openDeleteModal}
          variant="contained"
          color="secondary"
        >
          <DeleteForeverIcon />
          Delete
        </Button>
      ) : (
        <div />
      );

    const creator_name =
      (this.state.creator_first_name ||
        PermissionsService.GetUser().first_name) +
      " " +
      (this.state.creator_last_name || PermissionsService.GetUser().last_name);

    return (
      <Container style={{ width: "100%", padding: "0 8px" }} maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            style={{ ...padding, position: "relative" }}
          >
            <Input
              type="text"
              placeholder="Enter search query..."
              fullWidth={true}
              onChange={this.handleFilterChanged}
            />
            <Grid
              item
              container
              xs={12}
              alignContent="flex-start"
              style={{ overflowY: "scroll", bottom: "48px" }}
            >
              <div style={{ display: "none" }}>{this.props.note_count}</div>
              {this.state.filtered_notes?.map((note) => {
                const date = moment
                  .utc(note.note_date)
                  .local()
                  .format("MMM D, YYYY h:mmA");
                return (
                  <Grid item xs={12} key={`${note.id}`}>
                    <div>
                      <a
                        href="#"
                        aria-label={`Note on ${date}`}
                        onClick={(e) => {
                          this.handleNoteChanged(note.id);
                          e.preventDefault();
                        }}
                      >
                        {date}
                      </a>
                    </div>
                  </Grid>
                );
              }) || <div />}
            </Grid>
            {PermissionsService.HasPermission(
              "org.admin.patients.notes.create"
            ) && (
              <Tooltip title="Create a new note" aria-label="Create a new note">
                <div
                  style={{
                    display: "block",
                    position: "absolute",
                    bottom: "0px",
                    right: "4px",
                  }}
                >
                  <a
                    href="#"
                    aria-label="Add new note"
                    onClick={(e) => {
                      this.onCreateNewNote(e);
                      e.preventDefault();
                    }}
                  >
                    <AddIcon />
                  </a>
                </div>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={8}
            md={10}
            style={{ ...padding, borderLeft: "1px solid black" }}
          >
            <Grid item xs={12}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <InputLabel>Note Date:</InputLabel>
                <DateTimePicker
                  id="#note_date"
                  value={this.state.note_date}
                  onChange={this.noteDateChanged}
                  readOnly={!this.state.is_editing}
                />
              </MuiPickersUtilsProvider>
              <Tooltip
                title="Jump to data for this day"
                aria-label="Jump to data for this day"
              >
                <Button
                  onClick={this.goToData}
                  aria-label="Jump to data for this note"
                >
                  <ShowChart />
                </Button>
              </Tooltip>
              <Tooltip
                title={`This note was made by ${creator_name}`}
                aria-label={`This note was made by ${creator_name}`}
              >
                <a
                  href={`/user-profile/${
                    this.state.creator_id || PermissionsService.GetUserID()
                  }`}
                >
                  {creator_name}
                </a>
              </Tooltip>
              <hr />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                minHeight: "400px",
                overflow: "scroll",
                paddingLeft: "26px",
              }}
            >
              <Editor
                placeholder="Enter patient notes..."
                onChange={this.onTextChanged}
                value={this.state.note}
                readOnly={!this.state.is_editing}
              />
            </Grid>
            {button}
            {deleteButton}
            <Grid item xs={12}>
              {this.state.message}
            </Grid>
            <Dialog
              open={this.state.delete_modal_open}
              onClose={this.onDeleteModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete this note?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you wish to delete this note? This cannot be
                  undone and the note will not be recoverable.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.onDeleteModalClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.deleteNote} color="primary" autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.viewed_user,
    patient_notes: state.notesReducer.notes,
    note_count: state.notesReducer.note_count,
    has_fetched_notes: state.notesReducer.has_fetched,
  };
};
export default connect(mapStateToProps, (dispatch) => {
  return {
    CreateNote: PatientNotes.CreateNote,
    GetNotes: PatientNotes.GetNotes,
    UpdateNote: PatientNotes.UpdateNote,
    DeleteNote: PatientNotes.DeleteNote,
    dispatch,
  };
})(PatientNotes);
