import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./sharedComponents/Login";
import ForgotPassword from "./sharedComponents/ForgotPassword";
import ResetPassword from "./sharedComponents/ResetPassword";

import Organizations from "./adminView/Organizations";
import BillingOrganizations from "./adminView/BillingOrganizations";
import MonthlyView from "./scheduledSessions/MonthlyView";

import Users from "./organizationView/Users";
// import Practitioners from "./organizationView/Practitioners";
import UserGeneralGraph from "./dashboards/profile_views/UserProfilePage";
import UserFeedbackGraph from "./d3Components/UserFeedbackGraph";
import AppBar from "./sharedComponents/AppBar";

import { connect } from "react-redux";
import { signOut } from "../actions";

import { Debug } from "../lib";

import "../styles/style.scss";
import UserFile from "./dashboards/profile_views/UserFile";
import PermissionsPage from "./adminView/PermissionsPage";
import DeepDataDive from "./adminView/DeepDataDive";
import ActiveUsersChart from "./adminView/ActiveUsersChart";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import PermissionsService from "../lib/PermissionsSystem";


// Neurofit logo
import logo from "../assets/images/neurofit-logo.svg";

// will be replaced with a parent component (sub-nav)
import BillingInformation from "./Billing";

// register component
import RegisterContainer from "./sharedComponents/Register/RegisterContainer";

// navbar 1.1
import NavbarContainer from "./sharedComponents/Navbar/NavbarContainer";
import NavbarMobileContainer from "./sharedComponents/Navbar/NavbarMobile";

// Patient Profile 1.1

// Main scss for App.js
import "./App.scss";

import asyncComponent from "../highOrderComponents/asyncComponent/asyncComponent";

import theme from "./theme";

const AsyncPractitionersComponent = asyncComponent(() =>
  import("./practitionersManagement/PractitionersManagement")
);
const AsyncScheduler = asyncComponent(() =>
  import("./scheduledSessions/index")
);
const AsyncPatientDashboard = asyncComponent(() =>
  import("./dashboards/PatientDashboard")
);
const AsyncOrgDashboard = asyncComponent(() =>
  import("./dashboards/OrganizationDashboard")
);
const AsyncPractitionerDashboard = asyncComponent(() =>
  import("./dashboards/PractitionerDashboard")
);
const AsyncError404Page = asyncComponent(() => import("./Error/404"));

const AsyncBillingInformation = asyncComponent(() => import("./Billing"))

class App extends Component {
  getPrivilege = async () => {
    parseInt(localStorage.getItem("privilege"), 10);
  };

  renderRoutes = () => {
    if (this.props.isAuthenticated && this.props.privilege != undefined) {
      this.getPrivilege();
      if (this.props.privilege === 0) {
        return (
          <MuiThemeProvider theme={theme}>
              <Switch>
                {/* <Route path="/cognitive-profile" component={UserGeneralGraph} /> */}
                <Route
                  path="/user-profile/:id"
                  component={AsyncPatientDashboard}
                />
                <Route
                  path="/practitioner-profile/:id"
                  component={AsyncPractitionerDashboard}
                />
                <Route path="/mood" component={() => <UserFeedbackGraph />} />
                <Route
                   exact
                   path="/"
                   component={() => <Users user_types={["PATIENT"]} />}
                />
                <Route
                  path="/patient"
                  component={() => <Users user_types={["PATIENT"]} />}
                />
                <Route
                  path="/organizations"
                  component={() => <Organizations />}
                />
                {
                    PermissionsService.HasPermission("admin.billing.read") && 
                    <Route path="/billing" component={AsyncBillingInformation}  />
                }
               
                <Route path="/org-profile/:id" component={AsyncOrgDashboard} />
                {/* Setting the route to the component for the monthly view schedule */}
                <Route path="/schedule" component={AsyncScheduler} />
                <Route component={AsyncError404Page} />
              </Switch>
          </MuiThemeProvider>
        );
      } else if (this.props.privilege === 1) {
        return (
          <MuiThemeProvider theme={theme}>
            <Switch>
                {
                    PermissionsService.HasPermission("admin.billing.read") && 
                    <Route path="/billing" component={AsyncBillingInformation}  />
                }
              <Route
                exact
                path="/"
                component={() => <Users user_types={["PATIENT"]} />}
              />
              <Route
                path="/organizations"
                component={() => <Organizations />}
              />
              {/* <Route path="/user-profile/:id" component={(props) => <UserGeneralGraph {...props}/>} /> */}
              <Route
                path="/user-profile/:id"
                component={AsyncPatientDashboard}
              />
              <Route
                  path="/practitioner-profile/:id"
                  component={AsyncPractitionerDashboard}
              />
              <Route path="/org-profile/:id" component={AsyncOrgDashboard} />
              <Route path="/permissions" component={PermissionsPage} />
              <Route path="/deep_data" component={DeepDataDive} />
              {/*<Route exact path="/practitioners" component={AsyncPractitionersComponent} />
              <Route exact path="/patients" component={()=><Users user_types={["PATIENT"]}/>} />*/}
              <Route path="/schedule" component={AsyncScheduler} />
              <Route component={AsyncError404Page} />
            </Switch>
          </MuiThemeProvider>
        );
      }
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <div className="container mt-3 login-page-container">
            <Switch>
              <Route
                exact
                path="/"
                component={() => (
                  <Login
                    isAuthenticated={this.props.isAuthenticated}
                    getPrivilege={this.props.getPrivilege}
                  ></Login>
                )}
              />
              <Route
                exact
                path="/forgot_password"
                component={() => <ForgotPassword />}
              />
              <Route
                exact
                path="/password_reset"
                component={() => <ResetPassword />}
              />
              <Route exact path="/register" component={RegisterContainer} />
              <Route component={AsyncError404Page} />
            </Switch>
          </div>
        </MuiThemeProvider>
      );
    }
  };

  // hides and displays the appbar
  renderAppBar = () => {
    // if the authentication is false, the AppBar hides
    if (!this.props.isAuthenticated) {
      return (
        <Router>
          {/* <div className="delete"> */}
          {this.renderRoutes()}
          {/* </div> */}
        </Router>
      );
    }
    // else, the AppBar will display
    else {
      // navbar and dynamic components rendered here
      return (
        <Router>
          <AppBar
            isAuthenticated={this.props.isAuthenticated}
            privilege={this.props.privilege}
            logoutUser={this.props.signOut}
            user={this.props.user}
            org={this.props.org}
          />
          <NavbarContainer
            isAuthenticated={this.props.isAuthenticated}
            privilege={this.props.privilege}
            logoutUser={this.props.signOut}
            user={this.props.user}
            org={this.props.org}
          />

          <NavbarMobileContainer
            isAuthenticated={this.props.isAuthenticated}
            privilege={this.props.privilege}
            logoutUser={this.props.signOut}
            user={this.props.user}
            org={this.props.org}
          />

          {/* <NavbarMobileContainer
                /> */}
          <div className="app-container">
            <div className="app-container-flex-item">{this.renderRoutes()}</div>
          </div>
        </Router>
      );
    }
  };

  render() {
    return <div>{this.renderAppBar()}</div>;
  }
}

const mapStateToProps = (state) => {
  // console.log(state.authReducer);
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    privilege: state.authReducer.privilege,
    user: state.authReducer.user,
    org: state.authReducer.org,
  };
};

export default connect(mapStateToProps, { signOut })(App);
