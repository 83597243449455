import React from "react";
import "./SuccessComponent.scss";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import Button from "@material-ui/core/Button";

function SuccessComponent(props) {
  const { addingType, numberOfUsers } = props;
  return (
    <div className="SuccessComponent-container">
      {addingType === "individual" && (
        <main>
          <div className="icon">
            <CheckCircleOutlineTwoToneIcon
              fontSize="large"
              style={{ color: "#C4DFEF", width: "140px", height: "140px" }}
            />
          </div>
          <div className="main-content">
            New user has successfully been added!
          </div>
        </main>
      )}
      {addingType !== "individual" && (
        <main>
          <div className="icon">
            <CheckCircleOutlineTwoToneIcon
              style={{ color: "#C4DFEF", width: "140px", height: "140px" }}
              iconStyle={{
                width: 130,
                height: 130,
              }}
            />
          </div>
          <div className="main-content">
            {numberOfUsers} Users have successfully been imported
          </div>
        </main>
      )}
      <div className="buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleAddOrUpload}
        >
          {addingType === "individual" && "Add Another User"}
          {addingType !== "individual" && "Upload Additional Users"}
        </Button>
        <Button variant="contained" color="primary" onClick={props.handleReset}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default SuccessComponent;
