import moment from "moment"

const assignmentsToActivities = (
  assignments,
  dateStart = null,
  dateEnd = null
) => {
  let activities = []
  let start = dateStart && moment(dateStart)
  let end = dateEnd && moment(dateEnd)
  for (let a in assignments) {
    for (let w in assignments[a].activity_windows) {
      let windowParams = {
        start: assignments[a].activity_windows[w].start,
        end: assignments[a].activity_windows[w].end,
      }
      for (let act in assignments[a].activity_windows[w].activities) {
        assignments[a].activity_windows[w].activities[act] = {
          ...assignments[a].activity_windows[w].activities[act],
          ...windowParams,
        }
      }
      if (start && end) {
        if (
          moment(assignments[a].activity_windows[w].start).isBetween(start, end)
        ) {
          activities = activities.concat(assignments[a].activity_windows[w].activities)
        }
      } else if (start) {
        if (moment(assignments[a].activity_windows[w].start).isAfter(start)) {
          activities = activities.concat(assignments[a].activity_windows[w].activities)
        }
      } else if (end) {
        if (moment(assignments[a].activity_windows[w].start).isBefore(end)) {
          activities = activities.concat(assignments[a].activity_windows[w].activities)
        }
      } else {
        activities = activities.concat(assignments[a].activity_windows[w].activities)
      }
    }
  }

  return activities
}

export { assignmentsToActivities }
