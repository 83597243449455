import React from "react";
import "./ConfirmImport.scss";

function ConfirmImport(props) {
  return (
    <div className="ConfirmImport-container">
      <main>
        <div>
          You’re ready to import {props.numberOfUsers} new{" "}
          {props.userType !== "PATIENT" ? "Practitioners" : "Patients"}
        </div>
        <div>
          Clicking the import button will create accounts for each{" "}
          {props.userType !== "PATIENT" ? "Practitioners" : "Patients"}
        </div>
      </main>
    </div>
  );
}

export default ConfirmImport;
