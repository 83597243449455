import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    secondary: {
      main: "#57B8F1",
    },
    white: "rgba(255, 255, 255, 1)",
    gray: {
      100: "rgba(248, 248, 248, 1)",
      200: "rgba(237, 236, 236, 1)",
      300: "rgba(212, 212, 212, 1)",
      400: "rgba(158, 158, 158, 1)",
      500: "rgba(112, 112, 112, 1)",
      600: "rgba(28, 27, 26, 1)",
    },
    blue: {
      100: "rgba(233, 245, 251, 1)",
      200: "rgba(219, 238, 247, 1)",
      300: "rgba(197, 223, 238, 1)",
      400: "rgba(146, 202, 235, 1)",
      500: "rgba(12, 131, 191, 1)",
    },
    orange: {
      100: "rgba(255, 242, 227, 1)",
      200: "rgba(242, 138, 20, 1)",
      300: "rgba(199, 85, 4, 1)",
    },
    red: {
      100: "rgba(255, 240, 240, 1)",
      200: "rgba(218, 3, 3, 1)",
    },
    green: {
      100: "rgba(238, 249, 213, 1)",
      200: "rgba(9, 118, 38, 1)",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
    }
  },
})

export default theme
