import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import "./InvalidInputRow.scss";
import { makeStyles } from "@material-ui/core/styles";
import { DIAGNOSIS, MAPPING_OCCUPATION } from "../../../../lib/Constants";
const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E5B8B8",
    },
    color: "#E5B8B8",
  },
  tooltip: {
    backgroundColor: "#E5B8B8",
    border: "1px solid #E5B8B8",
  },
}));
function formatDate(date) {
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();

  if (d < 10) {
    d = "0" + d;
  }
  if (m < 10) {
    m = "0" + m;
  }

  let newData = y + "-" + m + "-" + d;
  return newData;
}
function InvalidInputRow(props) {
  let classes = useStyles();
  const [FirstName, LastName, DateOfBirth, Sex, Email, Diagnosis] = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const errorStyle = { background: "#E2D1D1", border: "1px solid #eee" };
  const [invalidColumn, setInvalidColumn] = useState([]);
  const getErrors = (_path) => {
    const desiredElement = props.error.filter((el) => {
      return el.path === _path;
    });
    let errorJSX = (
      <ul
        style={{
          padding: 0,
          margin: 0,
          listStyle: "none",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        {desiredElement.map((el, index) => {
          return el.errors.map((el) => {
            return (
              <li
                style={{
                  padding: 0,
                  margin: 0,
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#B75151",
                }}
                key={index}
              >
                {el}
              </li>
            );
          });
        })}
      </ul>
    );
    return errorJSX;
  };
  useEffect(() => {
    let err = [];
    props.error.forEach((element) => {
      err.push(element.path);
    });
    setInvalidColumn(err);
  }, [props.error]);
  useEffect(() => {
    if (props.isClicked) {
      let req_data = {
        FirstName: FirstName.current.value,
        LastName: LastName.current.value,
        Sex: Sex.current.value,
        Email: Email.current.value,
      };
      if (props.userType === "PATIENT") {
        req_data = {
          ...req_data,
          DateOfBirth: DateOfBirth.current.value,
          Diagnosis: Diagnosis.current.value,
        };
      } else {
        req_data = {
          ...req_data,
          Occupation: Diagnosis.current.value,
        };
      }
      props.getUser(req_data);
    }
  }, [props.isClicked]);
  let cellContent;
  let mappedColumn = {
    "First Name": "FirstName",
    "Last Name": "LastName",
    Sex: "Sex",
    Email: "Email",
  };
  if (props.userType === "PATIENT") {
    mappedColumn = {
      ...mappedColumn,
      "Date of Birth": "DateOfBirth",
      Diagnosis: "Diagnosis",
    };
    cellContent = {
      "First Name": (
        <input
          type="text"
          ref={FirstName}
          defaultValue={props.user.FirstName}
          style={
            invalidColumn.includes("FirstName")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        />
      ),
      "Last Name": (
        <input
          type="text"
          ref={LastName}
          defaultValue={props.user.LastName}
          style={
            invalidColumn.includes("LastName")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        />
      ),
      "Date of Birth": (
        <input
          type="date"
          ref={DateOfBirth}
          defaultValue={formatDate(props.user.DateOfBirth)}
          style={
            invalidColumn.includes("DateOfBirth")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        />
      ),
      Sex: (
        <select
          defaultValue={String(props.user.Sex).toLowerCase() || ""}
          ref={Sex}
          style={
            invalidColumn.includes("Sex")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        >
          <option value=""></option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      ),
      Email: (
        <input
          ref={Email}
          type="email"
          defaultValue={props.user.Email}
          style={
            invalidColumn.includes("Email")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        />
      ),
      Diagnosis: (
        <select
          ref={Diagnosis}
          defaultValue={String(props.user.Diagnosis)|| ""}
          style={
            invalidColumn.includes("Diagnosis")
              ? errorStyle
              : { border: "1px solid #eee" , width  : '100px'  }
          }
        >
          <option value=""></option>
          {DIAGNOSIS.map(item => {
            return <option value={item}>{item}</option>
          })}
        </select>
      ),
    };
  } else {
    mappedColumn = {
      ...mappedColumn,
      Occupation: "Occupation",
    };
    cellContent = {
      "First Name": (
        <input
          ref={FirstName}
          type="text"
          defaultValue={props.user.FirstName}
          style={
            invalidColumn.includes("FirstName")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        />
      ),
      "Last Name": (
        <input
          ref={LastName}
          type="text"
          defaultValue={props.user.LastName}
          style={
            invalidColumn.includes("LastName")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        />
      ),
      Sex: (
        <select
          ref={Sex}
          defaultValue={String(props.user.Sex).toLowerCase() || ""}
          style={
            invalidColumn.includes("Sex")
              ? errorStyle
              : { border: "1px solid #eee" }
          }
        >
          <option value=""></option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      ),
      Email: (
        <input
          ref={Email}
          type="email"
          defaultValue={props.user.Email}
          style={{ border: "1px solid #eee" }}
        />
      ),
      Occupation: (
        <select
          ref={Diagnosis}
          defaultValue={String(MAPPING_OCCUPATION.find(item => item.label ===  props.user.Occupation)?.id || props.user.Occupation).toLowerCase() || ""}
          style={
            invalidColumn.includes("Occupation")
              ? errorStyle
              : { border: "1px solid #eee"}
          }
        >
          <option value=""></option>
          <option value="therapist">General Practitioner</option>
          <option value="ot">Occupational Therapist</option>
          <option value="pt">Physical Therapist</option>
          <option value="slp">Speech Language Pathologist</option>
        </select>
      ),
    };
  }
  return (
    <div className="InvalidInputRow-container">
      {Array.from(
        { length: props.userType === "PATIENT" ? 6 : 5 },
        (_, index) =>
          invalidColumn.includes(mappedColumn[props.currentColumn[index]]) ? (
            <Tooltip
              arrow
              title={getErrors(mappedColumn[props.currentColumn[index]])}
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
              <div className="cell-content invalidCell">
                {cellContent[props.currentColumn[index]]}
              </div>
            </Tooltip>
          ) : (
            <div className="cell-content">
              {cellContent[props.currentColumn[index]]}
            </div>
          )
      )}
    </div>
  );
}

export default InvalidInputRow;
