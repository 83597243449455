import React, { useEffect } from "react";
import * as yup from "yup";
import { makeStyles, useTheme } from "@material-ui/core/styles/index";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector, useStore } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery/index";
import Alert from "@material-ui/lab/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { createUser, getUsersByOrg } from "../../actions";
import DateFnsUtils from "@date-io/date-fns/build/index";
import moment from "moment/moment";
import Input from "@material-ui/core/Input";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  tiltStyle: {
    maxWidth: "800px",
    margin: "2.5rem auto",
    border: `2.5px solid #16A0D8`,
    borderRadius: "10px",
    outline: "none",
    height: "90%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "800px",
    width: "auto",
    height: "auto",
    // backgroundColor: '#fafafa',
    // padding: theme.spacing(6),
    padding: "0",
    // overflow: 'auto',
    margin: "auto 0",
    borderRadius: "10px",
    outline: "none",
  },
  form: {
    width: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    padding: "2.5rem 3.5rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      padding: "0",
    },
  },
  addBtn: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
  inlineInput: {
    width: "100%",
  },
  radioContainer: {
    marginTop: theme.spacing(1),
  },
  radioGroup: {
    flexDirection: "row",
  },
  personIcon: {
    fontSize: "3.5rem",
    margin: "0 auto",
    backgroundColor: "#E8F5FB",
    borderRadius: "50%",
    padding: "5px",
    border: "2px solid #16A0D8",
  },
  heading: {
    textAlign: "center",
    padding: "10px 0",
    marginTop: "0",
    marginBottom: "0",
  },
  input_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const OCCUPATION_TYPES = [
  { label: "General Practitioner", value: "therapist" },
  { label: "Occupational Therapist", value: "ot" },
  { label: "Physical Therapist", value: "pt" },
  { label: "Speech Language Pathologist", value: "slp" },
];

const schema = yup.object().shape({
  email: yup.string().trim().email().required().label("Email"),
  firstName: yup.string().trim().required().label("First Name"),
  lastName: yup.string().trim().required().label("Last Name"),
  occupation: yup.string().trim().required().label("Occupation"),
  gender: yup.string().trim().required().label("Gender"),
  dob: yup.string().required().label("Date of Birth"),
  user_password: yup.string().optional(),
  passwordConfirmation: yup.string().optional(),
});

export default function AddPractitionerUsers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const dispatch = useDispatch();

  const [dob, handleDobChanged] = React.useState(new Date());

  const responseMessage = useSelector((state) => state.userReducer.response);

  const { register, handleSubmit, setValue, errors, reset, control, setError } =
    useForm({
      resolver: yupResolver(schema),
    });

  const open = useSelector((state) => state.modalReducer.modalProps.open);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const unsubscribe = store.subscribe((action) => {
      handleSubmit(onSubmit)();
    });
    return () => unsubscribe();
  }, []);

  const onSubmit = async (data) => {
    // Prepare the data that will be saved for the patient
    const pronounSelect = data.pronouns;
    let pronouns = {};
    // For now we'll just use the radio buttons to select a gender and assign the relevant pronoun options
    switch (pronounSelect) {
      case "masculine":
        pronouns.pronoun_subject = "he";
        pronouns.pronoun_object = "him";
        pronouns.pronoun_possessive = "his";
        break;
      case "feminine":
        pronouns.pronoun_subject = "she";
        pronouns.pronoun_object = "her";
        pronouns.pronoun_possessive = "hers";
        break;
      case "neuter":
      default:
        pronouns.pronoun_subject = "they";
        pronouns.pronoun_object = "them";
        pronouns.pronoun_possessive = "theirs";
        break;
    }
    let req_data = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      occupation: data.occupation,
      gender: data.gender,
      dob: moment().toISOString().split("T")[0],
      role: "GENERAL_PRACTITIONER",
      is_patient: false,
      password: data.user_password,
    };

    let result = await dispatch(createUser(req_data));

    if (result !== undefined && result.status === 200) {
      props.moveToFinalStep();
      await dispatch(getUsersByOrg(props.user_types));
      reset();
    } else {
      if (result.errorType === "DuplicateEntryError") {
        setError("email", {
          type: "manual",
          message: "This email already exists",
        });
      }
    }
  };

  const renderResponseMessage = () => {
    if (responseMessage.status === 200) {
      return (
        <div className={classes.messageContainer}>
          <Alert color="success" variant="standard">
            {responseMessage.message}
          </Alert>
        </div>
      );
    } else if (responseMessage.status === 400) {
      return (
        <div className={classes.messageContainer}>
          <Alert severity="error">{responseMessage.message}</Alert>
        </div>
      );
    }
  };

  const renderFormError = (errorMessage) => {
    return (
      <div className={classes.messageContainer}>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    );
  };

  const body = (
    // <div className={classes.paper}>

    <div className={`${classes.paper} form-paper-container`}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} className={classes.input_container}>
          {/* first name */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth={false}
              id="firstName"
              label="First Name"
              name="firstName"
              autoFocus
              inputRef={register}
              className={classes.inlineInput}
            />
          </Grid>
          {/* last name */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth={false}
              id="lastName"
              label="Last Name"
              name="lastName"
              autoFocus
              inputRef={register}
              className={classes.inlineInput}
            />
          </Grid>

          {/* email */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              inputRef={register}
            />
          </Grid>

          {/* Occupation */}
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              className={classes.radioContainer + " w-100"}
            >
              <FormLabel component="legend">Occupation</FormLabel>
              <Controller
                control={control}
                id="occupation"
                name="occupation"
                label="Occupation"
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    value={value ? value : ""}
                    name={name}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  >
                    {OCCUPATION_TYPES &&
                      OCCUPATION_TYPES.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          {/* date of birth */}
          <Grid item xs={12} sm={12} md={6} style={{ display: "none" }}>
            <Input
              type="hidden"
              name="dob"
              id="dob"
              inputRef={register}
              value={moment(dob).toISOString()}
            ></Input>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="dialog"
                format="MM/dd/yyyy"
                margin="normal"
                label="Date of Birth"
                disableFuture
                fullWidth
                value={dob}
                onChange={(date) => {
                  handleDobChanged(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* Gender & Pronouns options */}
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              className={classes.radioContainer + " w-100"}
            >
              <FormLabel component="legend">Gender</FormLabel>
              <Controller
                control={control}
                id="gender"
                name="gender"
                label="Gender"
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    value={value ? value : ""}
                    name={name}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  >
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
         {/*} <Grid item xs={12} sm={12} md={6}>
            <FormControl
              component="fieldset"
              className={classes.radioContainer + " w-100"}
            >
              <FormLabel component="legend">Pronouns</FormLabel>
              <Controller
                control={control}
                id="pronouns"
                name="pronouns"
                label="Pronouns"
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    value={value ? value : ""}
                    name={name}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  >
                    <MenuItem value="feminine">She/Her/Hers</MenuItem>
                    <MenuItem value="masculine">He/Him/His</MenuItem>
                    <MenuItem value="neuter">They/Them/Theirs</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>*/}

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user_password"
              label="Password"
              name="user_password"
              type="password"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="passwordConfirmation"
              label="Confirm Password"
              name="passwordConfirmation"
              type="password"
              inputRef={register}
            />
          </Grid>
        </Grid>
      </form>
      {renderResponseMessage()}
      {errors.email && renderFormError(errors.email.message)}
      {errors.firstName && renderFormError(errors.firstName.message)}
      {errors.lastName && renderFormError(errors.lastName.message)}
      {errors.education && renderFormError(errors.education.message)}
      {errors.diagnosis && renderFormError(errors.diagnosis.message)}
      {errors.gender && renderFormError(errors.gender.message)}
      {errors.user_password && renderFormError(errors.user_password.message)}
      {errors.passwordConfirmation &&
        renderFormError(errors.passwordConfirmation.message)}
    </div>
    // </div>
  );

  return <>{body}</>;
}
