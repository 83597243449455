import http from "../http-common";
import moment from "moment";

import {
  GET_PATIENT_NOTES,
  CREATE_PATIENT_NOTE,
  UPDATE_PATIENT_NOTE,
  DELETE_PATIENT_NOTE,
} from "./types";

function tryDispatch(dispatch, args) {
  if (dispatch) {
    dispatch(args);
  }
}

class NotesActions {
  static USER_NOTES_ROUTE = "organization/users/{user_id}/notes";
  static USER_NOTE_INSTANCE_ROUTE =
    "organization/users/{user_id}/notes/{note_id}";

  static __ResolvePath(path, user_id, note_id = "") {
    return path.replace("{user_id}", user_id).replace("{note_id}", note_id);
  }

  static GetNotes(user_id, options) {
    const route = NotesActions.__ResolvePath(
      NotesActions.USER_NOTES_ROUTE,
      user_id
    );
    const start_date = options?.start_date;
    const end_date = options?.end_date;

    return async (dispatch) => {
      const response = await http
        .get(route, {
          params: {
            start_date: start_date,
            end_date: end_date,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      tryDispatch(dispatch, {
        type: GET_PATIENT_NOTES,
        payload: response.data,
        response: response,
      });

      return response;
    };
  }

  /**
   * Creates a new note for the given user
   * @param { } user_id             The UUID of the user to create the note for
   * @param { } note_data.note      The text of the note in markdown format
   * @param { } note_data.note_date The date of the note, or null for the current date
   * @returns An axios HTTP response
   */
  static CreateNote(user_id, note_data) {
    const note = note_data.note;
    const note_date = moment(note_data.note_date).utc().toISOString(true);
    const route = NotesActions.__ResolvePath(
      NotesActions.USER_NOTES_ROUTE,
      user_id
    );

    return async (dispatch) => {
      const response = await http
        .post(route, {
          note,
          note_date,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      tryDispatch(dispatch, {
        type: CREATE_PATIENT_NOTE,
        payload: response.data,
        response: response,
      });

      return response;
    };
  }

  /**
   * Updates a given note, overriding it's text or date
   * @param { } user_id             The UUID of the user that the note is on
   * @param { } note_id             The UUID of the note to update
   * @param { } note_data.note      The text of the note in markdown format
   * @param { } note_data.note_date The date of the note, or null for the current date
   * @returns An axios HTTP response
   */
  static UpdateNote(user_id, note_id, note_data) {
    const note = note_data.note;
    const note_date = moment(note_data.note_date).utc().toISOString(true);
    const route = NotesActions.__ResolvePath(
      NotesActions.USER_NOTE_INSTANCE_ROUTE,
      user_id,
      note_id
    );

    return async (dispatch) => {
      const response = await http
        .put(route, {
          note,
          note_date,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      tryDispatch(dispatch, {
        type: UPDATE_PATIENT_NOTE,
        payload: response.data,
        response: response,
      });

      return response;
    };
  }

  /**
   * Deletes a note associated with the user
   * @param {string} user_id The UUID of the user that the note is on
   * @param {string} note_id The UUID of the note to update
   * @returns                An axios HTTP response
   */
  static DeleteNote(user_id, note_id) {
    const route = NotesActions.__ResolvePath(
      NotesActions.USER_NOTE_INSTANCE_ROUTE,
      user_id,
      note_id
    );

    return async (dispatch) => {
      const response = await http
        .delete(route)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      tryDispatch(dispatch, {
        type: DELETE_PATIENT_NOTE,
        payload: response.data,
        response: response,
      });

      return response;
    };
  }
}

export default NotesActions;
