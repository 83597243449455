import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 250,
  },
}));

export default function RedarLegend(props) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        Graph Info
      </Typography>
      <Typography variant="body1" gutterBottom>
        Difficulty:{" "}
        {props.data[0].Difficulty === null
          ? 0
          : props.data[0].Difficulty.toFixed(2)}{" "}
        / 5
      </Typography>
      <Typography variant="body1" gutterBottom>
        Satisfaction:{" "}
        {props.data[0].Satisfaction === null
          ? 0
          : props.data[0].Satisfaction.toFixed(2)}{" "}
        / 5
      </Typography>
      <Typography variant="body1" gutterBottom>
        Frustration:{" "}
        {props.data[0].Frustration === null
          ? 0
          : props.data[0].Frustration.toFixed(2)}{" "}
        / 5
      </Typography>
      <Typography variant="body1" gutterBottom>
        Enjoyment:{" "}
        {props.data[0].Enjoyment === null
          ? 0
          : props.data[0].Enjoyment.toFixed(2)}{" "}
        / 5
      </Typography>
      <Typography variant="body1" gutterBottom>
        Effort:{" "}
        {props.data[0].Effort === null ? 0 : props.data[0].Effort.toFixed(2)} /
        5
      </Typography>
    </Paper>
  );
}
