import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateStartDateSelect, updateEndDateSelect } from "../../actions";
import moment from "moment";
import { Debug } from "../../lib";
import PropTypes from "prop-types";

class DateRangePickers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // These dates are UTC dates
      startDate: moment(this.props.startDate),
      endDate: moment(this.props.endDate),
    };

    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
    this._handleDateChanged = this._handleDateChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    let newState = this.state;
    let requiresUpdate = false;
    if (prevProps.startDate != this.props.startDate) {
      newState.startDate = this.props.startDate.utc();
      requiresUpdate |= true;
    }
    if (prevProps.endDate != this.props.endDate) {
      newState.endDate = this.props.endDate.utc();
      requiresUpdate |= true;
    }
    if (requiresUpdate) {
      this.setState(newState);
    }
  }

  _handleDateChanged(key, date) {
    date = this.props.monthly ? date.set("date", 1) : date;
    date = date.utc();
    this.setState(
      {
        ...this.state,
        [key]: date,
      },
      () => {
        this.props.dispatch(updateStartDateSelect(date));
        if (
          !!(
            this.props.onValueChanged &&
            typeof this.props.onValueChanged === "function"
          )
        ) {
          this.props.onValueChanged(this.state.startDate, this.state.endDate);
        }
      }
    );
  }

  /**
   * handles when the start date selector has changed
   * @param {moment} date The new date in local time
   */
  handleStartDateChanged(date) {
    this._handleDateChanged("startDate", date);
  }

  /**
   * handles when the start date selector has changed
   * @param {moment} date The new date in local time
   */
  handleEndDateChanged(date) {
    date = this.props.monthly ? date.endOf("month") : date.endOf("day");
    this._handleDateChanged("endDate", date);
  }

  render() {
    let startDate = this.state.startDate.local();
    let endDate = this.state.endDate.local();

    const shared_options = {
      autoOk: "autoOK",
      variant: "inline",
      format: this.props.monthly ? "YYYY-MM" : "YYYY-MM-DD",
      margin: "normal",
      disableFuture: this.props.disableFuture || false,
      disablePast: this.props.disablePast || false,
      KeyboardButtonProps: {
        "aria-label": "change date",
      },
      views: this.props.monthly ? ["month", "year"] : ["year", "month", "date"],
    };

    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <KeyboardDatePicker
              {...shared_options}
              id="startDatePicker"
              label="Start Date"
              value={moment(startDate).format(shared_options.format)}
              onChange={this.handleStartDateChanged}
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <KeyboardDatePicker
              {...shared_options}
              id="endDatePicker"
              label="End Date"
              value={moment(endDate).format(shared_options.format)}
              onChange={this.handleEndDateChanged}
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

DateRangePickers.props = {
  onValueChanged: PropTypes.func,
  monthly: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
};

const mapStateToProps = (state) => {
  return {
    startDate: state.generalGraphReducer.startDateSelect,
    endDate: state.generalGraphReducer.endDateSelect,
  };
};
export default connect(mapStateToProps, (dispatch) => {
  return {
    updateStartDateSelect: updateStartDateSelect,
    updateEndDateSelect: updateEndDateSelect,
    dispatch,
  };
})(DateRangePickers);

/*
export default function DateRangePickers(props) {

    const dispatch = useDispatch();
    const startDateUTC = props.monthly ? moment(props.startDate).set('date', 1) : moment(props.startDate);
    const endDateUTC  = props.monthly ? moment(props.endDate).set('date', 1) : moment(props.endDate);
    let startDate = startDateUTC.local(); 
    let endDate = endDateUTC.local(); 
    
    const handleStartDateChange = (date) => {
        Debug.log(date);
        date = props.monthly ? date.set('date', 1) : date;
        dispatch(updateStartDateSelect(date.utc()))
        if (!!(props.onValueChanged && typeof props.onValueChanged === "function")) {
            props.onValueChanged(date, endDate);
        }
    };
    const handleEndDateChange = (date) => {
        date = props.monthly ? date.set('date', 1) : date;
        dispatch(updateEndDateSelect(date.utc()))
        if (!!(props.onValueChanged && typeof props.onValueChanged === "function")) {
            props.onValueChanged(startDate, date);
        }
    }

    const shared_options = {
        autoOk: 'autoOK',
        variant:"inline",
        format: props.monthly ? "YYYY-MM" : "YYYY-MM-DD",
        margin:"normal",
        disableFuture: props.disableFuture || false,
        disablePast: props.disablePast || false,
        KeyboardButtonProps: {
            'aria-label': 'change date',
        },
        views: props.monthly ? ['month', 'year'] : ['year', 'month', 'date']
    }

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                    <KeyboardDatePicker
                        {...shared_options}
                        id="startDatePicker"
                        label="Start Date"
                        value={moment(startDate).format('YYYY-MM-DD')}
                        onChange={handleStartDateChange}
                        style={{
                            width: '100%'
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <KeyboardDatePicker
                        {...shared_options}
                        id="endDatePicker"
                        label="End Date"
                        value={moment(endDate).format('YYYY-MM-DD')}
                        onChange={handleEndDateChange}
                        style={{
                            width: '100%'
                        }}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )

}
*/
