import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { useDispatch, useSelector } from "react-redux";
import { updateWithinDateRange } from "../../actions";
import { createDateString } from "../../lib";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ChartRangeDropdown() {
  const dispatch = useDispatch();
  const date = useSelector((state) => state.userRadarPlotReducer.selectedDate);
  const classes = useStyles();
  const handleChange = (e) => dispatch(updateWithinDateRange(e.target.value));

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">Within</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={date}
          onChange={handleChange}
        >
          {createDateString(30)}
          <MenuItem value={`${createDateString(30)}`}>30 Days</MenuItem>
          <MenuItem value={`${createDateString(60)}`}>60 Days</MenuItem>
          <MenuItem value={`${createDateString(90)}`}>90 Days</MenuItem>
          <MenuItem value={`all`}>All</MenuItem>
          {/* <MenuItem value={"All"}>All Sessions</MenuItem> */}
        </Select>
        <FormHelperText>
          Sessions that occured within the last (days)
        </FormHelperText>
      </FormControl>
    </div>
  );
}

// http://reactchartjs.github.io/react-chartjs-2/#/radar
