import { GET_USER_SESSIONS } from "../actions/types";

const INITIAL_STATE = {
  hasFetched: false,
  sessions: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_SESSIONS:
      return { ...state, sessions: action.payload, hasFetched: true };
    default:
      return state;
  }
};
