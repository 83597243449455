import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AccountImage  from "../../assets/images/organization profile.png";

const useStyles = makeStyles((theme) => ({
  wideCell: {
    minWidth: "150px",
  },
}));

export default function OrganizationsListRow(props) {
  async function handleUpdate(id) {
    const updatePrivilege = await props.setPrivilege(id, privilege);
  }

  function privilegeText(privilege) {
    switch (privilege) {
      case -1:
        return "Inactive";
      case 0:
        return "Organization";

      case 1:
        return "Admin";

      default:
        return "N/A";
    }
  }

  const classes = useStyles();

  const [privilege, setPrivilege] = useState(props.privilege);

  const handleChange = (e) => {
    setPrivilege(e.target.value);
  };

  const owner = props.Owner;
  const first_name = owner ? owner.first_name : "<missing>";
  const last_name = owner ? owner.last_name : "<missing>";
  const email = owner ? owner.email : "<missing>";
  return (
    <TableRow component="tr" key={props.id} hover={true}>
      <TableCell scope="row">
        <a href={`/org-profile/${props.id}`}>
          <img  src={AccountImage}  alt="Organization"/>
        </a>
      </TableCell>
      <TableCell scope="row">{first_name}</TableCell>
      <TableCell align="left">{last_name}</TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="left">{props.name}</TableCell>
      <TableCell align="left">
        {new Date(props.updated_at).toDateString()}
      </TableCell>
      <TableCell align="left">{new Date(props.created_at).toDateString()}</TableCell>
      <TableCell align="left">{privilegeText(props.privilege)}</TableCell>
      {props.dataPre !== 0 && <TableCell align="left" className={classes.wideCell}>
        <FormControl>
          <Select
            className={classes.wideCell}
            labelId="privilegeLabel"
            id="privilegeSelect"
            value={privilege}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <MenuItem value={-1}>Inactive</MenuItem>
            <MenuItem value={0}>Organization</MenuItem>
            <MenuItem value={1}>Admin</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      }
      {props.dataPre !== 0 && <TableCell align="left">
        <Button
          onClick={() => {
            handleUpdate(props.id);
          }}
        >
          Update Privilege
        </Button>
      </TableCell>
    }
    </TableRow>
  );
}
