import moment from "moment"
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Checkbox,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"

const DAYS = ["S", "M", "T", "W", "T", "F", "S"]

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  textField: {
    padding: theme.spacing(1),
    width: 160,
  },
  fullTextField: {
    padding: theme.spacing(1),
    width: 320,
  },
  dayCheck: {
    width: "2rem",
    height: "2rem",
    padding: "1rem",
    margin: "0 0.25rem",
    borderRadius: "2rem",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  dayCheckDeselected: {
    background: theme.palette.gray[200],
    color: theme.palette.gray[500],
  },
  dayCheckSelected: {
    backgroundColor: theme.palette.blue[500],
    color: theme.palette.gray[100],
  },
  paper: {
    borderRadius: "0.75rem",
    boxShadow: "none",
    padding: "2rem",
  },
  divider: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  buttonPrimary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.orange[300],
    },
    backgroundColor: theme.palette.orange[200],
    color: theme.palette.white,
    maxWidth: "8rem",
  },
  buttonSecondary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.gray[100],
    },
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[600],
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
}))

const ActivityForm = ({ activity, index, onActivityChange }) => {
  const classes = useStyles()

  // const handleChange = (event) => {
  //   const { id, value } = event.target
  //   setActivity(index, id, value)
  // }

  // const handleDayChange = (event) => {
  //   const { id } = event.target
  //   const dayValue = parseInt(id.split("-")[1])

  //   if (isSelected(dayValue)) {
  //     setActivity(index, "days", activity.days.filter(d => d !== dayValue))
  //   } else {
  //     setActivity(index, "days", [dayValue, ...activity.days])
  //   }
  // }

  // const isSelected = (dayValue) => {
  //   return activity.days.includes(dayValue)
  // }

  const handleChange = (field, value) => {
    onActivityChange(index, { ...activity, [field]: value })
  }

  const handleDayChange = (dayValue) => {
    const newDays = activity.days.includes(dayValue)
      ? activity.days.filter((d) => d !== dayValue)
      : [...activity.days, dayValue]
    handleChange("days", newDays)
  }

  const isSelected = (dayValue) => {
    return activity.days.includes(dayValue)
  }

  const currentDate = moment().format("YYYY-MM-DD")

  return (
    <>
      <FormControl
        component='fieldset'
        className={classes.formControl}
        classes={{ root: classes.formControl }}>
        <FormLabel component='legend'>Days of Week</FormLabel>
        {DAYS.map((title, i) => (
          <span
            className={`${classes.dayCheck} ${
              isSelected(i)
                ? classes.dayCheckSelected
                : classes.dayCheckDeselected
            }`}
            id={`day-${i}`}
            key={`day-${i}`}
            onClick={() => handleDayChange(i)}>
            {title}
          </span>
        ))}
      </FormControl>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>Time Window</FormLabel>
        <TextField
          id='time_start'
          value={activity.time_start}
          onChange={(e) => handleChange('time_start', e.target.value)}
          label='Start'
          variant='outlined'
          type='time'
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1800,
          }}
        />
        <TextField
          id='time_end'
          value={activity.time_end}
          onChange={(e) => handleChange('time_end', e.target.value)}
          label='End'
          variant='outlined'
          type='time'
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1800,
          }}
        />
      </FormControl>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>Date Range</FormLabel>
        <TextField
          id='date_start'
          value={activity.date_start}
          onChange={(e) => handleChange('date_start', e.target.value)}
          label='Start'
          variant='outlined'
          type='date'
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id='date_end'
          value={activity.date_end}
          onChange={(e) => handleChange('date_end', e.target.value)}
          label='End'
          variant='outlined'
          type='date'
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
    </>
  )
}

export default ActivityForm
