export class HSLA {
  constructor(h, l, s, a) {
    this.h = h;
    this.l = l;
    this.s = s;
    this.a = a || 1.0;
  }

  toString() {
    return `hsla(${this.h}, ${this.s}%, ${this.l}%, ${this.a})`;
  }

  /**
   * Creates a copy of this HSLA instance
   */
  Clone() {
    return new HSLA(this.h, this.l, this.s, this.a);
  }

  /**
   * Adds a given lightness value to this colour, and returns the reference for chaining
   * @param {numeric} value
   */
  AddLightness(value) {
    this.l += value;
    return this;
  }

  SetAlpha(value) {
    this.a = value;
    return this;
  }

  /**
   * Generates a random colour
   * @param {number} alpha The alpha of the color, will default to 1 if not provided (or falsy)
   */
  static Random(alpha) {
    alpha = alpha || 1.0;
    var h = Math.floor(Math.random() * 360);
    var s = 25 + Math.floor(Math.random() * 50);
    var l = 25 + Math.floor(Math.random() * 50);
    return new HSLA(h, s, l, alpha);
  }

  /**
   * Determines an approximate 'distance' between two colours, useful for ensuring colours are distinct
   * @param {HSLA} other The HLSA colour instance to compare this colour to
   */
  DistanceTo(other) {
    if (!(other instanceof HSLA)) {
      throw new Error("Other object should be an HSLA instance!");
    }
    return (
      Math.abs(this.h - other.h) * 2 +
      Math.abs(this.s - other.s) +
      Math.abs(this.l - other.l)
    );
  }
}

/**
 * Generates a set of colors that are all somewhat visually distinct from each other
 * @param {number} count The number of colors to attempt to generate
 * @param {number} threshold The threshold for difference between colours, lower values means less distinct (default 75)
 * @param {number} alpha The transparency of the colors to generate (0-1, default 1)
 * @param {number} abort_threshold The number of attempts to make at generating a color before aborting, default 500
 */
export function GenerateColours(
  count,
  threshold = 75,
  alpha = 1,
  abort_threshold = 500
) {
  let colours = [];
  for (let i = 0; i < count; i++) {
    let colour = null;
    let cycles = 0;
    do {
      colour = HSLA.Random(alpha);
      cycles++;
    } while (
      colours.some((x) => x.DistanceTo(colour) < threshold) &&
      cycles < abort_threshold
    );
    if (cycles >= abort_threshold) break;
    colours.push(colour);
  }

  return colours;
}
