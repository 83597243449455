import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  InputBase,
  IconButton,
  Badge,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DateRangePickers from "../../sharedComponents/DateRangePickers";
import NewSession from "../otherComponents/NewSession";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  date: {
    paddingLeft: theme.spacing(2),
  },
  left: {
    color: "#4dd2ff",
  },
  right: {
    color: "#4dd2ff",
    paddingRight: theme.spacing(2),
  },
}));

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//Top heading for the MonthlyView
export default function MonthlySessionHeading(props) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  let month = props.month;
  let year = props.year;

  let value = 10;

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <Grid container alignItems="center">
          {/*Icon 1*/}
          <IconButton onClick={props.goBackDate}>
            <Badge className={classes.left}>
              <ArrowBackOutlinedIcon />
            </Badge>
          </IconButton>

          {/*Icon 2*/}
          <IconButton onClick={props.goForwardDate}>
            <Badge className={classes.right}>
              <ArrowForwardOutlinedIcon />
            </Badge>
          </IconButton>

          <Divider orientation="vertical" flexItem />

          <Grid item>
            <Typography
              className={classes.date}
              variant="h5"
              align="center"
              color="primary"
            >
              {Months[month]} {year}
            </Typography>
          </Grid>

          <Grid item sm />

          {/*New Session Dialog*/}
          <Grid item>
            <NewSession />
          </Grid>

          <Grid item className={classes.right} />

          {/* Search Option for a specific date*/}
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Search Date"
                value={selectedDate}
                onChange={props.handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
