import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "./customDialogStyle.scss";

class CustomDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      toggle,
      children,
      className,
      open,
      hideCloseButton,
      closeButtonText,
      welcomeEnabled,
      ...rest
    } = this.props;
    return (
      <Dialog
        className={`CustomDialog ${className}`}
        open={open || false}
        onClose={toggle}
        aria-labelledby="bio-dialog"
        disableBackdropClick={welcomeEnabled}
        disableEscapeKeyDown={welcomeEnabled}
        {...rest}
      >
        <div className="dialog-content">
          {!hideCloseButton && (
            <Button
              className="close-button"
              component={Link}
              onClick={toggle}
              color="primary"
            >
              {closeButtonText && <ArrowBackIcon />}
              {closeButtonText || <span className="material-icons">close</span>}
            </Button>
          )}
          {!!children && (
            <div className="w-100 CustomDialog_body">{children}</div>
          )}
        </div>
      </Dialog>
    );
  }
}

export default CustomDialog;
