import React, { Component } from "react"

import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import OrganizationsList from "./OrganizationsList"
import AddOrganizationForm from "./AddOrganizationForm"
import LoadingSpinner from "../../sharedComponents/LoadingSpinner"
import SearchField from "../sharedComponents/SearchField"
import SelectField from "../sharedComponents/SelectField"
import privilegeText from "../sharedComponents/privilegeText"

import Grid from "@material-ui/core/Grid"
import {
  getOrganizations,
  setPrivilege,
  registerOrganization,
  showModal,
  hideModal,
} from "../../../actions"

import ActiveUsersChart from "../../adminView/ActiveUsersChart"
import moment from "moment"

const styles = (theme) => ({
  select: {
    width: "50%",
    height: "40px",
  },
  title: {
    fontSize: "30px",
    lineHeight: "37px",
    color: "#333437",
    fontWeight: "500",
    padding: "6px 0.8em",
  },
  controlRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5rem 0",
  },
  controlSearch: {
    minWidth: "20rem",
    marginRight: "0.75rem",
    flexGrow: 1,
  },
  controlFilter: {
    minWidth: "8rem",
    maxWidth: "12rem",
    marginRight: "0.75rem",
    flexShrink: 0,
  },
  selectedItem: {
    backgroundColor: theme.palette.info.light,
  },
  selectContainer: {
    padding: "6px 1.5em",
    width: "100%",
  },
  gridContainer: {
    "& .MuiFab-label": {
      color: "#333437",
      fontWeight: "700",
      lineHeight: "37px",
    },
  },
  userTypesLabel: {
    display: "inline-block !important",
    marginRight: "10px",
  },
})

const searchStringify = (o) =>
  `${o.name} ${o.Owner.first_name} ${o.Owner.last_name} ${o.Owner.email}`.toLowerCase()

const filterString = (searchString, organizations) => {
  if (!searchString) {
    return organizations
  }
  return organizations.filter((o) =>
    searchStringify(o).includes(searchString.toLowerCase())
  )
}

const filterStatus = (status, organizations) => {
  if (status === "") {
    return organizations
  }

  return organizations.filter((o) => o.privilege === parseInt(status))
}

const filter = (searchString, status, organizations) => {
  return filterStatus(status, filterString(searchString, organizations))
}

class Organizations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hide_inactive: false,
      start_date: moment().subtract(1, "month").toISOString(),
      end_date: moment().toISOString(),
      searchString: "",
      statusFilter: "",
    }
    this.handleInactiveToggleChange = this.handleInactiveToggleChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleFilterSelect = this.handleFilterSelect.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }

  componentDidMount() {
    if (!this.props.hasFetched) {
      this.props.getOrganizations()
    }
  }

  handleInactiveToggleChange(event) {
    this.setState({ ...this.state, hide_inactive: event.target.checked })
    this.forceUpdate()
  }

  handleFilter({ searchString = "", statusFilter = "" }) {
    this.setState({
      filteredOrganizations: filter(
        searchString,
        statusFilter,
        this.props.organizations
      ),
      searchString: searchString,
      statusFilter: statusFilter,
    })
  }

  handleSearch(event) {
    this.handleFilter({ searchString: event.target.value })
  }

  handleFilterSelect(event) {
    this.handleFilter({ statusFilter: event.target.value })
  }

  render() {
    const { classes, organizations } = this.props
    const { searchString, statusFilter } = this.state
    const filteredOrganizations = filter(
      searchString,
      statusFilter,
      this.props.organizations
    )

    return (
      <>
        <Grid container className={classes.gridContainer} direction={"column"}>
          <h2 className={classes.title}>Manage Organizations</h2>
          <div className={classes.controlRow}>
            <div className={classes.controlSearch}>
              <SearchField
                label='Search by organization name, admin name, or admin email'
                value={this.state.searchString}
                handleChange={this.handleSearch}
              />
            </div>
            <div className={classes.controlFilter}>
              <SelectField
                label='Account Status'
                options={[-1, 0, 1].map((i) => ({
                  value: i,
                  label: privilegeText(i),
                }))}
                value={this.state.statusFilter}
                handleChange={this.handleFilterSelect}
              />
            </div>
            {organizations?.privilege !== 0 && this.props.hasFetched && (
              <div className={classes.controlCreate}>
              <AddOrganizationForm
                registerOrganization={this.props.registerOrganization}
                showModal={this.props.showModal}
                
                hideModal={this.props.hideModal}
              />
              </div>
            )}
          </div>
        </Grid>
        <div className={classes.marginTop}>
          {this.props.hasFetched ? (
            <OrganizationsList
              hide_inactive={this.state.hide_inactive}
              setPrivilege={this.props.setPrivilege}
              organizations={filteredOrganizations}
              searchString={this.state.searchString}
            />
          ) : (
            <LoadingSpinner />
          )}
          {organizations?.privilege !== 0 && (
            <div className={classes.marginBottom}>
              <h3 className={classes.title}>Active Users</h3>
              <ActiveUsersChart
                start_date={this.state.start_date}
                end_date={this.state.end_date}
              />
            </div>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organizationReducer.organizations,
    hasFetched: state.organizationReducer.hasFetched,
    modal: state.modalReducer,
  }
}

export default connect(mapStateToProps, {
  getOrganizations,
  setPrivilege,
  registerOrganization,
  showModal,
  hideModal,
})(withStyles(styles)(Organizations))
