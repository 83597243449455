import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../dashboards/Footer";
import SuccessPayment from './SuccessPayment'

import { connect } from "react-redux";

import "./index.scss"
import { getDetailsForCurrOrg } from "../../actions";
import { GetLocalStorageValueSafe } from "../../lib";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";

import moment from "moment";
import http from "../../http-common";

const BillingInformation = (props) => {
    const [stripeInfo , setStripeInfo] = useState("")
    const [subscriptionINFO , setSubscriptionINFO] = useState(null)
    const [stripeID , setStripeID] = useState(null)
    const [numberOfPatients , setNumberOfPatients] = useState(0)
    const [subscriptionID , setSubscriptionID] = useState(null)
    const [userID , setUserID] = useState(JSON.parse(GetLocalStorageValueSafe('user_data')).id || "")
    const [trial , setTrial] = useState(true)
    const [loadingSpinner , setLoadingSpinner] = useState(true)

    //params 
    const params = new URLSearchParams(props.location.search).get("success")

    useEffect(() =>  {
      if(stripeID) {
        createPortalSession()
      }
    },[stripeID])

    useEffect(() => {
      if(subscriptionID)  {
        getSubscriptionsDetails()
      }
    },[stripeID , stripeInfo])


    useEffect(() => { 
      (async () => {
         const {userEntry , noOfPatients }  = await props.getDetailsForCurrOrg()
         setStripeID(userEntry.stripe_id)
         setSubscriptionID(userEntry.subscription)
         setLoadingSpinner(false)
         setTrial(userEntry.trial)
         setNumberOfPatients(noOfPatients)
      })();
   },[])

    useEffect(() =>  {
      if(props.user) {
        setUserID(props.user.id)
      }
    },[props.user])

    const getSubscriptionsDetails = async () => {
      const payload = {
        url: `/billing/retrieve_an_invoice/${subscriptionID}`,
        method: "get",
      }
      const result = await http(payload)
      if (!result) {
          console.log(result.error.message);
      } else {
        setSubscriptionINFO(result?.data || "")
      }
    }
    const createPortalSession = async () => {
      const payload = {
        url: `/billing/create-customer-portal-session`,
        method: "post",
        data : { stripe_id : stripeID },
      }
      const result = await http(payload)
      if (!result) {
          console.log(result.error.message);
      } else {
          setStripeInfo(result?.data || "")
      }
  };
    
    const Billing = () =>  (
      <div style={{ width: "100%" }} id={"billing"}> 
     {subscriptionID && <section id="section-template" className="patient-flex">
        <div className="your-subscription">
              <p className="subscription">
              Your Subscription
              </p>
              <div className="sub-item">
              {subscriptionID && subscriptionINFO && subscriptionINFO?.status !== 'canceled' && <div>
                <p className="month">
                    {subscriptionINFO?.product_name} {subscriptionINFO?.items?.data[subscriptionINFO?.items?.data?.length -1 ]?.price?.recurring?.interval === 'month' ? 'Monthly' :'Yearly'}
                </p>
                <p className="sub-title">
                  last updated on {moment.unix(subscriptionINFO?.current_period_start).local().format("DD MMM YYYY")}
                </p>
              </div> }
              {subscriptionID && subscriptionINFO && subscriptionINFO?.status !== 'canceled' && <div>
                <p className="month month-center">
                  {numberOfPatients} / {subscriptionINFO?.quantity} {subscriptionINFO?.quantity > 1 ? 'users' : 'user'} 
                </p>
                <p className="sub-title">
                  last updated on {moment.unix(subscriptionINFO?.current_period_start).local().format("DD MMM YYYY")}
                </p>
              </div> }
              </div>
              {subscriptionINFO?.status === 'trialing' && subscriptionINFO?.canceled_at === null && <p>
                  After your free trial ends on {moment.unix(subscriptionINFO?.current_period_end).local().format("DD MMM YYYY")}, <br></br> this plan will continue automatically.
                </p>}
              {subscriptionID && subscriptionINFO && subscriptionINFO?.canceled_at !== null && <p>
                You've cancelled this plan. It will end automatically on {moment.unix(subscriptionINFO?.current_period_end).local().format("DD MMM YYYY")}.
                </p>}
          </div>
          {(subscriptionID &&  subscriptionINFO && subscriptionINFO?.canceled_at === null) 
            &&
          <div className="next-billing">
          <p className="subscription">
                Your next bill
              </p>
              <div>
                <p className="month">
                CA$ { (subscriptionINFO?.plan?.amount / 100) * (subscriptionINFO?.quantity)} 
                </p>
                <div className="view-details">
                  <p className="sub-title">
                   on {moment.unix(subscriptionINFO?.current_period_end).local().format("DD MMM YYYY")}
                  </p>
                 {stripeID && subscriptionID && 
                <a href={stripeInfo}>
                  view details
                  </a>
                }
                </div> 
              </div> 
          </div>
        }
      </section>
      }
      {/*subscriptionID &&  subscriptionINFO?.status !== 'canceled' && <section id="section-template" className="patient-grid">
            <p>
            Get even more out of the Neurofit features you need.<br/> <a  href={stripeInfo} className="upgrade-plans" >Upgrade your subscription today</a>.
            </p>
            <p>
            Want even more out of Neurofit?  <a className="view-plans" href="#section-template">View all plans</a>
            </p>
    </section>        */}
        {!subscriptionID && trial && <section id="section-template" className="patient-grid">
          <stripe-pricing-table  
            client-reference-id={`${userID}`}
            allow-top-navigation= {true}
            pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID_TRIAL}
            publishable-key= {process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            ></stripe-pricing-table>
        </section>
        }
        {!subscriptionID && !trial && <section id="section-template" className="patient-grid">
          <stripe-pricing-table  
            client-reference-id={`${userID}`}
            allow-top-navigation= {true}
            pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
            publishable-key= {process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            ></stripe-pricing-table>
        </section>
        }
        {stripeID && subscriptionID && <section id="section-template_1" className="patient-grid">
          <a href={stripeInfo}>
          Upgrade your subscription
          </a>
          <br></br>
          <a  href={stripeInfo}>
             View billing history
          </a>
          <br></br>
          <a href={stripeInfo}>
          Update billing method
          </a>
          <br></br>
        </section>}
        <section id="section-footer" className="patient-grid">
          <Footer />
        </section>
    </div>
    )
    if(props.isAuthenticated) {
      if(loadingSpinner) {
        return <LoadingSpinner></LoadingSpinner>;
      }
      if(params) {
        return <SuccessPayment currInfo={subscriptionINFO} />;
      }
      return (
        Billing()
      )
    }
    else { 
      return <></>
    }
    
};

const mapStateToProps = (state) => {
  // console.log(state.authReducer);
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    privilege: state.authReducer.privilege,
    user: state.authReducer.user,
    org: state.authReducer.org,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailsForCurrOrg: () => dispatch(getDetailsForCurrOrg()),
  };
};

export default connect(mapStateToProps , mapDispatchToProps)(BillingInformation);
