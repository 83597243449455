import React, { useState, useEffect } from "react";
import StepTwo from "./StepTwo";

const StepTwoCont = ({
  checkoutData,
  callbackSetCheckoutData,
  callbackHandleNext,
}) => {
  const [pricing, setPricing] = useState({
    pricing_id: null,
    desc: "",
    price_monthly_usd: 0,
    price_anually_usd: 0,
  });

  const [toggle, setToggle] = useState(false);

  // if price is monthly/anually
  const togglePrice = (checked) => {
    setToggle(checked);
  };

  useEffect(() => {
    console.table(pricing);

    let newArr = [...checkoutData]; // copy old data
    newArr[1] = pricing; //replace array index 1 (pricing) with new chosen subscription
    newArr[1].is_yearly = toggle; //if true then set yearly sub
    callbackSetCheckoutData(newArr); //set it
  }, [pricing, toggle]);

  // once pricing id is populated => user selected and proceed to next step
  useEffect(() => {
    if (pricing.pricing_id != null) {
      callbackHandleNext();
    }
  }, [pricing.pricing_id]);

  // this function is passed as a callback to select which button was selected from the button elements
  const handlePricingClick = (e) => {
    let pid = parseInt(e.target.value);
    switch (pid) {
      case 0:
        setPricing({
          pricing_id: 0,
          title: "Starter Plan",
          desc: "description one",
          pricing_monthly_usd: 15,
          pricing_anually_usd: 162,
          starting: 1,
        });
        break;
      case 1:
        setPricing({
          pricing_id: 1,
          title: "Standard Plan",
          desc: "description two",
          pricing_monthly_usd: 12,
          pricing_anually_usd: 126,
          starting: 6,
        });
        break;
      case 2:
        setPricing({
          pricing_id: 2,
          title: "Standard Plus Plan",
          desc: "description three",
          pricing_monthly_usd: 11,
          pricing_anually_usd: 105.6,
          starting: 31,
        });
        break;
    }
  };
  return (
    <StepTwo
      callbackHandlePricingClick={handlePricingClick}
      toggle={toggle}
      togglePrice={togglePrice}
    />
  );
};

export default StepTwoCont;
