import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedUser } from "../../actions";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import ChartRangeDropdown from "../sharedComponents/ChartRangeDropdown";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownList: {
    marginBottom: "1.5rem",
  },
}));

export default function UsersDropdown(props) {
  const classes = useStyles();
  const email = useSelector((state) => state.userReducer.selectedUser);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleChange = (e) => {
    dispatch(updateSelectedUser(e.target.value));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function renderMenuItems() {
    return props.users.map((user) => {
      return (
        <MenuItem key={user.id} value={user.id}>
          {`${user.first_name.trim()}, ${user.last_name}`}
        </MenuItem>
      );
    });
  }

  return (
    <div>
      <FormControl className={classes.dropdownList}>
        <InputLabel id="demo-simple-select-helper-label">Users</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={email}
          onChange={handleChange}
        >
          <MenuItem value="Your Users"></MenuItem>
          {renderMenuItems()}
        </Select>
        <FormHelperText>
          Select a user for creating visualization
        </FormHelperText>
      </FormControl>
    </div>
  );
}
